import React from 'react';
import common_banner from "../../images/common_banner.png";

function Common_banner() {
    return (
        <div>
            <div className='common_banner_img'>
                <img
                    // src={common_banner}
                    src={process.env.PUBLIC_URL+'/images/Uni.PNG'}
                    alt="common_banner"
                />
            </div>
            <div className='exam_gapping_div'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-start uni_exam">
                                <h2>
                                    Uni <span>&</span> Exam <span>Services</span>
                                </h2>
                                <p>
                                    We assist students in navigating university applications and exams with tailored guidance and resources. Our services include exam preparation strategies, application reviews, and personalized academic counseling. With a focus on each student's goals, we aim to simplify the journey to academic success.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Common_banner;
