import React, { useEffect, useState } from "react";
import Header from "../../../Header/header";
import Footer from "../../../footer/footer";
import Common_heading from "../Common Heading/common_heading";
import Registration_side_bar from "../registration_side_bar/registration_side_bar";
import {
  programForAdm,
  registrationDetails,
  RegProgList,
} from "../../../../controllers/submissionsController/submissionsController";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
function Programforadmission() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: "",
    admission_year: "2025-2026",
    admission_campus: null,
    applied_program: null,
    second_language_choice: null,
    // learn_source: [],
  });
  const [errors, setErrors] = useState({});
  const [applicationId, setApplicationId] = useState(null);
  const [programmeList, setProgrammeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [rrProgramsList, setrrProgramsList] = useState([
    "U&Me (with Parent) - 1 Yr",
    "U&Me Chutties (without Parent) - 1 Yr",
    "Toddler – 2 Yrs",
    "Tireless Tadpoles – 3 Yrs",
    "Flighty Frogs – 4 Yrs",
    "Bright Bunnies – 5 Yrs",
    "Cool Cats – 6 Yrs",
    "Leaping Lions – 7 Yrs",
  ]);
  const [opProgramsList, setOpProgramsList] = useState([
    "Toddler – 2 Yrs",
    "Tireless Tadpoles – 3 Yrs",
    "Flighty Frogs – 4 Yrs",
    "Bright Bunnies – 5 Yrs",
    "Cool Cats – 6 Yrs",
    "Leaping Lions – 7 Yrs",
    "Dynamic Dolphins – 8 Yrs",
    "Galloping Gazelles – 9 Yrs",
    "Soaring Seagulls – 10 Yrs",
    "6th Grade - 11 Yrs",
    "7th Grade - 12 Yrs",
    "8th Grade - 13 Yrs",
    "9th Grade - 14 Yrs",
    "10th Grade - 15 Yrs",
    "11th Grade - 16 Yrs",
  ]);
  // Update programmeList based on selected campus
  useEffect(() => {
    if (formData.admission_campus === "OP-Campus") {
      setProgrammeList(opProgramsList);
    } else if (formData.admission_campus === "RR-Campus") {
      setProgrammeList(rrProgramsList);
    } else {
      setProgrammeList([]); // Reset if no campus is selected
    }
  }, [formData.admission_campus]);

  
  const validateForm = () => {
    const newErrors = {};
  
    if (!formData.admission_year) {
      newErrors.admission_year = "Admission Year is required.";
    }
    if (!formData.admission_campus) {
      newErrors.admission_campus = "Admission Campus is required.";
    }
    if (!formData.applied_program) {
      newErrors.applied_program = "Applied Program is required.";
    }
    if (!formData.second_language_choice) {
      newErrors.second_language_choice = "Second Language Choice is required.";
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const rawBody = { ...formData };

    try {
      const response = await programForAdm(rawBody);
      if (response?.status === true) {
        notification.open({
          message: "Submitted.",
          description: `Details submitted successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        }); // alert('Details submitted successfully.');
        window.history.pushState({}, "", "/foreign-nationals-Only");
        window.dispatchEvent(new Event("popstate"));
      } else {
        notification.open({
          message: "Opps!",
          description: `${"Failed to submit details"}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        }); // alert('Failed to submit details.');
      }
    } catch (error) {
      console.error("Submission error:", error);
      alert("An error occurred while submitting the details.");
    }
  };

  useEffect(() => {
    const fetchApplicationDetails = async () => {
      const encodedId = localStorage.getItem("applicationId");
      if (!encodedId) {
        console.error("Encoded applicationId not found in localStorage");
        navigate("/enter-student");
        return;
      }
      try {
        const decodedId = atob(encodedId);
        setApplicationId(decodedId);
        const response = await registrationDetails({ id: decodedId });
        if (response?.status === true) {
          const prevData = response.result;
          setFormData({
            id: decodedId,
            admission_year: prevData.admission_year || "2025-2026",
            applied_program: prevData.applied_program || null,
            second_language_choice: prevData.second_language_choice || null,
            admission_campus: prevData.admission_campus || null,
          });
        }
      } catch (error) {
        console.error("Error fetching registration details:", error);
        navigate("/enter-student");
      }
    };

    fetchApplicationDetails();
  }, [navigate]);

  return (
    <div>
      <Header />
      <div className="inner_mainbanner">
        <Common_heading />
      </div>
      <div className="exam_gapping_div">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <Registration_side_bar />
            </div>

            <div className="col-md-9">
              <form>
                <div className="form_heading_container  mb-3">
                  <h5 className="heading-text">
                    Programme for which admission is sought:
                  </h5>
                </div>
                <div className="enter_na_form mt-4 mb-3 font_reduce_size_program">
                  <h5 className="heading-text ">
                    Note: If the desired grade level is not available in the
                    drop-down menu, please do not select any other grade level
                    to submit the application
                  </h5>
                </div>
                {/* Program Selection */}
                <div className="row mb-4">
                  <div className="col-md-6 regestration_form_input">
                    <select
                      className="form-select"
                      value={formData.admission_campus || ""}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          admission_campus: e.target.value || null,
                          applied_program: " ", // Reset program when changing campus
                        }))
                      }
                    >
                      <option value="">Select Campus</option>
                      <option value="OP-Campus">
                        OP Campus - Navalur (2 - 16 Yrs)
                      </option>
                      <option value="RR-Campus">
                        RR Campus - Kotturpuram (1 - 7 Yrs)
                      </option>
                    </select>
                    {errors.admission_campus && <span className="error-text">{errors.admission_campus}</span>}
                  </div>
                  <div className="col-md-6 regestration_form_input">
                    <select
                      className="form-select"
                      value={formData.admission_year }
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          admission_year: e.target.value || null,
                        }))
                      }
                    >
                      {/* <option value="">Select Session Year</option> */}
                      <option value="2025-2026">2025-2026</option>
                      <option value="2024-2025">2024-2025</option>
                      
                    </select>
                    {errors.admission_year && <span className="error-text">{errors.admission_year}</span>}
                  </div>
                </div>

                <div className="col-md-12 regestration_form_input">
                  <select
                    className="form-select"
                    value={formData.applied_program || ""}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        applied_program: e.target.value || null,
                      }))
                    }
                    disabled={!formData.admission_campus} // Disable if no campus is selected
                  >
                    <option value="">Select Program</option>
                    {programmeList.map((program, index) => (
                      <option key={index} value={program}>
                        {program}
                      </option>
                    ))}
                  </select>
                  {errors.applied_program && <span className="error-text">{errors.applied_program}</span>}
                </div>

                {/* Second Language Choice */}
                <div className="form_choice_language mt-4 ">
                  <h5 className="heading-text">Choice of Second Language</h5>
                  <div className="d-flex flex-wrap gap-3">
                    {["Tamil", "Hindi", "Spanish", "French"].map(
                      (lang, idx) => (
                        <label className="regestration_kc_radio" key={idx}>
                          <input
                            type="radio"
                            id="radio_btn"
                            name="second_language"
                            value={lang}
                            checked={formData.second_language_choice === lang}
                            onChange={() =>
                              setFormData((prev) => ({
                                ...prev,
                                second_language_choice: lang,
                              }))
                            }
                          />
                          <span className="regestration_applied_yes">
                            {lang}
                          </span>
                        </label>
                      )
                    )}
                  </div>
                  {errors.second_language_choice && <span className="error-text">{errors.second_language_choice}</span>}
                </div>
                <div className="form_subbmit_btn mt-4">
                  <button type="button" onClick={handleSubmit}>
                    Proceed to Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Programforadmission;
