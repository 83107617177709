import { API_BASE_URL } from "../../config/constants";
import { postRequest } from "../API";

/*********************************************************
* Function Name : AcademicsDetails
* Description   : AcademicsDetails
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const academicsDetails = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}academics/details`,
            postData: options
        }
        const res = await postRequest(params);
        // console.log( "academics/details form response: ", res);
        
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end