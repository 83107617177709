import React, { useState, useEffect,useRef } from "react";
import Header from "../Header/header";
import Footer from "../footer/footer";
import "./news.css";
import { useNavigate } from "react-router-dom";
import news_Banner from "../images/news_banner_pic.jpg";
import { Link } from "react-router-dom";
import Pagination from "react-bootstrap/Pagination";
import { articles, articlesTag } from "../../controllers/commonController/cmsController";
import { FRONT_ASSETS_URL } from "../../config/constants";

function News() {
  const [cmsArticleList, setcmsArticleList] = useState([]);
   const [tags, setcmsCourseList] = useState([]);
  const [activePage, setActivePage] = useState(1); // Current page
  const [totalPages, setTotalPages] = useState(1); // Total pages
  const itemsPerPage = 9; // Articles per page
  const navigate = useNavigate();

  /*********************************************************
   *  This function is use to fetch cms content list
   *********************************************************/
  const cmsArticles = async () => {
    setcmsArticleList([]);
    // setIsLoading(true);
    try {
      const options = {
        // type: "news",
        condition: {
          status: "A",
          type: "News",

        },
        select: {},
        sort: { _id: -1 },
        populate: {
          key: "",
          select: " ",
        },
        skip: (activePage - 1) * itemsPerPage,
        limit: itemsPerPage,
      };
      const listData = await articles(options);
      if (listData?.status === true) {
        setcmsArticleList(listData?.result);
        const totalItems = listData?.count || 0;
        // console.log(totalItems, "totalItems");

        setTotalPages(Math.ceil(totalItems / itemsPerPage));
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      //   setIsLoading(false);
    }
  }; //end

      /*********************************************************
     *  This function is use to fetch cms content list
     *********************************************************/
      const cmsCourses = async () => {
        setcmsCourseList([]);
        // setIsLoading(true);
        try {
          const listData = await articlesTag();
          if (listData?.status === true) {
            setcmsCourseList(listData?.result);
          }
        } catch (error) {
          console.log("failed to fetch banner list:", error);
        } finally {
          //   setIsLoading(false);
        }
      }; //end

  useEffect(() => {
    cmsCourses();
    cmsArticles();
  }, []);
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleTagClick = (tag) => {
    navigate("/news-events", { state: { selectedTag: tag } });
  };

  useEffect(() => {
    cmsArticles(activePage);
  }, [activePage]);

  const handlePageClick = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setActivePage(pageNumber);
    }
  };



  const [isSticky, setIsSticky] = useState(false);
  const footerRef = useRef(null); // Reference to the footer

  useEffect(() => {
    const handleScroll = () => {
      const footer = footerRef.current;
      const footerPosition = footer ? footer.getBoundingClientRect() : null;

      // Check if footer is visible
      const isFooterVisible = footerPosition && footerPosition.top <= window.innerHeight;

      // Enable or disable sticky class based on scroll position and footer visibility
      if (!isFooterVisible) {
        setIsSticky(window.pageYOffset > 380); // Make sticky if pageYOffset is greater than 380 and footer is not visible
      } else {
        setIsSticky(false); // Disable sticky class if footer is visible
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  //   const handlePageClick = (pageNumber) => {
  //     setActivePage(pageNumber); // Update the active page on click
  //   };
  // Utility function to format the createdAt date
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div>
      <Header />
      <div className="inner_mainbanner">
        <div className="exam_gapping_div">
          <div className="container-fluid">
            <div className="row align-items-center justify-content-center news_banner_main_div">
              {/* Left Section - Text */}
              <div className="col-md-4 text-start news_banner_div">
                <h2>
                  Latest <span> News</span>
                </h2>
                <p>
                  We assist students in navigating university applications and
                  exams with tailored guidance and resources. Our services
                  include exam preparation strategies, application reviews, and
                  personalized academic counseling. With a focus on each
                  student's goals, we aim to simplify the journey to academic
                  success.
                </p>
              </div>

              {/* Right Section - Image */}
              <div className="col-md-8 d-flex justify-content-center">
                <div className="news_banner_div_img">
                  <img
                    src={news_Banner}
                    alt="common_banner"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="exam_gapping_div     ">
        <div className="container-fluid">
          <div className="info_all_nav mb-4">
            <div className="row align-items-center">
              <div className="col-md-12">
                <nav aria-label="breadcrumb" className="news_info_breadcrumb">
                  <ol className="breadcrumb mb-0">
                    <li className="breadcrumb-item">
                      <Link to="/#">Home</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <Link to="/#">
                        {" "}
                        <span>News</span>
                      </Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="row new_mobile_div">
            {/* Left Section - Cards */}
            <div className="col-md-9">
              <div className="news_lsiting_slide">
                <div className="row">
                  {cmsArticleList.map((item) => (
                    <div key={item._id} className="col-md-4 mb-4 news_card_news_tabs">
                      <Link to={`/${item.slug}`}>
                        <div className="home_event_card event_card">
                          <div className="home_event_custom_card card text-center border event_news_card_drop">
                            <div className="home_card_img_container news_image_for_mobile">
                              <img
                                src={`${FRONT_ASSETS_URL}${item?.image}`}
                                className="img-fluid card-img"
                                alt={item.title}
                              />
                            </div>
                            <div className="home_event_card_body text-start event_slider_contnet news_card_tabs">
                              <h4 className="event-label">
                                {item?.type} <span>/ By {item?.author}</span>
                              </h4>
                              <h5 className="card-title text-start">
                                {item?.title}
                              </h5>
                              <p className="text-start">{item?.content}</p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="text-start event-date home_event_slider_bottom">
                              {formatDate(item?.createdAt)}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              <div className="pegination_for_news">
                <Pagination className="justify-content-center mt-4">
                  <Pagination.Prev
                    onClick={() => handlePageClick(activePage - 1)}
                    disabled={activePage === 1}
                  >
                    « Prev
                  </Pagination.Prev>
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === activePage}
                      onClick={() => handlePageClick(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => handlePageClick(activePage + 1)}
                    disabled={activePage === totalPages}
                  >
                    Next »
                  </Pagination.Next>
                </Pagination>
              </div>
            </div>

            {/* Right Section */}
            <div className="col-md-3 ">
              <div
                className={`all_sticky ${isSticky ? "sticky_info_for" : ""}`}
              >
                <div className="d-flex flex-wrap eligible_main_div">
                  {tags.map((tag, index) => (
                    <div
                      key={index}
                      className="eligible_tag eligible_tag_for_news"
                      onClick={() => handleTagClick(tag)} // Add click handler
                    >
                      <h6 className="tag-name">{tag}</h6> {" "}
                      {/* <span>{tag.age}</span> */}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer ref={footerRef}>
        <Footer />
      </footer>
    </div>
  );
}

export default News;
