import React, { useState } from 'react';

function Common_faq({ faqData }) {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle open/close on click
  };

  return (
    <div className='faq_background'>
      <div className="container-fluid">
        <div className="text-center home_school_heading faq_main_div all_section_margin">
          <h2>
            <span className="highlight">FAQ’s</span>
          </h2>
          <p>
            Here are some frequently asked questions to help you understand our Early Years program better. <br />
            We aim to provide clarity and support as you navigate your child's early learning journey.
          </p>

          {/* Wrap FAQ items in a row and col-12 */}
          <div className="row">
            <div className="col-12 col-md-6 col-lg-12 faq_tabs">
              {faqData && faqData.length > 0 ? (
                faqData.map((faq, index) => (
                  <div className={`details`} key={faq._id}>
                    <div
                      className={`details__container ${openIndex === index ? 'open' : ''}`}
                      onClick={() => toggleFAQ(index)}
                    >
                      <summary className="details__summary">
                        <h2 className="faq_details__title">{faq.title}</h2>
                      </summary>
                    </div>
                    <div className={`details__desc ${openIndex === index ? 'open' : ''}`}>
                      <div className="details__desc-inner">
                        {faq.description}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p>No FAQs available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Common_faq;
