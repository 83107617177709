import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./founder.css";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { FRONT_ASSETS_URL } from "../../config/constants";

function Reflections_slider({ founderJourneydata }) {
  const [activeIndex, setActiveIndex] = useState(0);

  // Function to replicate data if only one item exists
  const replicateData = (data) => {
    if (!data || data.length === 0) {
      console.error("No data provided for the slider.");
      return [];
    }

    const minDots = 5;
    if (data.length < minDots) {
      const replicatedArray = [...data];
      while (replicatedArray.length < minDots) {
        replicatedArray.push(...data);
      }
      return replicatedArray.slice(0, minDots); // Ensure exactly 5 items
    }
    return data;
  };

  // Replicating Data
  const replicatedData = replicateData(founderJourneydata || []);

  // Custom Next Arrow
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <button className="founder_reflication_slider_rght reflection_right" onClick={onClick}>
        <MdOutlineArrowForwardIos />
      </button>
    );
  };
  //   useEffect(() => {
  //     console.log("Founder Journey Data from Parent:", founderJourneydata);
  //   }, [founderJourneydata]);

  // Custom Prev Arrow
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button className="founder_reflication_slider_left reflection_left" onClick={onClick}>
        <MdOutlineArrowBackIos />
      </button>
    );
  };

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    fade: true,
    afterChange: (index) => setActiveIndex(index),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container-fluid my-5 reflection_side_mobile">
      <div className="row align-items-center">
        <div className="col-md-4 text-start">
          <div className="calendar_beyond reflection_main_text">
            <h2>
              Reflections:
              <br />
              <span> A Walk Through</span>
              <span> Time</span>
            </h2>
            <p>
              KC High’s growth, from an after school activity centre to a globally recognized institution, reflects a commitment to providing socially relevant education without compromising personalized attention and strong community ties. Valli and her dedicated team at KC High are not merely adopting best practices but pioneering next practices. The constant pursuit of excellence and a forward-looking approach define Valli's enduring impact on education.
            </p>
          </div>
        </div>
        <div className="col-md-8">
          <div className="explore_slider_main_div">
            <Slider {...settings} className="reflection_slider_dot">
              {replicatedData.map((item, index) => (
                <div className="slide-item" key={index}>
                  <div
                    className={`card border-0 ${activeIndex === index ? "related_slide" : ""
                      }`}
                  >
                    <div className="row align-items-center founder_row">
                      <div className="col-md-6 founder_col">
                        <div className="card-body founder_text text-start">
                          <h5 className="card-title">{item.title}</h5>
                          <p className="card-text">{item.content}</p>
                        </div>
                      </div>
                      <div className="col-md-6 text-center reflection_sldier_img">
                        <img
                          src={`${FRONT_ASSETS_URL}${item.image}`}
                          className="img-fluid"
                          alt={item.title}
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Reflections_slider;
