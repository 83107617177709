import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MdOutlineArrowBackIos, MdOutlineArrowForwardIos } from 'react-icons/md';
import { articles } from '../../../controllers/commonController/cmsController';
import { FRONT_ASSETS_URL } from '../../../config/constants';
import { Link } from 'react-router-dom';

function AcademicEarlySlider({ slug }) {
    const [activeIndex, setActiveIndex] = useState(0);
    const [academicsStories, setAcademicsStories] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /*********************************************************
     *  Fetch CMS content list
     *********************************************************/
    const academicRelatedStories = async () => {
        setIsLoading(true);
        try {
            const options = {
                type: "", // Assign a meaningful type here
                condition: {
                    status: "A",
                    pages: {
                        $in: [slug],
                    },
                },
            };
            const listData = await articles(options);
            if (listData?.status === true) {
                // console.log(listData?.result, "slider article");
                setAcademicsStories(listData?.result);
            }
        } catch (error) {
            console.log("Failed to fetch articles:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        academicRelatedStories();
    }, []);

    // Replicate Data if necessary
    const replicateData = (data) => {
        return data.length === 1 ? Array(5).fill(data[0]) : data;
    };

    const replicatedData = replicateData(academicsStories);

    // Custom Next Arrow
    const NextArrow = (props) => {
        const { onClick } = props;
        return (
            <button className="home_slider_left_right_button relted_right" onClick={onClick}>
                <MdOutlineArrowForwardIos />
            </button>
        );
    };

    // Custom Prev Arrow
    const PrevArrow = (props) => {
        const { onClick } = props;
        return (
            <button className="home_slider_left__button related_left" onClick={onClick}>
                <MdOutlineArrowBackIos />
            </button>
        );
    };

    var settings = {
        dots: false,
        arrows: true,
        infinite: true,
        afterChange: (index) => setActiveIndex(index),
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        arrows: true,
        cssEase: "linear",
        centerMode: true,
        focusOnSelect: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1900,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="academics_all_section">
            <div className="container-fluid">
                <div className="text-center home_school_heading">
                    <h2>
                        Related <span className="highlight">Stories</span>
                    </h2>
                    <p>
                        Discover highlights from our Early Years programs that showcase our commitment to
                        <br /> nurturing young learners. Stay tuned for inspiring stories of growth and discovery.
                    </p>
                </div>
                <div className="explore_slider_main_div">
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <Slider {...settings}>
                            {replicatedData.map((item, index) => (
                                <Link to={`/${item.slug}`} >
                                <div className="slide-item" key={item._id || index}>
                                    <div
                                        className={`card text-center border-0 learn_article_slider_main_section ${activeIndex === index ? 'related_slide' : ''
                                            }`}
                                    >
                                        <div>
                                            <img
                                                src={`${FRONT_ASSETS_URL}${item.image}`}
                                                className="early_slider_img related_image"
                                                alt={item.title}
                                            />
                                            <span className="date-badge">
                                                {new Date(item.createdAt).toLocaleDateString('en-US', {
                                                    day: '2-digit',
                                                    month: 'short',
                                                    year: 'numeric',
                                                })}
                                            </span>
                                        </div>
                                        <div className="card-body subscription_slider_main_div text-start">
                                            <h5 className="card-title">{item.title}</h5>
                                            <p className="card-text">{item.content}</p>
                                        </div>
                                    </div>
                                </div>
                                </Link>
                            ))}
                        </Slider>
                    )}
                    <div className="event_slider_left_btn academic_early_store pb-4">
                        {/* <button type="button" className="early_read_btn">
                            View All Stories
                        </button> */}
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AcademicEarlySlider;
