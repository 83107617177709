import React from 'react'
import { Link } from 'react-router-dom'

function Common_sticky_button() {
    return (
       
        <div className="side_tab_container">
           
            <div className="side-tab">
            <Link to= "/contact-us" style={{textDecoration : "none", color:"white"}}>
                 New to KC High
                </Link> 
            </div>
        </div>
    )
}

export default Common_sticky_button
