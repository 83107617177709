import React, { useRef, useEffect, useState } from "react";
import "./counselling_department.css";
import Header from "../Header/header";
import Footer from "../footer/footer";
import counselling_img from "../images/counselling.png";
import counselling_img2 from "../images/counselling_middle_pic.png";
import department_img from "../images/dedpartment_img6.png";
import { list } from "../../controllers/commonController/cmsController";

function Counselling_department() {
  const boxesContainerRef = useRef(null);
  const dottedLineRef = useRef(null);
  const [cmsContentList, setcmsContentList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  /*********************************************************
   *  This function is use to fetch cms content list
   *********************************************************/
  const cmsContent = async () => {
    setcmsContentList([]);
    setIsLoading(true);
    try {
      const option = {
        type: "",
        condition: {
          status: "A",
          type: "roadmap",
        },
      };
      const listData = await list(option);
      if (listData?.status === true) {
        setcmsContentList(listData?.result);
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      setIsLoading(false);
    }
  }; //end

  useEffect(() => {
    cmsContent();
  }, []);
    // Scroll to the top of the page when the component mounts
useEffect(() => {
  window.scrollTo(0, 0);
}, []);

//////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Here dash line code functionality upladed dash line based on cad
//////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Function to update the width of the dotted line
  // const updateDottedLineWidth = () => {
  //   if (boxesContainerRef.current && dottedLineRef.current) {
  //     const totalGap = (boxesContainerRef.current.children.length - 1) * 70; 
  //     const totalWidth = boxesContainerRef.current.scrollWidth + totalGap;
  //     dottedLineRef.current.style.width = `${totalWidth}px`;
  //   }
  // };
  const updateDottedLineWidth = () => {
    if (scrollContainerRef.current && dottedLineRef.current) {
      // Get the total scrollable width of the container
      const totalWidth = scrollContainerRef.current.scrollWidth;

      // Set the dotted line width to match the container's scroll width
      dottedLineRef.current.style.width = `${totalWidth}px`;
    }
  };
  useEffect(() => {
    if (!isLoading) {
      updateDottedLineWidth(); 
    }
  }, [cmsContentList, isLoading]);
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Here end dash line functionality 
  //////////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Run this function on component mount and window resize
  useEffect(() => {
    updateDottedLineWidth();
    window.addEventListener("resize", updateDottedLineWidth);

    return () => {
      window.removeEventListener("resize", updateDottedLineWidth);
    };
  }, []);
  const scrollContainerRef = useRef(null);

  //// mouse track
  // Track mouse state
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  const handleMouseDown = (e) => {
    isDragging.current = true;
    startX.current = e.pageX - scrollContainerRef.current.offsetLeft;
    scrollLeft.current = scrollContainerRef.current.scrollLeft;
  };

  const handleMouseMove = (e) => {
    if (!isDragging.current) return;
    e.preventDefault();
    const x = e.pageX - scrollContainerRef.current.offsetLeft;
    const walk = x - startX.current; // Calculate the distance dragged
    scrollContainerRef.current.scrollLeft = scrollLeft.current - walk;
  };

  const handleMouseUpOrLeave = () => {
    isDragging.current = false;
  };

  return (
    <div>
      <Header />
      <section>
        <div className="container-fluid">
          <div className="content-container">
            <div className="row">
              <div className="sec_adjust">
                <div className="col-12 col-md-7 col-lg-7">
                  <div className="text-start early_year_heading_Section counselling_heaidng">
                    <h2>
                      University <span>&</span> <br></br>
                      Guidance <span>Counselling</span>
                    </h2>

                    <p>
                      KC High has a dedicated University and Guidance Counseling
                      department that assists students with their career
                      guidance and university applications. Our counsellors help
                      learners to recognize and build their strengths, interests
                      and skills to thrive and excel in school and beyond.
                      Learners are guided with individualized college plans
                      through the use of BridgeU, a university and career
                      guidance platform and other resources aimed at connecting
                      students with programs they are passionate about and
                      universities that are best fit for them. Our goal is to
                      ensure our students are future-ready when they graduate.
                      Every student of KC High’s first graduating batch received
                      an IB Diploma with an average score of 35.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-5 col-lg-5">
                  <div className="pulse-container">
                    <img
                      // src={counselling_img}

src={process.env.PUBLIC_URL+'/images/Uni_Counseling_Banner.JPG'}                      alt="Early Years Program"
                      className="early_circular_image2"
                    />
                    <div className="pulse pulse-1"></div>
                    <div className="pulse pulse-2"></div>
                    <div className="pulse pulse-3"></div>
                    <div className="pulse pulse-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="home_founder_section">
        <div className="container-fluid all_fluid_main_class">
          <div className="row align-items-center">
            <div className="d-flex justify-content-center home_founder_message_row">
              <div className="col-md-12 d-flex align-items-center counselling_img">
                <div>
                  <img src={counselling_img2} alt="Founder Icon" />
                </div>
                <div className="founder-text ms-3 text-start board_Section_text">
                  <h2 className="text-white"> Counselling Department</h2>
                  <p className="text-white counselling_paragraph">
                    The Counseling department at KC High has been awarded
                    a Grade A+ accreditation by the IC3 Institute for
                    demonstrating a commitment to connecting its students with
                    opportunities for success and showing innovation in career
                    exploration and development through robust best practices.
                    The Accreditation was awarded based on a qualitative and
                    quantitative analysis of the school’s counselling practices
                    measured using seven key standards: infrastructure, staff,
                    leadership/management support, counselling curriculum and
                    activities, resources, documentation, school
                    partnerships/associations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="counselling_department_gapping">
        <div className="container-fluid">
          <div className="row">
            <div className=" col-md-12 department_hovering_col">
              <div className="text-center calendar_school_text">
                <h2>
                  The <span> Roadmap</span>
                </h2>
                <p className="founder_paragraph department_paragraph">
                  KC High University and Guidance Counseling department assist
                  students in college search and application process with:
                </p>
              </div>
              {/* ///////////////////////////////// */}
              <div
                ref={scrollContainerRef}
                className="card_overflow"
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUpOrLeave}
                onMouseLeave={handleMouseUpOrLeave}
              >
                <div
                  ref={boxesContainerRef}
                  className="department_horering_boxes"
                >
                  {cmsContentList
                    .filter((_, idx) => idx % 2 !== 1) // Select alternate elements (index 0, 2, 4...)
                    .map((item, idx) => (
                      <div className="my-4" key={idx}>
                        <div className="info_box_top">
                          <p className="text-center mb-0">{item.content}</p>
                          <div className="circle_department"></div>
                        </div>
                      </div>
                    ))}
                </div>

                {/* Dashed Line */}
                <div ref={dottedLineRef} className="dotted-line"></div>

                <div
                  ref={boxesContainerRef}
                  className="department_horering_boxes_bottom"
                >
                  {cmsContentList
                    .filter((_, idx) => idx % 2 !== 0) // Select alternate elements (index 1, 3, 5...)
                    .map((item, idx) => (
                      <div className="" key={idx}>
                        <div className="info_box_bottom">
                          <p className="text-center mb-0">{item.content}</p>
                          <div className="circle_bottom"></div>
                        </div>
                      </div>
                    ))}
           
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="process_step_gapping">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 d-flex  align-items-center">
              <div className="department_sucees_section">
                <h2>
                  Where <span>Success</span>
                </h2>
                <h3> Stories Begin</h3>
                <p>
                  KC High University and Guidance Counseling department assist
                  students in college search and application process with:
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <img
              src={process.env.PUBLIC_URL+'/images/Uni_Counseling_Success Stories.JPG'}
                // src={department_img}
                alt="Students in classroom"
                className="img-fluid rounded"
              />
            </div>
          </div>
        </div>
      </div>
      <div className=" d-flex justify-content-center info_gapping_section">
        <div className="container-fluid department_padding">
          <div className="row justify-content-center w-100 department_gap_mobile">
            <div className="col-md-4 d-flex">
              <div className=" deartment_placement_hovering  w-100 h-100">
                <div className="counselling_rr_card_bg h-100 d-flex flex-column">
                  <div className="card-body text-start department_offer flex-grow-1">
                    <h5 className="card-title">Admission Placements & Offers</h5>
                    <div className="campus-content department">
                      <p>
                        Our students have successfully secured placements in
                        prestigious universities worldwide, reflecting their
                        hard work and dedication. We take pride in guiding them
                        toward fulfilling their academic dreams.
                      </p>
                    </div>
                  </div>
                  {/* <button className="read_more_button">Read More</button> */}
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex">
              <div className="deartment_placement_hovering w-100 h-100">
                <div className="counselling_rr_card_bg h-100 d-flex flex-column">
                  <div className="card-body text-start department_offer flex-grow-1">
                    <h5 className="card-title">KC Graduates</h5>
                    <div className="campus-content department">
                      <p>
                        Our graduates have excelled in diverse fields, gaining
                        degrees from renowned institutions globally. They
                        continue to inspire by pursuing careers in their chosen
                        domains with passion and purpose.
                      </p>
                    </div>
                  </div>
                  {/* <button className="read_more_button">Read More</button> */}
                </div>
              </div>
            </div>
            <div className="col-md-4 d-flex department_mobile">
              <div className="deartment_placement_hovering w-100 h-100">
                <div className="counselling_rr_card_bg h-100 d-flex flex-column">
                  <div className="card-body text-start department_offer flex-grow-1">
                    <h5 className="card-title">Alumni Connect</h5>
                    <div className="campus-content department">
                      <p>
                        Belonging to a community can be very helpful when an
                        alumnus is looking for a job or a business opportunity.
                        KC High, through the Alumni Society, could provide
                        graduates with support in terms of intergenerational
                        networking.
                      </p>
                    </div>
                  </div>
                  {/* <button className="read_more_button">Read More</button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Counselling_department;
