import React, { useState, useEffect } from "react";
import Header from "../../../Header/header";
import Footer from "../../../footer/footer";
import Common_heading from "../Common Heading/common_heading";
import Registration_side_bar from "../registration_side_bar/registration_side_bar";
import { FaUpload } from "react-icons/fa";
import { RiDeleteBin6Line } from "react-icons/ri";
import {
  submitQuestionnaires,
  registrationDetails,
} from "../../../../controllers/submissionsController/submissionsController";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { FRONT_ASSETS_URL, PDF_FRONT_ASSETS_URL } from "../../../../config/constants";
function Parentquestionnaires() {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [isDragging1, setIsDragging1] = useState(false);
  const [isDragging2, setIsDragging2] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    questionnaire1: null,
    questionnaire2: null,
  });
  const navigate = useNavigate();
  // Error states for validations
  const [isChecked, setIsChecked] = useState(false);
  const [file1Error, setFile1Error] = useState("");
  const [file2Error, setFile2Error] = useState("");
  const [checkboxError, setCheckboxError] = useState("");

  const handleFileChange = (e, fileSetter, setError) => {
    if (e.target.files.length > 0) {
      fileSetter(e.target.files[0]);
      setError(""); // Clear the error if file is selected
    }
  };

  const handleDeleteFile = (fileSetter, setError) => {
    fileSetter(null);
    setError(""); // Clear the error if file is deleted
  };

  const handleDragOver = (e, setDragging) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = (setDragging) => {
    setDragging(false);
  };

  const handleDrop = (e, setDragging, fileSetter, setError) => {
    e.preventDefault();
    setDragging(false);
    if (e.dataTransfer.files.length > 0) {
      fileSetter(e.dataTransfer.files[0]);
      setError(""); // Clear the error if file is dropped
    }
  };

  const handleSubmit = async () => {
    // Reset errors
    setFile1Error("");
    setFile2Error("");
    // setCheckboxError("");

    let hasError = false;

    if (!file1) {
      setFile1Error("Please upload Questionnaire 1 or Questionnaire 3.");
      hasError = true;
    }

    if (!file2) {
      setFile2Error("Please upload Questionnaire 2 or Questionnaire 4.");
      hasError = true;
    }

    // if (!isChecked) {
    //   setCheckboxError("You must accept the declaration to submit the form.");
    //   hasError = true;
    // }

    if (hasError) {
      return; // Exit if there are validation errors
    }

    const form = new FormData();
    form.append("id", formData.id);
    form.append("questionnaire1", file1);
    form.append("questionnaire2", file2);

    try {
      const response = await submitQuestionnaires(form);
      if (response?.status === true) {
        // localStorage.removeItem("applicationId");
        // alert("Details submitted successfully.");
        notification.open({
          message: "Submitted.",
          description: `Details submitted successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
        window.history.pushState({}, "", "/other-details");
        window.dispatchEvent(new Event("popstate"));
      } else {
        notification.open({
          message: response?.message?.errorCode,
          description: response?.message?.message,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
        // alert("Failed to submit details.");
      }
    } catch (error) {
      console.error("Submission error:", error);
      alert("An error occurred while submitting the details.");
    }
  };

  useEffect(() => {
    const fetchApplicationDetails = async () => {
      const encodedId = localStorage.getItem("applicationId");
      if (!encodedId) {
        navigate("/enter-student");
        return;
      }

      try {
        const decodedId = atob(encodedId);
        const response = await registrationDetails({ id: decodedId });

        if (response?.status === true) {
          const prevData = response.result;
          setFormData({
            id: decodedId,
            questionnaire1: prevData.questionnaire1 || null,
            questionnaire2: prevData.questionnaire2 || null,
          });
        }
      } catch (error) {
        console.error("Error fetching registration details:", error);
        navigate("/enter-student");
      }
    };

    fetchApplicationDetails();
  }, [navigate]);

  return (
    <div>
      <Header />
      <div className="inner_mainbanner">
        <Common_heading />
      </div>
      <div className="exam_gapping_div">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <Registration_side_bar />
            </div>
            <div className="col-md-9">
              <form>
                <div className="form_heading_container  mb-3">
                  <h5 className="heading-text">Parent Questionnaires</h5>
                </div>
                <div className="online_program_section mt-4 mb-3">
                  <h5 className="heading-text">
                    Parents are required to download, complete, and upload two
                    Questionnaires below:
                  </h5>
                  <p>
                    If you are applying for any pre-primary program from
                    <span className="cool_cat_color">
                      {" "}
                      U&Me to Bright Bunnies{" "}
                    </span>
                    , download, complete and upload Questionnaires 1 and 2.
                  </p>
                </div>
                <div className="d-flex form_download_btn ">
                  <button
                    type="button"
                    onClick={() => {
                      const link = document.createElement("a");
                      link.href =
                        `${PDF_FRONT_ASSETS_URL}assets/parentQuestionnaires/Parent-Questionnaire-1-U-Me-to-Bright-Bunnies-3-1.doc`; // Suggested filename
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                  >
                    {" "}
                    Download Parent Questionnaire 1
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      const link = document.createElement("a");
                      link.href =
                        `${PDF_FRONT_ASSETS_URL}assets/parentQuestionnaires/Parent-Questionnaire-2-U-Me-to-Bright-Bunnies-KCH.doc`; // Suggested filename
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                  >
                    Download Parent Questionnaire 2{" "}
                  </button>
                </div>{" "}
                <hr></hr>
                <div className="online_program_section mt-4 mb-3">
                  <p>
                    If you are applying for any program from{" "}
                    <span className="cool_cat_color">
                      {" "}
                      Cool Cats to Grade 11{" "}
                    </span>
                    , download, complete, and upload Questionnaires 3 and 4.
                  </p>
                </div>
                <div className="d-flex form_download_btn ">
                  <button
                    type="button"
                    onClick={() => {
                      const link = document.createElement("a");
                      link.href = `${PDF_FRONT_ASSETS_URL}assets/parentQuestionnaires/Parent-Questionnaire-3-for-grade-1-upwards-KCH.doc`; // Suggested filename
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                  >
                    Download Parent Questionnaire 1
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      const link = document.createElement("a");
                      link.href =
                        `${PDF_FRONT_ASSETS_URL}assets/parentQuestionnaires/Parent-Questionnaire-4-for-grade-1-upwards-KCH.doc`; // Suggested filename
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                  >
                    Download Parent Questionnaire 2
                  </button>
                </div>
                <hr></hr>
                <div className="form_heading_container mb-3">
                  <h5 className="heading-text">Parent Questionnaires</h5>
                </div>
                {/* File Upload Section 1 */}
                <div className="online_program_section mt-4 mb-3">
                  <h5 className="heading-text">Questionnaire Upload 1:</h5>
                </div>
                <div
                  className={`form_file_upload d-flex justify-content-center align-items-center ${
                    isDragging1 ? "dragging" : ""
                  }`}
                  onDragOver={(e) => handleDragOver(e, setIsDragging1)}
                  onDragLeave={() => handleDragLeave(setIsDragging1)}
                  onDrop={(e) =>
                    handleDrop(e, setIsDragging1, setFile1, setFile1Error)
                  }
                >
                  <label className="file-upload-label px-4 py-2">
                    <FaUpload className="me-2" />
                    Click to choose a file or drag here
                    <input
                      type="file"
                      className="d-none"
                      onChange={(e) =>
                        handleFileChange(e, setFile1, setFile1Error)
                      }
                    />
                  </label>
                </div>
                {file1 && (
                  <div className="form_questionnaire mt-2 mb-3">
                    <h5 className="heading-text">{file1.name}</h5>
                    <RiDeleteBin6Line
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteFile(setFile1, setFile1Error)}
                    />
                  </div>
                )}
                {file1Error && <span className="error-text">{file1Error}</span>}
                {/* File Upload Section 2 */}
                <div className="online_program_section mt-4 mb-3">
                  <h5 className="heading-text">Questionnaire Upload 2:</h5>
                </div>
                <div
                  className={`form_file_upload d-flex justify-content-center align-items-center ${
                    isDragging2 ? "dragging" : ""
                  }`}
                  onDragOver={(e) => handleDragOver(e, setIsDragging2)}
                  onDragLeave={() => handleDragLeave(setIsDragging2)}
                  onDrop={(e) =>
                    handleDrop(e, setIsDragging2, setFile2, setFile2Error)
                  }
                >
                  <label className="file-upload-label px-4 py-2">
                    <FaUpload className="me-2" />
                    Click to choose a file or drag here
                    <input
                      type="file"
                      className="d-none"
                      onChange={(e) =>
                        handleFileChange(e, setFile2, setFile2Error)
                      }
                    />
                  </label>
                </div>
                {file2 && (
                  <div className="form_questionnaire mt-2 mb-3">
                    <h5 className="heading-text">{file2.name}</h5>
                    <RiDeleteBin6Line
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDeleteFile(setFile2, setFile2Error)}
                    />
                  </div>
                )}
                {file2Error && <span className="error-text">{file2Error}</span>}
                {/* Checkbox Section
                <div className="form_submiting_div final_form_submited">
                  <label className="form_checkbo me-2">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => {
                        setIsChecked(e.target.checked);
                        setCheckboxError(""); // Clear the error on check
                      }}
                    />
                    <span>
                      By submitting this admission form, we hereby declare that all information given in this application is true and accurate. We hereby abide by the rules and regulations of the school, if admitted.
                    </span>
                  </label>
                  {checkboxError && (
                    <span className="error-text">{checkboxError}</span>
                  )}
                </div> */}
                <div className="form_subbmit_btn mt-4">
                  <button type="button" onClick={handleSubmit}>
                    Submit Details
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Parentquestionnaires;
