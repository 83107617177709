import React, { useEffect, useState,useRef  } from "react";
import Header from "../../../Header/header";
import Footer from "../../../footer/footer";
import Common_banner from "../../common_banner/common_banner";
import Sidebar from "../../side_bar/side_bar";
import "../ap_exam.css";
import { Link } from "react-router-dom";
import { IoCopyOutline } from "react-icons/io5";
import { apExamDetail } from "../../../../controllers/newsExamController/newsExamController";
import { notification } from "antd";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";

function Intro_and_benifit() {
  const [activeTab, setActiveTab] = useState("all");
  const [apExam, setapExam] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  /*********************************************************
   *  This function is used to fetch the policy list
   *********************************************************/
  const ap_exam = async () => {
    setapExam([]);
    setIsLoading(true);
    try {
      const listData = await apExamDetail();
      if (listData?.status === true) {
        setapExam(listData?.result);
      }
    } catch (error) {
      console.error("Failed to fetch policy list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    ap_exam();
  }, []);
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  // Function to handle tab changes
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  const renderList = (items) => {
    return (
      items?.map((item, index) => (
        <li key={index} className="mb-2">
          {item}
        </li>
      )) || null
    );
  };

  //////// copy functionality
  const handleCopy = (code) => {
    navigator.clipboard.writeText(code).then(
      () => {
        // alert("Text copied to clipboard!");
        notification.open({
          message: "Copied.",
          description: `Text copied to clipboard!.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
      },
      (err) => {
        console.error("Failed to copy: ", err);
      }
    );
  };
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Trigger when 10% of the element is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isVisible) {
      const scrollHeight = document.documentElement.scrollHeight;
      const middleOfPage = scrollHeight * 0.39; // 50% of the page height
      window.scrollTo({
        top: middleOfPage,
        behavior: 'smooth' // Optional: Adds smooth scrolling
      });
    }
  }, [isVisible]);

  return (
    <div>
      <Header />
      <div className="inner_mainbanner">
        <Common_banner></Common_banner>
      </div>
      <div className="exam_gapping_div">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3     ffffffffffff">
              <Sidebar></Sidebar>
            </div>
            <div className=" col-md-9 ">
              <div className="exam_placement_program">
                <div id="intro" className="text-start exam_program_text">
                  <h1>
                    AP<sup>®</sup>:
                    <span>
                      {" "}
                      Advanced Placement Program<sup>®</sup>Exams
                    </span>
                  </h1>

                  {/* Description */}
                  {/* <p className="text-muted" >
                  </p> */}
                  <p
                    className="text-muted"
                    dangerouslySetInnerHTML={{
                      __html: apExam.introduction,
                    }}
                  ></p>
                </div>
                <div
                  id="registration-and-schedule"
                  className="d-flex align-items-center justify-content-between px-4 mb-3 exam_webite_btn"
                >
                  <span className="text-start">
                    For more information visit the College Board's website.
                  </span>
                  <div className="event_slider_left_btn exam_btn">
                    <a
                      href="https://international.collegeboard.org/students/ap/taking-ap-india"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button type="button" className="early_read_btn">
                        View Website
                      </button>
                    </a>
                  </div>
                </div>

                {/* Second Card */}
                <div className="d-flex align-items-center justify-content-between px-4 mb-3 exam_webite_list">
                  <span className="text-start">
                    List of Universities around the world offering AP credits
                  </span>
                  <div className="event_slider_left_btn exam_btn">
                    <a
                      href="https://international.collegeboard.org/students/ap/find-universities-recognize-ap"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button type="button" className="early_read_btn">
                        View Website
                      </button>
                    </a>
                  </div>
                </div>

                <div
                  id="registration-and-schedule"
                  className="d-flex align-items-center justify-content-between px-4 mb-3 exam_webite_btn"
                >
                  <span className="text-start">
                  KC High registration form
                  </span>
                  <div className="event_slider_left_btn exam_btn">
                    <a
                      href="https://forms.gle/pTGwhxrGJ2bf2Nq56  "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button type="button" className="early_read_btn">
                        View Website
                      </button>
                    </a>
                  </div>
                </div>
                {/* ///////////// Benifit section */}
                <div className="text-start exam_benifits_section">
                  <h5>{apExam?.benefits_section_title}</h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        apExam.benefits_section_content ||
                        `
                                                <ul>
                                                    <li>Loading...</li>
                                                    
                                                </ul>`,
                    }}
                  ></div>
                </div>
                <hr />
                <div className="text-start exam_read_now">
                  <ul>
                    <li>
                      <Link to="/#" className="text-decoration-none">
                        Read how
                      </Link>{" "}
                      AP helped these students.
                    </li>
                    <li className="mt-2">
                      Review the{" "}
                      <Link to="/#" className="text-decoration-none">
                        AP course descriptions
                      </Link>{" "}
                      for the subject(s) you want to test in. Each description
                      explains which topics are covered in the AP course—and
                      what might be on the exam.
                    </li>
                  </ul>
                </div>
                <div className="text-start  exam_for_students">
                  <h1>
                    AP Exam<span> Registration For Students:</span>
                  </h1>
                  {/* <div className="text-start exam_stand_Exam">
                    <ul>
                      <li className="mb-2">
                        {" "}
                        KC High, Chennai is authorized by the College Board to
                        administer AP Exams.
                      </li>
                    </ul>
                  </div> */}
                  <div
                    className="text-start exam_stand_Exam"
                    dangerouslySetInnerHTML={{
                      __html:
                        apExam.registration_section ||
                        `
                <ul>
                    <li>Loading...</li>
                </ul>`,
                    }}
                  ></div>
                </div>
                <div className="text-start  exam_for_students">
                  <h1>
                    2025 <span> AP Exam schedule</span>
                  </h1>
                  <div className="d-flex align-items-center justify-content-between px-4 mb-3 exam_webite_btn exam_view">
                    <span className="text-start">
                      View the AP Examination schedule and test day details
                    </span>
                    <div className="event_slider_left_btn exam_btn">

                      <a
                        href="https://apcentral.collegeboard.org/exam-administration-ordering-scores/exam-dates"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button type="button" className="early_read_btn">
                          Click Here
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="text-start exam_benifits_section"  id="fully-digital-subject">
                  <h5>{apExam?.section1_title}</h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        apExam?.section1_content ||
                        `
                <ul>
                    <li>Loading...</li>
                </ul>`,
                    }}
                  ></div>
                </div>
                <div
                  id="fully-digital-subject"
                  className="text-start exam_benifits_section"
                >
                  <h5>{apExam?.section2_title}</h5>
                  <div    id="hybrid-digital-ap"
                    dangerouslySetInnerHTML={{
                      __html:
                        apExam?.section2_content ||
                        `
                <ul>
                    <li>Loading...</li>
                </ul>`,
                    }}
                  ></div>
                </div>
                {/* //////////////// Hybrid digital */}
                <div
                  id="hybrid-digital-ap"
                  className="text-start exam_benifits_section"
                >
                  <h5>{apExam?.section3_title}</h5>
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        apExam?.section3_content ||
                        `
                <ul>
                    <li>Loading...</li>
                </ul>`,
                    }}
                  ></div>
                </div>
                <div className="d-flex align-items-center justify-content-between px-4 mb-3 exam_webite_btn"   id="regular-join-ap">
                  <span className="text-start" >
                    For more details please visit the website.
                  </span>
                  <div className="event_slider_left_btn exam_btn" >
                    <a
                      href="https://apcentral.collegeboard.org/exam-administration-ordering-scores/digital-ap-exams/28-exams-digital-2025"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button type="button" className="early_read_btn">
                        Click Here
                      </button>
                    </a>
                  </div>
                </div>
                <div
                  id="regular-join-ap"
                  ref={sectionRef}
                  className="text-start exam_benifits_section"
                >
                  <h5>{apExam?.regular_testing_code_date}</h5>
                </div>
                {/* Tab Buttons */}
                <div className="d-flex exam_main_tabs">
                  <button
                    className={`tabs_button ${activeTab === "all" ? "active" : ""
                      }`}
                    onClick={() => handleTabChange("all")}
                  >
                    All
                  </button>
                  <button
                    className={`tabs_button ${activeTab === "fullyDigital" ? "active" : ""
                      }`}
                    onClick={() => handleTabChange("fullyDigital")}
                  >
                    Fully Digital
                  </button>
                  <button
                    className={`tabs_button ${activeTab === "hybridDigital" ? "active" : ""
                      }`}
                    onClick={() => handleTabChange("hybridDigital")}
                  >
                    Hybrid Digital
                  </button>
                </div>

                {/* Tab Content */}
                <div className="mt-4">
                  {activeTab === "all" && (
                    <div>
                      <div className=" d-flex justify-content-between align-items-center exam_course">
                        <span>Course Name</span>
                        <span>Join Code</span>
                      </div>
                      <div className="list-group" >
                        {/* Active Item */}
                        {apExam?.regular_testing_codes?.map((item) => (
                          <div
                            key={item._id}
                            className="d-flex justify-content-between align-items-center exam_history"
                          >
                            <span className="exam_ap">
                              {item.name} ({item.type})
                            </span>
                            <div className="d-flex align-items-center exam_peged" >
                              <span className="me-2">{item.code}</span>
                              <IoCopyOutline
                                size={16}
                                onClick={() => handleCopy(item.code)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                      <div
                     
                        className="text-start exam_benifits_section"
                      >
                        <h5>
                          {/* Late Testing Join Codes:{" "} */}
                          {apExam?.late_testing_code_date}
                          
                        </h5>
                      </div>

                      
                      <div className="mt-4 d-flex justify-content-between align-items-center exam_course">
                        <span>Course Name</span>
                        <span>Join Code</span>
                      </div>
                      <div className="list-group">
                        {/* Active Item */}
                        {apExam?.late_testing_codes?.map((item) => (
                          <div
                            key={item._id}
                            className="d-flex justify-content-between align-items-center exam_history"
                          >
                            <span className="exam_ap">
                              {item.name}  ({item.type})
                            </span>
                            <div className="d-flex align-items-center exam_peged">
                              <span className="me-2">{item.code}</span>
                              <IoCopyOutline
                                size={16}
                                onClick={() => handleCopy(item.code)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  { activeTab === "fullyDigital" && (
                    <div>
                      {/* Fully Digital Regular Testing Join Codes */}
                      <div className="d-flex justify-content-between align-items-center exam_course">
                        <span>Course Name</span>
                        <span>Join Code</span>
                      </div>
                      <div className="list-group">
                        {apExam?.regular_testing_codes
                          ?.filter((item) => item.type === "Fully Digital")
                          .map((item) => (
                            <div
                              key={item._id}
                              className="d-flex justify-content-between align-items-center exam_history"
                            >
                              <span className="exam_ap">
                                {item.name} ({item.type})
                              </span>
                              <div className="d-flex align-items-center exam_peged">
                                <span className="me-2">{item.code}</span>
                                <IoCopyOutline
                                  size={16}
                                  onClick={() => handleCopy(item.code)}
                                />
                              </div>
                            </div>
                          ))}
                      </div>

                      {/* Fully Digital Late Testing Join Codes */}
                      <div
                        id="late-joining-ap"
                        className="text-start exam_benifits_section"
                      >
                        <h5>
                          Late Testing Join Codes:{" "}
                          {apExam?.late_testing_code_date}
                        </h5>
                      </div>
                      <div className="list-group">
                        {/* {apExam?.late_testing_codes
                          ?.filter((item) => item.type === "Fully Digital")
                          .map((item) => (
                            <div
                              key={item._id}
                              className="d-flex justify-content-between align-items-center exam_history"
                            >
                              <span className="exam_ap">
                                {item.name} ({item.type})
                              </span>
                              <div className="d-flex align-items-center exam_peged">
                                <span className="me-2">{item.code}</span>
                                <IoCopyOutline
                                  size={16}
                                  onClick={() => handleCopy(item.code)}
                                />
                              </div>
                            </div>
                          ))} */}
                           {apExam?.late_testing_codes?.map((item) => (
                          <div
                            key={item._id}
                            className="d-flex justify-content-between align-items-center exam_history"
                          >
                            <span className="exam_ap">
                              {item.name} 
                            </span>
                            <div className="d-flex align-items-center exam_peged">
                              <span className="me-2">{item.code}</span>
                              <IoCopyOutline
                                size={16}
                                onClick={() => handleCopy(item.code)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {activeTab === "hybridDigital" && (
                    <div>
                      {/* Hybrid Digital Regular Testing Join Codes */}
                      <div className="d-flex justify-content-between align-items-center exam_course">
                        <span>Course Name</span>
                        <span>Join Code</span>
                      </div>
                      <div className="list-group">
                        {apExam?.regular_testing_codes
                          ?.filter((item) => item.type === "Hybrid Digital")
                          .map((item) => (
                            <div
                              key={item._id}
                              className="d-flex justify-content-between align-items-center exam_history"
                            >
                              <span className="exam_ap">
                                {item.name} ({item.type})
                              </span>
                              <div className="d-flex align-items-center exam_peged">
                                <span className="me-2">{item.code}</span>
                                <IoCopyOutline
                                  size={16}
                                  onClick={() => handleCopy(item.code)}
                                />
                              </div>
                            </div>
                          ))}
                      </div>

                      {/* Hybrid Digital Late Testing Join Codes */}
                      <div
                        id="late-joining-ap"
                        className="text-start exam_benifits_section"
                      >
                        <h5>
                          Late Testing Join Codes:{" "}
                          {apExam?.late_testing_code_date}
                        </h5>
                      </div>
                      <div className="list-group">
                        {/* {apExam?.late_testing_codes
                          ?.filter((item) => item.type === "Hybrid Digital")
                          .map((item) => (
                            <div
                              key={item._id}
                              className="d-flex justify-content-between align-items-center exam_history"
                            >
                              <span className="exam_ap">
                                {item.name} ({item.type})
                              </span>
                              <div className="d-flex align-items-center exam_peged">
                                <span className="me-2">{item.code}</span>
                                <IoCopyOutline
                                  size={16}
                                  onClick={() => handleCopy(item.code)}
                                />
                              </div>
                            </div>
                          ))} */}
                           {apExam?.late_testing_codes?.map((item) => (
                          <div
                            key={item._id}
                            className="d-flex justify-content-between align-items-center exam_history"
                          >
                            <span className="exam_ap">
                              {item.name} 
                            </span>
                            <div className="d-flex align-items-center exam_peged">
                              <span className="me-2">{item.code}</span>
                              <IoCopyOutline
                                size={16}
                                onClick={() => handleCopy(item.code)}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                {/* ///////////  Receive Admission */}
                <div className="p-4  exam_test_center">
                  <h5>Receive Admission Ticket From Test Center</h5>
                  <p className="mt-3 mb-0">
                    The test center will provide an admission ticket to you
                    after they have ordered your exams. Ticket will be provided
                    no later than <span>1st April 2025</span>. This ticket
                    is to confirm your seat has been reserved.
                  </p>
                </div>
                <div
                  id="testing-ap"
                  className="text-start exam_testing_policies"
                >
                  <h3 style={{fontWeight:"700"}}>{apExam?.testing_policy_content_title}</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        apExam?.testing_policy_content ||
                        `
                <ul>
                    <li>Loading...</li>
                </ul>`,
                    }}
                  ></p>
                </div>

                <div
                  id="regestration-ap"
                  className="text-start exam_benifits_section"
                >
                  <h5>Registration Timeline</h5>
                </div>
                <div className="exam_regular_main_div">
                  <div className=" exam_regular ">
                    <h5 className="flex-1">
                      Registration End Date (for regular and late exams)
                    </h5>
                    <h5 className=" flex-2">
                      {apExam?.registration_start_date}
                    </h5>
                  </div>
                  <div className=" exam_regular ">
                    <h5 className="flex-1">
                      Registration End Date (for regular and late exams)
                    </h5>
                    <h5 className=" flex-2">{apExam?.registration_end_date}</h5>
                  </div>
                  <div className=" exam_regular ">
                    <h5 className="flex-1">
                      AP Exam Fee (INR) per subject Regular Exam
                    </h5>
                    <h5 className=" flex-2">{apExam?.fee_per_regular_exam}</h5>
                  </div>
                  <div className=" exam_regular ">
                    <h5 className="flex-1">
                      AP Exam Fee (INR) per subject Late Exam
                    </h5>
                    <h5 className=" flex-2">{apExam?.fee_per_late_exam}</h5>
                  </div>
                </div>
                <div className="text-start exam_candidates">
                  <h5>
                    Students will need a student account with College Board to
                    register.
                  </h5>
                  <ul>
                    <li>
                      Link to My AP account:{" "}
                      <Link to="/#" className=" text-decoration-none">
                        https://myap.collegeboard.org/login
                      </Link>{" "}
                      <br></br>(Create an account only if you do not already
                      have one you use for SAT, PSAT, AP.)
                    </li>
                    <li>
                      Students must ensure that they have registered for all AP
                      Exams, paid the exam fee to the AP test center, and
                      completed the entire process mentioned in the AP Exam
                      registration instructions by the deadlines listed. There
                      will be no extensions to the deadlines allowed later.
                    </li>
                  </ul>
                </div>
                {/* //////////////// contact us: */}
                <div
                  id="contact-ap"
                  className="text-start exam_benifits_section"
                >
                  <h5>Contact Us: AP Exam</h5>
                </div>
                <div className="exam_regular_main_div">
                  <div className=" exam_contact">
                    <h5 className="flex-1">NAME :</h5>
                    <h5 className=" flex-2">{apExam?.contact_name}</h5>
                  </div>
                  <div className=" exam_contact">
                    <h5 className="flex-1">PHONE : </h5>
                    <h5 className=" flex-2">{apExam?.contact_phone}</h5>
                  </div>
                  <div className=" exam_contact">
                    <h5 className="flex-1">EMAIL :</h5>
                    <h5 className=" flex-2">{apExam?.contact_email}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Intro_and_benifit;
