import { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import './side_bar.css';
import { FiPlus, FiMinus } from "react-icons/fi";
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Sidebar = () => {
    const location = useLocation();
    const [activeKey, setActiveKey] = useState("0");

    // Update active accordion key based on URL path and set the default hash
    useEffect(() => {
        if (location.pathname.startsWith('/intro-and-benifit')) {
            setActiveKey("0"); // AP Exams section
            if (location.hash === "") {
                window.location.hash = '#intro'; // Default hash if none exists
            }
        } else if (location.pathname.startsWith('/advanced-course') || location.pathname.startsWith('/course')) {
            setActiveKey("1"); // AP Courses section
        } else if (location.pathname.startsWith('/sap-exam-intro') || location.pathname.startsWith('/Introduction-and-Benefits')) {
            setActiveKey("2"); // SAP Exam section
        }
    }, [location.pathname, location.hash]);

    // Handle accordion toggle
    const handleAccordionToggle = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };


    
    /////////////////////
    return (
        <>
            <div className='text-start side_bar_heading'>
                <h4>Services List:</h4>
            </div>
            <Accordion
                activeKey={activeKey}
                onSelect={handleAccordionToggle}
                className='accordian_body_main_div'
            >
                <Accordion.Item eventKey="0" className='side_bar_item'>
                    <Accordion.Header>
                        <span>AP Exams</span>
                        <span
                            className='side_bar_plus_minus'
                            style={{ marginLeft: "auto", fontSize: "18px" }}
                        >
                            {activeKey === "0" ? <FiMinus /> : <FiPlus />}
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <ul className='services_names'>
                            <li className={`side_bar_item_fix  ${location.hash === '#intro' ? 'active' : ''}`}>
                                <HashLink to="/intro-and-benifit#intro" className="text-decoration-none">
                                    Introduction and Benefits
                                </HashLink>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#registration-and-schedule' ? 'active' : ''}`}>
                                <HashLink to="/intro-and-benifit#registration-and-schedule" className="text-decoration-none">
                                    Registration and Schedule
                                </HashLink>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#fully-digital-subject' ? 'active' : ''}`}>
                                <HashLink to="/intro-and-benifit#fully-digital-subject" className="text-decoration-none">
                                    Fully Digital Subjects
                                </HashLink>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#hybrid-digital-ap' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/intro-and-benifit#hybrid-digital-ap">
                                        Hybrid Digital Subjects
                                    </HashLink>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#regular-join-ap' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/intro-and-benifit#regular-join-ap">
                                        Regular Testing Join Codes
                                    </HashLink>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#late-joining-ap' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/intro-and-benifit#late-joining-ap">
                                        Late Testing Join codes
                                    </HashLink>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#testing-ap' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/intro-and-benifit#testing-ap">
                                        Testing Policies
                                    </HashLink>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#regestration-ap' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/intro-and-benifit#regestration-ap" className="text-decoration-none">
                                        Registration Timelines
                                    </HashLink>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#contact-ap' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/intro-and-benifit#contact-ap" className="text-decoration-none">
                                        Contact Us
                                    </HashLink>
                                </div>
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1" className='side_bar_item'>
                    <Accordion.Header>
                        <span>AP Courses</span>
                        <span
                            className='side_bar_plus_minus'
                            style={{ marginLeft: "auto", fontSize: "18px" }}
                        >
                            {activeKey === "1" ? <FiMinus /> : <FiPlus />}
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <ul className='services_names'>
                            <li className={`side_bar_item_fix ${location.hash === '#course' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/advanced-course#course">
                                        Courses
                                    </HashLink>
                                </div>
                            </li>

                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2" className='side_bar_item'>
                    <Accordion.Header>
                        <span>SAT Exam</span>
                        <span
                            className='side_bar_plus_minus'
                            style={{ marginLeft: "auto", fontSize: "18px" }}
                        >
                            {activeKey === "2" ? <FiMinus /> : <FiPlus />}
                        </span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <ul className='services_names'>
                            <li className={`side_bar_item_fix ${location.hash === '#Introduction-and-Benefits' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/sap-exam-intro#Introduction-and-Benefits">
                                        Introduction and Benefits
                                    </HashLink>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#Schedule' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/sap-exam-intro#Schedule">
                                        Schedule
                                    </HashLink>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '/assessment-sap' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/sap-exam-intro#assessment-sap">
                                        Assessments
                                    </HashLink>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#register-sap' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/sap-exam-intro#register-sap">
                                        Registration
                                    </HashLink>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#test-day-schedule' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/sap-exam-intro#test-day-schedule">
                                        Test Day Schedule
                                    </HashLink>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#documents-sap' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/sap-exam-intro#documents-sap">
                                        Documents
                                    </HashLink>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#acceptable-photo' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/sap-exam-intro#acceptable-photo">
                                        Identification
                                    </HashLink>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#timingsap' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/sap-exam-intro#timingsap">
                                        Timing
                                    </HashLink>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix ${location.hash === '#contactsap' ? 'active' : ''}`}>
                                <div>
                                    <HashLink to="/sap-exam-intro#contactsap">
                                        Contact Us
                                    </HashLink>
                                </div>
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
};

export default Sidebar;
