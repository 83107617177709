import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
import Common_sticky_button from "./common_sticky_button";
import { bannerHome } from "../../controllers/commonController/cmsController";
import { FRONT_ASSETS_URL, FRONT_URL } from "../../config/constants";
import banner_for_mobile from "../images/founder_slide2.png"
import { Link, useNavigate } from "react-router-dom";

function Home_slider() {
  const navigate =useNavigate()
  const settings = {
    dots: true,
    arrow: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    cssEase: "linear",
    fade: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cmsHomeBanner, setcmsHomeBanner] = useState([]);

  const handleVideoClick = () => {
    const video = videoRef.current;
    if (isPlaying) {
      video.pause();
      setIsPlaying(false);
    } else {
      video.play();
      setIsPlaying(true);
    }
  };

  const cmsHomeBannerList = async () => {
    setcmsHomeBanner([]);
    setIsLoading(true);
    try {
      const listData = await bannerHome();
      if (listData?.status === true) {
        setcmsHomeBanner(listData?.result);
      }
    } catch (error) {
      console.error("Failed to fetch banner list:", error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    cmsHomeBannerList();
  }, []);

  const isYouTubeLink = (url) => {
    if (typeof url !== "string") {
      return false; // Ensure non-string values do not cause errors
    }
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  const getYouTubeEmbedURL = (url) => {
    const videoId = url.split("v=")[1]?.split("&")[0] || url.split("/").pop();
    return `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1&controls=0&rel=0`;
  };

  useEffect(() => {
    const video = videoRef.current;
    if (
      video &&
      cmsHomeBanner?.link?.link &&
      !isYouTubeLink(cmsHomeBanner?.link?.link || "")
    ) {
      setTimeout(() => {
        video.play().catch((error) => {
          console.error("Video play error:", error);
        });
      }, 0);
    }
  }, [cmsHomeBanner?.link]);

  useEffect(() => {
    return () => {
      const video = videoRef.current;
      if (video) {
        video.pause();
      }
    };
  }, []);

  return (
    <div className="container-fluid home_slider_main_section">
      {cmsHomeBanner?.images?.length > 0 ? (
        <Slider {...settings}>
          {/* Dynamic Video Section */}
          <div className="row home_slider_main_content d-flex align-items-center">
            <div className="col-6 text-start home_left_kc_section">
              <div className="text-start home_main_section">
                <h4>KC High has a vision to create</h4>
                <div className="home_copetent_section">
                  <div className="c3_container">
                    <span className="curly_brace">{"{"}</span>
                    <span className="c3_main">C</span>
                    <sup className="c3_sup">3</sup>
                    <span className="curly_brace">{"}"}</span>
                  </div>
                  <h5 className="home_competency_text">
                    Competent, <br /> Courageous <br /> And Caring
                  </h5>
                </div>
                <p className="mb-4">
                  Citizens of the world, empowered{" "}
                  <br className="home_slider_br" /> to build a better tomorrow.
                </p>
                <div className="d-flex gap-3 home_main_btn_mobile anniversary_btn_link"  >
                {/* <Link to={{ pathname: "/founder", state: "founder" }}> */}
                    <button className="home_all_button" onClick={()=>{navigate('/founder',{state:"founder"})}}>
                      Founder's Message
                    </button>
                  {/* </Link> */}
                  {/* <Link to="/anniversary">
                    <button className="home_all_button">
                      25th Anniversary
                    </button>
                  </Link> */}
                  <a
                    href={`${FRONT_URL}${encodeURIComponent("kc's-25th-anniversary")}`}
                    className="home_all_button"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{fontWeight:"600"}}
                  >
                    25th Anniversary
                  </a>
                </div>
              </div>
            </div>
            <div className="col-6 text-end home_section_slider">
              <div className="slide-item">
                <div className="card text-center border-0 explore_slider_section">
                  <div className="order_delivery_img">
                    {cmsHomeBanner?.link?.link ? (
                      isYouTubeLink(cmsHomeBanner.link?.link) ? (
                        <iframe
                          className="order_delivery_main_img img-fluid"
                          src={getYouTubeEmbedURL(cmsHomeBanner?.link?.link)}
                          frameBorder="0"
                          allow="autoplay; encrypted-media"
                          allowFullScreen
                          title="YouTube video"
                          style={{ width: "100%", height: "100%" }}
                        ></iframe>
                      ) : cmsHomeBanner?.link?.link.endsWith(".gif") ? (
                        <img
                          className="order_delivery_main_img img-fluid img-zoom"
                          src={cmsHomeBanner?.link?.link}
                          alt="Animated GIF"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            margin: 0,
                            padding: 0,
                            display: "block",
                          }}
                        />
                      ) : (
                        <video
                          className="order_delivery_main_img img-fluid"
                          ref={videoRef}
                          muted
                          autoPlay
                          loop
                          controls={false}
                          src={cmsHomeBanner?.link?.link}
                          onError={(e) => {
                            console.error("Video load error", e);
                            // Fallback content or alert
                          }}
                        >
                          Your browser does not support the video tag.
                        </video>
                      )
                    ) : (
                      <p>Loading video...</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* Mobile view */}
            <div className="col-6 text-end home_right_slider_section">
              <div className="slide-item">
                <div className="card text-center border-0 explore_slider_section">
                  <div className="order_delivery_video">
                    {cmsHomeBanner?.link?.link ? (
                      isYouTubeLink(cmsHomeBanner?.link?.link) ? (
                        <iframe
                          className="order_delivery_main_img img-fluid"
                          src={getYouTubeEmbedURL(cmsHomeBanner?.link?.link)}
                          frameBorder="0"
                          allow="autoplay; encrypted-media"
                          allowFullScreen
                          title="YouTube video"
                          style={{ width: "100%", height: "100%" }}
                        ></iframe>
                      ) : cmsHomeBanner?.link?.link.endsWith(".gif") ? (
                        <img
                          className="order_delivery_main_img img-fluid"
                          src={cmsHomeBanner?.link?.link}
                          alt="Animated GIF"
                          style={{ width: "100%", height: "100%" }}
                        />
                      ) : (
                        <video
                          className="order_delivery_main_img img-fluid"
                          ref={videoRef}
                          muted
                          autoPlay
                          loop
                          src={cmsHomeBanner?.link?.link}
                          onError={(e) => {
                            console.error("Video load error", e);
                            // Fallback content or alert
                          }}
                        >
                          Your browser does not support the video tag.
                        </video>
                      )
                    ) : (
                      <p>Loading video...</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Dynamic Images Section */}
          {cmsHomeBanner?.images?.length > 0 &&
            cmsHomeBanner.images.map((image, index) => (
              <div
                key={index}
                className="row home_slider_main_content d-flex align-items-center"
              >
                <div className="col-6 text-start home_left_kc_section">
                  <div className="text-start home_main_section">
                    <h4>KC High has a vision to create</h4>
                    <div className="home_copetent_section">
                      <div className="c3_container">
                        <span className="curly_brace">{"{"}</span>
                        <span className="c3_main">C</span>
                        <sup className="c3_sup">3</sup>
                        <span className="curly_brace">{"}"}</span>
                      </div>
                      <h5 className="home_competency_text">
                        Competent, <br /> Courageous <br /> And Caring
                      </h5>
                    </div>
                    <p className="mb-4">
                      Citizens of the world, empowered{" "}
                      <br className="home_slider_br" /> to build a better
                      tomorrow.
                    </p>
                    <div className="d-flex gap-3 home_main_btn_mobile anniversary_btn_link" >
                      {/* <Link to="/founder"> */}
                        <button className="home_all_button" onClick={()=>{navigate('/founder',{state:"founder"})}}>
                          Founder's Message
                        </button>
                      {/* </Link> */}
                      {/* <Link to="/anniversary">
                        <button className="home_all_button">
                          25th Anniversary
                        </button>
                      </Link> */}
                      <a
                       href={`${FRONT_URL}kc's-25th-anniversary`}
                        className="home_all_button"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{fontWeight:"600"}}
                      >
                        25th Anniverddsary
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-6 text-end home_section_slider">
                  <div className="slide-item">
                    <div className="card text-center border-0 explore_slider_section">
                      <div className="order_delivery_img">
                        <img
                          src={`${FRONT_ASSETS_URL}${image.image.replace(
                            "./",
                            ""
                          )}`}
                          alt={`Slide ${index + 1}`}
                          className="order_delivery_main_img img-fluid"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-6 text-end home_right_slider_section'>
                  <div className="slide-item">
                    <div className="card text-center border-0 explore_slider_section">
                      <div className="order_delivery_img">
                        <img
                          src={`${FRONT_ASSETS_URL}${image.image.replace(
                            "./",
                            ""
                          )}`}
                          alt={`Slide ${index + 1}`}
                          className="order_delivery_main_img img-fluid"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      ) : (
        <div className="row home_slider_main_content d-flex align-items-center">
          <div className="col-6 text-start home_left_kc_section">
            <div className="text-start home_main_section">
              <h4>KC High has a vision to create</h4>
              <div className="home_copetent_section">
                <div className="c3_container">
                  <span className="curly_brace">{"{"}</span>
                  <span className="c3_main">C</span>
                  <sup className="c3_sup">3</sup>
                  <span className="curly_brace">{"}"}</span>
                </div>
                <h5 className="home_competency_text">
                  Competent, <br /> Courageous <br /> And Caring
                </h5>
              </div>
              <p className="mb-4">
                Citizens of the world, empowered{" "}
                <br className="home_slider_br" /> to build a better tomorrow.
              </p>
              <div className="d-flex gap-3 home_main_btn_mobile anniversary_btn_link">
                <Link to="/founder">
                  <button className="home_all_button">Founder's Message</button>
                </Link>
                {/* <Link to="/anniversary">
                  <button className="home_all_button">25th Anniversary</button>
                </Link> */}
             
             {/* // href="https://algodev.in:63030/kc's-25th-anniversary" */}
                <a
                  href={`${FRONT_URL}kc's-25th-anniversary`}
                  className="home_all_button"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{fontWeight:"600"}}
                >
                  25th Anniversary
                </a>
              </div>
            </div>
          </div>
          <div className="col-6 text-end home_section_slider">
            <div className="slide-item">
              <div className="card text-center border-0 explore_slider_section">
                <div className="order_delivery_img">
                  {cmsHomeBanner?.link?.link ? (
                    isYouTubeLink(cmsHomeBanner.link?.link) ? (
                      <iframe
                        className="order_delivery_main_img img-fluid"
                        src={getYouTubeEmbedURL(cmsHomeBanner?.link?.link)}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="YouTube video"
                        style={{ width: "100%", height: "100%" }}
                      ></iframe>
                    ) : cmsHomeBanner?.link?.link.endsWith(".gif") ? (
                      <img
                        className="order_delivery_main_img img-fluid img-zoom"
                        src={cmsHomeBanner?.link?.link}
                        alt="Animated GIF"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          margin: 0,
                          padding: 0,
                          display: "block",
                        }}
                      />
                    ) : (
                      <video
                        className="order_delivery_main_img img-fluid"
                        ref={videoRef}
                        muted
                        autoPlay
                        loop
                        controls={false}
                        src={cmsHomeBanner?.link?.link}
                        onError={(e) => {
                          console.error("Video load error", e);
                          // Fallback content or alert
                        }}
                      >
                        Your browser does not support the video tag.
                      </video>
                    )
                  ) : (
                    <p>Loading video...</p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Mobile view */}
          <div className="col-6 text-end home_right_slider_section">
            <div className="slide-item">
              <div className="card text-center border-0 explore_slider_section">
                <div className="order_delivery_video">
                  {cmsHomeBanner?.link?.link ? (
                    isYouTubeLink(cmsHomeBanner?.link?.link) ? (
                      <iframe
                        className="order_delivery_main_img img-fluid"
                        src={getYouTubeEmbedURL(cmsHomeBanner?.link?.link)}
                        frameBorder="0"
                        allow="autoplay; encrypted-media"
                        allowFullScreen
                        title="YouTube video"
                        style={{ width: "100%", height: "100%" }}
                      ></iframe>
                    ) : cmsHomeBanner?.link?.link.endsWith(".gif") ? (
                      <img
                        className="order_delivery_main_img img-fluid"
                        src={cmsHomeBanner?.link?.link}
                        alt="Animated GIF"
                        style={{ width: "100%", height: "100%" }}
                      />
                    ) : (
                      <video
                        className="order_delivery_main_img img-fluid"
                        ref={videoRef}
                        muted
                        autoPlay
                        loop
                        src={cmsHomeBanner?.link?.link}
                        onError={(e) => {
                          console.error("Video load error", e);
                          // Fallback content or alert
                        }}
                      >
                        Your browser does not support the video tag.
                      </video>
                    )
                  ) : (
                    <p>Loading video...</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Common_sticky_button />
    </div>
  );
}

export default Home_slider;


///////////////////////////////////////////