import React, { useState, useEffect } from "react";
import Header from "../../../Header/header";
import Footer from "../../../footer/footer";
import Common_heading from "../Common Heading/common_heading";
import Registration_side_bar from "../registration_side_bar/registration_side_bar";
import { useNavigate } from "react-router-dom";
import { FaTimes } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import {
  otherDetail,
  registrationDetails,
} from "../../../../controllers/submissionsController/submissionsController";
import { notification } from "antd";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
function OtherDetails() {
  const navigate = useNavigate();
  const [applicationId, setApplicationId] = useState("");
  const [errors, setErrors] = useState([]);
   const [isChecked, setIsChecked] = useState(false);
     const [checkboxError, setCheckboxError] = useState("");

  const [formData, setFormData] = useState({
    previously_applied: "",
    previous_application_details: [],
    admission_granted: "",
    admission_taken: "",
    reason_not_taken: "",
    learn_source: [],
    emergency_contacts1: "",
    emergency_contacts2: "",
  });

  const handleRemoveEntry = (index) => {
    setFormData((prev) => ({
      ...prev,
      previous_application_details: prev.previous_application_details.filter(
        (_, i) => i !== index
      ),
    }));
  };
  useEffect(() => {
    const fetchApplicationDetails = async () => {
      const encodedId = localStorage.getItem("applicationId");
      if (!encodedId) {
        console.error("Encoded applicationId not found in localStorage");
        navigate("/enter-student");
        return;
      }
      try {
        const decodedId = atob(encodedId);
        setApplicationId(decodedId);
        const response = await registrationDetails({ id: decodedId });
        if (response?.status === true) {
          setFormData({
            ...response.result,
            id: decodedId,
          });
        }
      } catch (error) {
        console.error("Error fetching registration details:", error);
        navigate("/enter-student");
      }
    };

    fetchApplicationDetails();
  }, [navigate]);

  const handleAddMore = () => {
    setFormData((prev) => ({
      ...prev,
      previous_application_details: [
        ...prev.previous_application_details,
        { child_name: "", program_class: "", applied_year: "" },
      ],
    }));
  };

  const handleChange = (e, index, field) => {
    if (field) {
      const updatedDetails = [...formData.previous_application_details];
      updatedDetails[index][field] = e.target.value;
      setFormData((prev) => ({
        ...prev,
        previous_application_details: updatedDetails,
      }));
    } else {
      const { name, value } = e.target;

      if (name === "previously_applied") {
        // Handle conditional logic based on "Yes" or "No"
        setFormData((prev) => ({
          ...prev,
          [name]: value,
          previous_application_details:
            value === "No"
              ? []
              : prev.previous_application_details.length === 0
              ? [{ child_name: "", program_class: "", applied_year: "" }]
              : prev.previous_application_details,
          admission_granted:
            value === "No" ? "Does not apply" : prev.admission_granted,
          admission_taken:
            value === "No" ? "Does not apply" : prev.admission_taken,
        }));
      } else {
        setFormData((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    }
  };
  const handleSubmit = async () => {
    const errors = {};

    if (formData.previously_applied === "Yes") {
      formData.previous_application_details.forEach((detail, index) => {
        // Validate Child Name
        if (!detail.child_name.trim()) {
          errors[`child_name_${index}`] = "Child Name is required.";
        } else if (/^\s*$/.test(detail.child_name)) {
          errors[`child_name_${index}`] = "Child Name cannot be blank spaces.";
        } else if (!/^[a-zA-Z ]+$/.test(detail.child_name)) {
          errors[`child_name_${index}`] =
            "Child Name can only contain letters and spaces.";
        } else if (detail.child_name.length < 2) {
          errors[`child_name_${index}`] =
            "Child Name must be at least 2 characters long.";
        } else if (detail.child_name.length > 50) {
          errors[`child_name_${index}`] =
            "Child Name must not exceed 50 characters.";
        }

        // Validate Program/Class
        if (!detail.program_class.trim()) {
          errors[`program_class_${index}`] = "Program/Class is required.";
        } else if (/[^a-zA-Z0-9 ]/.test(detail.program_class)) {
          errors[`program_class_${index}`] =
            "Program/Class cannot contain special characters.";
        }

        // Validate Applied Year
        if (!detail.applied_year.trim()) {
          errors[`applied_year_${index}`] = "Applied Year is required.";
        } else if (!/^\d{4}$/.test(detail.applied_year)) {
          errors[`applied_year_${index}`] =
            "Applied Year must be a valid year.";
        } else if (
          parseInt(detail.applied_year, 10) > new Date().getFullYear()
        ) {
          errors[`applied_year_${index}`] =
            "Applied Year cannot be in the future.";
        }
      });
    }
       if (!isChecked) {
      setCheckboxError("You must accept the declaration to submit the form.");
      // hasError = true;
      return;
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors); // Display errors
      return;
    }

    try {
      const response = await otherDetail(formData);
      if (response?.status === true) {
        localStorage.removeItem("applicationId");
        notification.open({
          message: "Submitted.",
          description: `Details submitted successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
        navigate("/");
      } else {
        notification.open({
          message: "Oops!",
          description: `${"Failed to submit details"}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      console.error("Submission error:", error);
      alert("An error occurred while submitting the details.");
    }
  };

  return (
    <div>
      <Header />
      <div className="inner_mainbanner">
        <Common_heading />
      </div>
      <div className="exam_gapping_div">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <Registration_side_bar />
            </div>
            <div className="col-md-9">
              <form>
                <div className="form_heading_container mb-3">
                  <h5 className="heading-text">Other Details</h5>
                </div>
                <div className="d-flex justify-content-between align-items-center radio-container regestration_div">
                  <label className="regestration_applied_heading">
                    Have you applied to KC earlier for your child/children?
                  </label>
                  <div className="d-flex gap-3 regestration_child_div">
                    <label className="regestration_kc_radio">
                      <input
                        type="radio"
                        name="previously_applied"
                        value="Yes"
                        checked={formData.previously_applied === "Yes"}
                        onChange={handleChange}
                        id="radio_btn"
                      />
                      <span className="regestration_applied_yes">Yes</span>
                    </label>
                    <label className="regestration_kc_radio">
                      <input
                        type="radio"
                        name="previously_applied"
                        value="No"
                        checked={formData.previously_applied === "No"}
                        onChange={handleChange}
                        id="radio_btn"
                      />
                      <span className="regestration_applied_yes">No</span>
                    </label>
                  </div>
                </div>
                <hr />
                {formData.previously_applied === "Yes" && (
                  <>
                    <div className="mention_name_section mb-3">
                      <h5 className="heading-text">
                        If yes, please mention name of child/children,
                        program/class, and year applied for
                      </h5>
                    </div>
                    <div className="parent-container">
                      {formData.previous_application_details.map(
                        (detail, index) => (
                          <div
                            className="row g-3 regestration_program position-relative add_more_btn"
                            key={index}
                          >
                            <button
                              type="button"
                              className="btn-icon" // Add your custom styling class
                              onClick={() => handleRemoveEntry(index)}
                              aria-label="Remove"
                            >
                              <RxCross2 size={20} color="#000" />
                            </button>
                            <div className="col-md-4 regestration_form_input program">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Child Name"
                                value={detail.child_name}
                                onChange={(e) =>
                                  handleChange(e, index, "child_name")
                                }
                              />
                              {errors[`child_name_${index}`] && (
                                <p className="error text-danger">
                                  {errors[`child_name_${index}`]}
                                </p>
                              )}
                            </div>
                            <div className="col-md-4 regestration_form_input program">
                              <input
                                type="number"
                                className="form-control"
                                placeholder="Program / Class"
                                value={detail.program_class}
                                onChange={(e) =>
                                  handleChange(e, index, "program_class")
                                }
                              />
                              {errors[`program_class_${index}`] && (
                                <p className="error text-danger">
                                  {errors[`program_class_${index}`]}
                                </p>
                              )}
                            </div>
                            <div className="col-md-4 regestration_form_input program">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Applied Year"
                                value={detail.applied_year}
                                onChange={(e) =>
                                  handleChange(e, index, "applied_year")
                                }
                              />
                              {errors[`applied_year_${index}`] && (
                                <p className="error text-danger">
                                  {errors[`applied_year_${index}`]}
                                </p>
                              )}
                            </div>
                          </div>
                        )
                      )}
                      <div className="text-start mt-3 form_subbmit_btn addmore">
                        <button type="button" onClick={handleAddMore}>
                          Add More
                        </button>
                      </div>
                    </div>
                  </>
                )}
                <div className="d-flex justify-content-between align-items-center radio-container mt-4 regestration_div">
                  <label className="regestration_applied_heading">
                    Were you granted admission?
                  </label>
                  <div className="d-flex gap-3">
                    <label className="regestration_kc_radio">
                      <input
                        type="radio"
                        name="admission_granted"
                        value="Yes"
                        checked={formData.admission_granted === "Yes"}
                        onChange={handleChange}
                        id="radio_btn"
                      />
                      <span className="regestration_applied_yes">Yes</span>
                    </label>
                    <label className="regestration_kc_radio">
                      <input
                        type="radio"
                        name="admission_granted"
                        value="No"
                        checked={formData.admission_granted === "No"}
                        onChange={handleChange}
                        id="radio_btn"
                      />
                      <span className="regestration_applied_yes">No</span>
                    </label>
                    <label className="regestration_kc_radio">
                      <input
                        type="radio"
                        name="admission_granted"
                        value="Does not apply"
                        checked={
                          formData.admission_granted === "Does not apply"
                        }
                        onChange={handleChange}
                        id="radio_btn"
                      />
                      <span className="regestration_applied_yes">
                        Does not apply
                      </span>
                    </label>
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between align-items-center radio-container mt-4 regestration_div">
                  <label className="regestration_applied_heading">
                    If yes, did you take up the admission in KC?
                  </label>
                  <div className="d-flex gap-3">
                    <label className="regestration_kc_radio">
                      <input
                        type="radio"
                        name="admission_taken"
                        value="Yes"
                        checked={formData.admission_taken === "Yes"}
                        onChange={handleChange}
                        id="radio_btn"
                      />
                      <span className="regestration_applied_yes">Yes</span>
                    </label>
                    <label className="regestration_kc_radio">
                      <input
                        type="radio"
                        name="admission_taken"
                        value="No"
                        checked={formData.admission_taken === "No"}
                        onChange={handleChange}
                        id="radio_btn"
                      />
                      <span className="regestration_applied_yes">No</span>
                    </label>
                    <label className="regestration_kc_radio">
                      <input
                        type="radio"
                        name="admission_taken"
                        value="Does not apply"
                        checked={formData.admission_taken === "Does not apply"}
                        onChange={handleChange}
                        id="radio_btn"
                      />
                      <span className="regestration_applied_yes">
                        Does not apply
                      </span>
                    </label>
                  </div>
                </div>
                <hr />
                <div className="d-flex justify-content-between radio-container mt-4 regestration_text_area">
                  <label className="registration-applied-heading">
                    If not, please mention why.
                  </label>
                  <textarea
                    className="custom-textarea"
                    placeholder="Enter your reason here"
                    name="reason_not_taken"
                    value={formData.reason_not_taken}
                    onChange={handleChange}
                    disabled={
                      formData.admission_taken === "Yes" ||
                      formData.admission_taken === "Does not apply"
                    }
                  />
                </div>
                <div className="form_choice_language mt-4">
                  <h5 className="heading-text">
                    How did you learn about KC High?
                  </h5>
                  <div className="d-flex flex-wrap gap-3">
                    {[
                      "Facebook",
                      "Instagram",
                      "Google Search",
                      "Parent Reference",
                      "LinkedIn",
                      "Twitter",
                      "Website",
                      "Other",
                    ].map((source, idx) => (
                      <label className="regestration_kc_radio" key={idx}>
                        <input
                          type="checkbox"
                          value={source}
                          checked={formData.learn_source.includes(source)}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setFormData((prev) => ({
                              ...prev,
                              learn_source: isChecked
                                ? [...prev.learn_source, source]
                                : prev.learn_source.filter(
                                  (item) => item !== source
                                ),
                            }));
                          }}
                        />
                        <span className="regestration_applied_yes">
                          {source}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
                <div className="form_heading_container mt-4">
                  <h5 className="heading-text">Emergency Contacts</h5>
                </div>
                <div className="row g-3">
                  <div className="col-md-4 regestration_form_input">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Emergency contact 1*"
                      value={formData.emergency_contacts1 || ""}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          emergency_contacts1: e.target.value || null,
                        }))
                      }
                    />
                    {errors.emergency_contacts1 && (
                      <span className="text-danger">
                        {errors.emergency_contacts1}
                      </span>
                    )}
                  </div>
                  <div className="col-md-4 regestration_form_input">
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Emergency contact 2"
                      value={formData.emergency_contacts2 || ""}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          emergency_contacts2: e.target.value || null,
                        }))
                      }
                    />
                  </div>
                </div>

                
                {/* Checkbox Section */}
                <div className="form_submiting_div final_form_submited mt-4">
                  <label className="form_checkbo me-2">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={(e) => {
                        setIsChecked(e.target.checked);
                        setCheckboxError(""); // Clear the error on check
                      }}
                    />
                    <span>
                      By submitting this admission form, we hereby declare that all information given in this application is true and accurate. We hereby abide by the rules and regulations of the school, if admitted.
                    </span>
                  </label>
                  {checkboxError && (
                    <span className="error-text">{checkboxError}</span>
                  )}
                </div>
                <div className="form_subbmit_btn mt-4">
                  <button type="button" onClick={handleSubmit}>
                    Proceed to Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default OtherDetails;
