import React, { useState } from 'react'
import Header from '../../../Header/header'
import Footer from '../../../footer/footer'
import Common_heading from '../Common Heading/common_heading'
import Registration_side_bar from '../registration_side_bar/registration_side_bar'

function EmergencyContacts() {
    return (
        <div>
            <Header />
            <div className="inner_mainbanner">
                <Common_heading />
            </div>
            <div className='exam_gapping_div'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <Registration_side_bar />
                        </div>
                        <div className="col-md-9">
                            <form>
                                <div className="form_heading_container mb-3 ">
                                    <h5 className="heading-text">Emergency Contacts</h5>
                                </div>
                                <div>
                                    <div className="row g-3">
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Phone Number 1"
                                                required
                                            />
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Phone Number 2"
                                                required
                                            />
                                        </div>
                                    </div>
                                    <p className='regestration_note'>Note  for Phone: Country Code + Area Code (for landlines only) Example: +91 or +91 (44)</p>
                                </div>
                                <div className='form_subbmit_btn'>
                                    <button type='button'>Proceed to Continue</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}

export default EmergencyContacts