import { Upload , notification} from "antd";
export const beforeUploadPDF = async (file) => {
    try {
      const isPDF = file.type === "application/pdf";
  
      if (!isPDF) {
        notification.error({
          message: "Invalid file type",
          description: "You can only upload PDF files!",
          placement: "topRight",
        });
        
        throw new Error("Invalid file type");
      }
  
      return isPDF;
    } catch (error) {
      console.error("Error in beforeUploadPDF:", error);
      return Upload.LIST_IGNORE;
    }
  };