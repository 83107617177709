import React, { useEffect, useState } from 'react'
import Header from '../../../Header/header'
import Footer from '../../../footer/footer'
import Common_banner from '../../common_banner/common_banner'
import Sidebar from '../../side_bar/side_bar'
import "../sap_exam.css"
import coursed_btn_img from "../../../images/courses_btn.png"
import { Link } from 'react-router-dom'
import { sapExamDetail } from '../../../../controllers/newsExamController/newsExamController'

function SapExamIntro() {
  const [sapExam, setSapExam] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /*********************************************************
     *  This function is used to fetch the policy list
     *********************************************************/
    const sap_exam = async () => {
        setSapExam([]);
        setIsLoading(true);
        try {
            const listData = await sapExamDetail();
            if (listData?.status === true) {
              setSapExam(listData?.result);
            }
        } catch (error) {
            console.error('Failed to fetch policy list:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
      sap_exam();
    }, []);
      // Scroll to the top of the page when the component mounts
useEffect(() => {
  window.scrollTo(0, 0);
}, []);


    // if (isLoading) {
    //   return <div>Loading...</div>;
    // }
  
    if (!sapExam) {
      return <div>No data available</div>;
    }
  return (
    <div>
      <Header />
      <div className="inner_mainbanner">
        <Common_banner></Common_banner>
      </div>
      <div className='exam_gapping_div'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <Sidebar></Sidebar>
            </div>
            <div className=" col-md-9 ">
              <div className='exam_placement_program'>
                <div className='text-start exam_program_text'>
                  <div className='text-start exam_program_text'>
                    <div className='courses_placement'>
                      <h1>
                        SAT <span> Exam</span>
                      </h1>
                      <button type='button'>
                        <img
                          src={coursed_btn_img}
                          alt='coursed_btn_img'
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div id='Introduction-and-Benefits' className='text-start exam_benifits_section schedule_main_text'>
                  <p>
                    The SAT is a university entrance test used worldwide to measure reading, writing, and math skills. It is part of a system to ensure students are ready for university.
                  </p>
                  <ul>
                    <li>Measures what students learn in school, and what they need to succeed in university.</li>
                    <li>One entrance test can be used at many universities in India, and around the world</li>
                    <li>Administered six times a year in India, in the months of August, October, December, March, May and June.</li>
                  </ul>
                </div>
                <div id='Schedule' className='text-start  exam_for_students'>
                  <h1>
                    KC HIGH – SAT Suits Of Assessment – Schedule
                  </h1>
                  <h5 className='schedule_digital_sat'>{sapExam?.digital_sat_title}</h5>
                  <div className=" schedule_test_date">
                    <div className="flex-row flex-header ">
                      <div className="flex-cell">SAT Test Date*</div>
                      <div className="flex-cell">Registration Deadline</div>
                      <div className="flex-cell">
                        Deadline for Changes, Regular Cancellation, and Late Registration**
                      </div>
                    </div>
                    {/* Rows */}
                    {sapExam?.digital_sat_dates?.map((date, index) => (
                      <div
                        key={date._id || index}
                        className="d-flex justify-content-between align-items-center schedule_date"
                      >
                        <span className="flex-cell">{date?.test_date}</span>
                        <span className="flex-cell">{date?.registration_deadline}</span>
                        <span className="flex-cell">{date?.changes_deadline}</span>
                      </div>
                    ))}
                  </div>
                </div>
                {/* ///////////////// Upcoming 2025 Digital SAT Dates */}
                <div className='text-start  exam_for_students'>
                  <h5 className='schedule_digital_sat mt-4'>{sapExam?.upcoming_sat_title}</h5>
                  <div className=" schedule_test_date">
                    <div className="flex-row flex-header ">
                      <div className="flex-cell">SAT Test Date*</div>
                      <div className="flex-cell">Registration Deadline</div>
                      <div className="flex-cell">
                        Deadline for Changes, Regular Cancellation, and Late Registration**
                      </div>
                    </div>
                    {/* Rows */}
                    {sapExam?.upcoming_sat_dates?.map((date, index) => (
                      <div
                        key={date._id || index}
                        className="d-flex justify-content-between align-items-center schedule_date"
                      >
                        <span className="flex-cell">{date?.test_date}</span>
                        <span className="flex-cell">{date?.registration_deadline}</span>
                        <span className="flex-cell">{date?.changes_deadline}</span>
                      </div>
                    ))}
                  </div>
                </div>
                {/* ///////////////// Digital PSAT 10 */}
                <div className='text-start  exam_for_students '>
                  <h5 className='schedule_digital_sat  mt-4'>{sapExam?.digital_psat_10_title}</h5>
                  <div className=" schedule_test_date exam_digital_psat">
                    <div className="flex-row flex-header ">
                      <div className="flex-cell">SAT Test Date*</div>
                      <div className="flex-cell">Registration Deadline</div>
                    </div>
                    {/* Rows */}
                    {sapExam?.digital_psat_10?.map((date, index) => (
                      <div
                        key={date._id || index}
                        className="d-flex justify-content-between align-items-center schedule_date"
                      >
                        <span className="flex-cell">{date?.test_date}</span>
                        <span className="flex-cell">{date?.registration_period}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='text-start  exam_for_students '>
                  <h5 className='schedule_digital_sat  mt-4'>{sapExam?.digital_psat_8_9_title}</h5>
                  <div className=" schedule_test_date exam_digital_psat">
                    <div className="flex-row flex-header ">
                      <div className="flex-cell">SAT Test Date*</div>
                      <div className="flex-cell">Registration Deadline</div>
                    </div>
                    {/* Rows */}
                    {sapExam?.digital_psat_8_9?.map((date, index) => (
                      <div
                        key={date._id || index}
                        className="d-flex justify-content-between align-items-center schedule_date"
                      >
                        <span className="flex-cell">{date?.test_date}</span>
                        <span className="flex-cell">{date?.registration_period}</span>
                      </div>
                    ))}
                  </div>
                </div>
                {/* //////////////////  SAT® Suite of Assessments */}
                <div id='assessment-sap' className='text-start  exam_for_students'>
                  <h1 className='course_assessment'>
                    <span>The </span> <sup> SAT® </sup> <span> Suite of Assessments</span>
                  </h1>
                  <div className="row g-3">
                    {/* Card 1 */}
                    <div className="col-12 col-md-3 mt-4">
                      <div className=" h-100 schedule_baseline">
                        <div className="card-body">
                          <h5 className="card-title ">PSAT™ 8/9</h5>
                          <p className="card-text ">Set readiness baseline</p>
                        </div>
                      </div>
                    </div>
                    {/* Card 2 */}
                    <div className="col-12 col-md-5 mt-4">
                      <div className=" h-100 schedule_baseline">
                        <div className="card-body">
                          <h5 className="card-title ">PSAT/NMSQT® & PSAT™ 10</h5>
                          <p className="card-text ">Allow check-in on student progress</p>
                        </div>
                      </div>
                    </div>
                    {/* Card 3 */}
                    <div className="col-12 col-md-4 mt-4">
                      <div className=" h-100 schedule_baseline">
                        <div className="card-body">
                          <h5 className="card-title ">SAT®</h5>
                          <p className="card-text ">Connect students to college</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='text-start exam_benifits_section'>
                  <h5>Upcoming 2025 Digital SAT Dates</h5>
                  <p>
                    There are two SAT sections:
                  </p>
                  <ul>
                    <li>Mathy</li>
                    <li>Evidence-Based Reading and Writing</li>
                  </ul>
                </div>
                <div className='text-start exam_benifits_section'>
                  <h5>The New Digital SAT</h5>
                  <p>
                    The digital SAT is a shorter, more secure test, a one that is easier to give. It has 2 sections – Reading and writing, and math – with a break in between. Each section is about an hour with a 10 minute break
                  </p>
                </div>
                <div className='text-start exam_benifits_section'>
                  <h5>How is the SAT scored?</h5>
                  <p>
                    Each section of the SAT is scored on a 200 to 800 point scale. Your total SAT score is the sum of your section scores. The highest possible SAT score is 1600.
                  </p>
                </div>
                <div id='register-sap' className="p-4  schedule_college">
                  <h5>How Do I Register For The <span>SAT?</span> </h5>
                  <p className="mt-3 mb-0">
                    SAT registration deadlines fall approximately five weeks before each test date. Register online on the
                  </p>
                  
                  <a
                      href="https://satsuite.collegeboard.org/sat/registration"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button type="button" >
                      College Board website.
                      </button>
                    </a>
                </div>
                <div id='test-day-schedule' className='text-start course_approximate'>
                  <h1>
                    Approximate <span> Test Day Schedule </span> At KC High
                  </h1>
                  <div className='text-start exam_stand_Exam'>
                    <ul >
                      <li>
                        <h4 className="course_time"><span>.</span> 07:00 – 07:15 a.m</h4>
                        <h4 className='course_staff'>: Staff arrive and set up the test center</h4>
                      </li>
                      <li>
                        <h4 className="course_time"><span>.</span> 07:45 a.m</h4>
                        <h4 className='course_staff'>: Test center doors open to students</h4>
                      </li>
                      <li>
                        <h4 className="course_time"><span>.</span> 08:00 a.m</h4>
                        <h4 className='course_staff'>: Test center doors close</h4>
                      </li>
                      <li>
                        <h4 className="course_time"><span>.</span> 08:15 – 08:30 a.m</h4>
                        <h4 className='course_staff'>: Testing starts</h4>
                      </li>
                      <li>
                        <h4 className="course_time"><span>.</span> 10:45 – 11:00 a.m</h4>
                        <h4 className='course_staff'>: Standard time testing ends</h4>
                      </li>
                      <li>
                        <h4 className="course_time"><span>.</span> Noon – 12:45 p.m</h4>
                        <h4 className='course_staff'>: Extended time for time and one-half testing ends</h4>
                      </li>
                      <li>
                        <h4 className="course_time"><span>.</span> 01:00 – 01:15 p.m</h4>
                        <h4 className='course_staff'>: Extended time for double time testing ends</h4>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* ////////////// Admission  */}
                <div id='documents-sap' className='text-start course_document'>
                  <h1>
                    Admission <span>Documents</span> and <span>Identification</span>
                  </h1>
                  <div className='text-start'>
                    <ul>
                      <li>Students must bring 2 documents to the test center   :their admission ticket and a valid photo ID.</li>
                      <li>Students without acceptable documents cannot test.</li>
                    </ul>
                  </div>
                </div>
                <div id='acceptable-photo' className='text-start course_document'>
                  <h1>
                    Acceptable Photo <span>Identification</span>
                  </h1>
                  <p>Valid IDs must meet all of the following requirements:</p>
                  <div className='text-start'>
                    <ul>
                      <li>Be a valid photo identification, issued either by the government or by the school that the student currently attends..</li>
                      <li>Bear the student’s full legal name.</li>
                      <li>Display a name that exactly matches the name on both the admission ticket and the roster, including the order of the names.</li>
                      <li>Be an original document (not photocopied).</li>
                      <li>Be in good condition, with clearly legible English language text, and a clearly visible photograph.</li>
                      <li>Include a recent, recognizable photograph that clearly matches the student’s appearance on test day.</li>
                    </ul>
                  </div>
                </div>
                <div className='text-start course_document'>
                  <h1>
                    Acceptable Photo <span>Identification</span>
                  </h1>
                  <p>Valid IDs must meet all of the following requirements:</p>
                  <div className='text-start'>
                    <ul>
                      <li>Be a valid photo identification, issued either by the government or by the school that the student currently attends..</li>
                      <li>Bear the student’s full legal name.</li>
                      <li>Display a name that exactly matches the name on both the admission ticket and the roster, including the order of the names.</li>
                      <li>Be an original document (not photocopied).</li>
                      <li>Be in good condition, with clearly legible English language text, and a clearly visible photograph.</li>
                      <li>Include a recent, recognizable photograph that clearly matches the student’s appearance on test day.</li>
                    </ul>
                  </div>
                </div>
                <div id='timingsap' className='text-start course_document'>
                  <h1>
                    <span>Timing</span> For The SAT
                  </h1>
                  <p>The two sections of the Digital SAT are Reading and Writing, and Math.</p>
                  <div className='text-start'>
                    <ul>
                      <li>Students will be given 64 minutes to complete the reading and writing sections and 70 minutes to complete the Math Section.</li>
                      <li>Both sections are divided into two modules of equal length with a 10 Minute break between reading and writing section, and Math Section.</li>
                    </ul>
                  </div>
                </div>
                <div id='contactsap' className="p-4 schedule_further_visit">
                  <h5>For further details kindly visit</h5>
                  <Link to="/#">
                    <p>https://satsuite.collegeboard.org/digital/whats-on-the-test/structure</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default SapExamIntro