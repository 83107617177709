import React, { useState, useEffect } from "react";
import "./founder.css";
import { FRONT_ASSETS_URL } from "../../config/constants";

function Founder_top_slider({ founderImage }) {
    const [slides, setSlides] = useState([]);
    const [center, setCenter] = useState(0); // Centered slide index

    // Update slides when founderImage changes
    useEffect(() => {
        if (Array.isArray(founderImage)) {
            setSlides(founderImage);
            setCenter(Math.floor(founderImage.length / 2));
        }
    }, [founderImage]);

    const updatePositions = (offset) => {
        setCenter((prevCenter) => {
            let newCenter = prevCenter + offset;
            if (newCenter < 0) newCenter = slides.length - 1;
            if (newCenter >= slides.length) newCenter = 0;
            return newCenter;
        });
    };

    const handleLeftScroll = () => updatePositions(-1);
    const handleRightScroll = () => updatePositions(1);

    const getSlideClass = (index) => {
        const position = index - center;
        if (position === -2 || position === slides.length - 2) return "position-1";
        if (position === -1 || position === slides.length - 1) return "position-2";
        if (position === 0) return "position-3";
        if (position === 1 || position === -(slides.length - 1)) return "position-4";
        if (position === 2 || position === -(slides.length - 2)) return "position-5";
        return "position-none";
    };

    const goToSlide = (index) => {
        setCenter(index);
    };

    // Auto-slide functionality
    useEffect(() => {
        const interval = setInterval(() => {
            handleRightScroll();
        }, 4000); // Change slide every 3 seconds

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [slides]); // Re-run if slides array changes

    // Add touch gestures for mobile devices
    useEffect(() => {
        const handleTouchStart = (e) => {
            const touchStartX = e.touches[0].clientX;
            const handleTouchEnd = (endEvent) => {
                const touchEndX = endEvent.changedTouches[0].clientX;
                if (touchStartX - touchEndX > 50) handleRightScroll();
                if (touchStartX - touchEndX < -50) handleLeftScroll();
                window.removeEventListener("touchend", handleTouchEnd);
            };
            window.addEventListener("touchend", handleTouchEnd);
        };

        window.addEventListener("touchstart", handleTouchStart);
        return () => {
            window.removeEventListener("touchstart", handleTouchStart);
        };
    }, []);

    return (
        <div className="custom-slider-container">
            <div className="slider-container founder_slider_container">
                {/* Slides */}
                <div className="slider-content" id="slider-content">
                    {slides.map((slide, index) => (
                        <div className={`slide ${getSlideClass(index)}`} key={index}>
                            <div className="founder_media_img">
                                <img src={`${FRONT_ASSETS_URL}${slide}`} alt={`Slide ${index + 1}`} />
                            </div>
                        </div>
                    ))}
                </div>

                {/* Dots for Navigation */}
                <div className="slider-dots slide_dot_founder">
                    {slides.map((_, index) => (
                        <span
                            key={index}
                            className={`dot dot_founder ${index === center ? "active" : ""}`}
                            onClick={() => goToSlide(index)}
                        ></span>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Founder_top_slider;
