import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Header/header';
import Footer from '../footer/footer';
import event_pic2 from "../images/new_deatils_banner.jpg";
import { IoCopyOutline } from "react-icons/io5";
import event2 from "../images/event2.png"

function New_details() {
    const [isSticky, setIsSticky] = useState(false);
    const [activeSection, setActiveSection] = useState(""); // Track the active section

    useEffect(() => {
        const handleScroll = () => {
            if (window.pageYOffset > 380) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }

            // Check which section is in the viewport
            const sections = [
                'intro',
                'registration',
                'fully',
                'hybrid',
                'regulartesting',
                'latetesting',
                'testingpolicies',
                'registrationtimelines'
            ];

            // Loop through each section and check if it's in the viewport
            for (let i = 0; i < sections.length; i++) {
                const section = document.getElementById(sections[i]);
                if (section) {
                    const top = section.offsetTop - 150;
                    const bottom = top + section.offsetHeight;
                    const scrollPosition = window.scrollY;

                    if (scrollPosition >= top && scrollPosition < bottom) {
                        setActiveSection(sections[i]);
                        break;
                    }
                }
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleLinkClick = (event, targetId) => {
        event.preventDefault();
        setActiveSection(targetId); // Set the active section based on the clicked link

        const targetElement = document.getElementById(targetId);
        if (targetElement) {
            window.scrollTo({
                top: targetElement.offsetTop - 85,
                behavior: 'smooth',
            });
        }
    };

    // Function to handle tab changes
    const [activeRegular, setActiveRegular] = useState('all');
    const handleTabChange = (tab) => {
        setActiveRegular(tab);
    };
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
    //////// copy functionality
    const handleCopy = () => {
        navigator.clipboard.writeText("6PEGED").then(
            () => {
                alert("Text copied to clipboard!");
            },
            (err) => {
                console.error("Failed to copy: ", err);
            }
        );
    };




    const navigate = useNavigate();
    const handleMoveToRegestrationPagek = () => {
        navigate('/online-form');
    };


    // Function to handle tab changes

    //////// copy functionality






    return (
        <div>
            <Header />
            <div>
                <div className="inner_mainbanner">
                    <div className='exam_gapping_div '>
                        <div className="container-fluid">
                            <div className=' news_banner_main_div details_banner_section'>
                                <div className="row align-items-center justify-content-center">
                                    {/* Left Section - Text */}
                                    <div className="col-md-6 text-start  details_banner_heading">
                                        <h4>
                                            Marking <span>25 Years - 25th</span>
                                        </h4>
                                        <hr />
                                        <div className="details_meta">
                                            <p>Written by <br></br><span>Tech Nerd KC</span></p>
                                            <p>Posted on <br></br><span>October 21, 2024</span></p>
                                        </div>
                                    </div>

                                    {/* Right Section - Image */}
                                    <div className="col-md-6 d-flex justify-content-center">
                                        <div className="details_banner_img">
                                            <img
                                                src={event_pic2}
                                                alt="common_banner"
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="exam_gapping_div     ">
                    <div className="container-fluid">
                        <div className="info_all_nav mb-4">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <nav aria-label="breadcrumb" className="news_info_breadcrumb">
                                        <ol className="breadcrumb mb-0">
                                            <li className="breadcrumb-item"><Link to="/#">Home</Link></li>
                                            <li className="breadcrumb-item active" aria-current="page"><Link to="/news"> <span>News</span></Link></li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="details_step_gapping">
                    <div className="container-fluid">
                        <div className="row">

                            {/* Content Section */}
                            <div className="col-md-8 text-start">
                                <div id="intro" className="content-block text-start">
                                    <div className="container d-flex justify-content-center align-items-center ">
                                        <div className="text-start" >
                                            <div className='text-start exam_program_text'>
                                                <h1>
                                                    Advanced Placement Program
                                                </h1>

                                                {/* Description */}
                                                <p className="text-muted">
                                                    College Board's Advanced Placement Program<sup>®</sup> is recognized by universities around the
                                                    world as a standard of academic excellence. Since 1955, the AP<sup>®</sup> Program has enabled
                                                    millions of students to take U.S. university undergraduate-level courses and earn undergraduate
                                                    degree credit, advanced placement, or both while still in high school/secondary school. Nearly all
                                                    colleges and universities in the United States and Canada — as well as more than 700 universities
                                                    worldwide — consider AP Exam scores. For more information visit the College Board's website.
                                                </p>
                                            </div>
                                            {/* ///////////// Benifit section */}
                                            <div className='text-start exam_benifits_section'>
                                                <h5 >Benefits Of Taking AP Program Exams</h5>
                                                <ul >
                                                    <li className="mb-2"> Stand out in university admissions</li>
                                                    <li className="mb-2"> Earn university credit</li>
                                                    <li className="mb-2"> Skip introductory university classes</li>
                                                    <li className="mb-2"> Save money on tuition</li>
                                                    <li className="mb-2"> Build skills and confidence</li>
                                                    <li className="mb-2"> Earn higher first-year grades at university (per )</li>
                                                </ul>
                                            </div>
                                            <div className='text-start exam_read_now'>
                                                <ul >
                                                    <li>
                                                        <Link to="/#" className=" text-decoration-none">
                                                            Read how
                                                        </Link>{' '}
                                                        AP helped these students.
                                                    </li>
                                                    <li className="mt-2">
                                                        Review the{' '}
                                                        <Link to="/#" className=" text-decoration-none">
                                                            AP course descriptions
                                                        </Link>{' '}
                                                        for the subject(s) you want to test in. Each description explains which
                                                        topics are covered in the AP course—and what might be on the exam.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='text-start exam_benifits_section'>
                                                <ul >
                                                    <li className="mb-2"> Stand out in university admissions</li>
                                                    <li className="mb-2"> Earn university credit</li>
                                                    <li className="mb-2"> Skip introductory university classes</li>
                                                    <li className="mb-2"> Save money on tuition</li>
                                                    <li className="mb-2"> Build skills and confidence</li>
                                                    <li className="mb-2"> Earn higher first-year grades at university (per )</li>
                                                </ul>
                                            </div>
                                            <div className='text-start exam_read_now'>
                                                <ul >
                                                    <li>
                                                        <Link to="/#" className=" text-decoration-none">
                                                            Read how
                                                        </Link>{' '}
                                                        AP helped these students.
                                                    </li>
                                                    <li className="mt-2">
                                                        Review the{' '}
                                                        <Link to="/#" className=" text-decoration-none">
                                                            AP course descriptions
                                                        </Link>{' '}
                                                        for the subject(s) you want to test in. Each description explains which
                                                        topics are covered in the AP course—and what might be on the exam.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='text-start exam_read_now'>
                                                <ul >
                                                    <li>
                                                        <Link to="/#" className=" text-decoration-none">
                                                            Read how
                                                        </Link>{' '}
                                                        AP helped these students.
                                                    </li>
                                                    <li className="mt-2">
                                                        Review the{' '}
                                                        <Link to="/#" className=" text-decoration-none">
                                                            AP course descriptions
                                                        </Link>{' '}
                                                        for the subject(s) you want to test in. Each description explains which
                                                        topics are covered in the AP course—and what might be on the exam.
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div id="registration" className="content-block  text-start">
                                    <div className="container d-flex justify-content-center align-items-center ">
                                        <div className="text-start " >
                                            <div className='text-start  exam_for_students'>
                                                <h1>
                                                    AP Exam<span> Registration For Students:</span>
                                                </h1>
                                                <div className='text-start exam_stand_Exam'>
                                                    <ul >
                                                        <li className="mb-2"> KC High, Chennai is authorized by the College Board to administer AP Exams.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='text-start  exam_for_students'>
                                                <h1>
                                                    2025 <span> AP Exam schedule</span>
                                                </h1>
                                                <div className="d-flex align-items-center justify-content-between px-4 mb-3 exam_webite_btn exam_view">
                                                    <span className="text-start">
                                                        View the AP Examination schedule and test day details
                                                    </span>
                                                    <div className="event_slider_left_btn exam_btn">
                                                        <button type="button" className="early_read_btn">
                                                            Click Here
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='text-start exam_benifits_section'>
                                                <h5 >28 AP Exams Go Digital In May 2025</h5>
                                                <p>
                                                    Starting in May 2025, standard paper testing will be discontinued for 28 AP Exams—
                                                    These exams will move to the Bluebook digital testing application.
                                                </p>
                                                <ul>
                                                    <li>Students will take digital exams in the  <Link to="/#">
                                                        Bluebook
                                                    </Link>{' '} app.</li>
                                                    <li>
                                                        Paper exams in these subjects will only be available to students approved by the College Board to
                                                        receive a paper exam for digital assessments.
                                                    </li>
                                                    <li>
                                                        For math, science, and economics exams that require graphing or symbolic notation, students will
                                                        view free-response questions and prompts in Bluebook and write their answers in paper exam booklets.
                                                    </li>
                                                    <li>Late-testing exams in these subjects are also in digital format.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                */}
                            </div>
                            {/* Extra Content Section */}
                            <div className="col-md-4 mb-4">
                                <div className='text-start exam_program_text'>
                                    <h1>
                                        Related <span>News</span>
                                    </h1>
                                </div>
                                <div className='news_lsiting_slide mb-4'>
                                    <Link to="/details_page">
                                        <div className="home_event_card event_card">
                                            <div className="home_event_custom_card card text-center border">
                                                {/* <div className="home_card_img_container">
                                                    <img
                                                        src={event2}
                                                        className="img-fluid card-img"
                                                        alt="Sample Event 3"
                                                    />
                                                </div> */}
                                                <div className="home_event_card_body text-start event_slider_contnet new_deatils">
                                                    <h4 className="event-label">
                                                        EVENT <span>/ By Tech Nerd KC</span>
                                                    </h4>
                                                    <h5 className="card-title text-start">Yet Another Event</h5>
                                                    <p className="text-start">This is a short description of yet another sample event.</p>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="text-start event-date home_event_slider_bottom">
                                                    December 15, 2024
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className='news_lsiting_slide mb-4'>
                                    <Link to="/details_page">
                                        <div className="home_event_card event_card">
                                            <div className="home_event_custom_card card text-center border">
                                                <div className="home_event_card_body text-start event_slider_contnet new_deatils">
                                                    <h4 className="event-label">
                                                        EVENT <span>/ By Tech Nerd KC</span>
                                                    </h4>
                                                    <h5 className="card-title text-start">Yet Another Event</h5>
                                                    <p className="text-start">This is a short description of yet another sample event.</p>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="text-start event-date home_event_slider_bottom">
                                                    December 15, 2024
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className='news_lsiting_slide mb-4'>
                                    <Link to="/details_page">
                                        <div className="home_event_card event_card">
                                            <div className="home_event_custom_card card text-center border">
                                                <div className="home_event_card_body text-start event_slider_contnet new_deatils">
                                                    <h4 className="event-label">
                                                        EVENT <span>/ By Tech Nerd KC</span>
                                                    </h4>
                                                    <h5 className="card-title text-start">Yet Another Event</h5>
                                                    <p className="text-start">This is a short description of yet another sample event.</p>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="text-start event-date home_event_slider_bottom">
                                                    December 15, 2024
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                <div className='news_lsiting_slide mb-4'>
                                    <Link to="/details_page">
                                        <div className="home_event_card event_card">
                                            <div className="home_event_custom_card card text-center border">
                                                <div className="home_event_card_body text-start event_slider_contnet new_deatils">
                                                    <h4 className="event-label">
                                                        EVENT <span>/ By Tech Nerd KC</span>
                                                    </h4>
                                                    <h5 className="card-title text-start">Yet Another Event</h5>
                                                    <p className="text-start">This is a short description of yet another sample event.</p>
                                                </div>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="text-start event-date home_event_slider_bottom">
                                                    December 15, 2024
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default New_details;
