import React, { useEffect, useState } from 'react'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import curriculum_img from '../images/our_campus.png'
import { FaLocationDot } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOurCampusData } from '../../features/tasks/ourCampusSlice';
import { FRONT_ASSETS_URL } from '../../config/constants';

function OurCampus({ toggleMap, onTabChange }) {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState('RR Campus');
    const { ourCampuses} = useSelector((state) => state.ourCampusReducer);

    useEffect(() => {
        if (ourCampuses.length === 0) {
            dispatch(fetchOurCampusData());
          }
        
      }, [ourCampuses, dispatch]);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };
      // Scroll to the top of the page when the component mounts
useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    if (onTabChange) {
      onTabChange(activeTab);
    }
  }, [activeTab, onTabChange]);

    return (
        <div className="rr_campus_main_section">
            <div className="container-fluid">
                <div className="text-start home_school_heading">
                    <h2>
                        Our <span className="highlight">Campuses</span>
                    </h2>
                    <p>
                        Providing the IGCSE and International Baccalaureate Diploma Program (IBDP)
                    </p>
                </div>

                <div className="d-flex flex-column align-items-start my-4">
                    <div className=" contact_us_campus  rounded-pill ">
                        <button
                            className={`curriculum_tab_button ${activeTab === 'RR Campus' ? 'active' : ''}`}
                            onClick={() => handleTabClick('RR Campus')}
                        >
                            {ourCampuses?.[0]?.short_name}
                        </button>
                        <button
                            className={`curriculum_tab_button ${activeTab === 'OP Campus' ? 'active' : ''}`}
                            onClick={() => handleTabClick('OP Campus')}
                        >
                            {ourCampuses?.[1]?.short_name}
                        </button>
                    </div>
                </div>

                <div className="tab-content  tabs_all_content op_campus_div">
                    {activeTab === 'RR Campus' ? (
                        <div className="row">
                            <div className="col-4 curriculum_mobile">
                                <div className='curriculum_slider rr_campus_tabs'>
                                    <img
                                        src={`${FRONT_ASSETS_URL}${ourCampuses?.[0]?.contactpage_image}`}
                                        alt="Slide 1"
                                    />
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="text-start contact_us_our_campus">
                                    <h2>{ourCampuses?.[0]?.short_name}</h2>
                                    <p>{ourCampuses?.[0]?.content} </p>
                                    <ul className=" contact_us_location">
                                        <li className=" all_kc_footer">
                                            <h5>Connect with us</h5>
                                            <ul className="list-unstyled   contact_us_olympia">
                                                <li className='contact_us_location'>
                                                    <FaLocationDot className='fa_locate_One'/>
                                                    <span className="contact_address_tag">
                                                        {ourCampuses?.[0]?.address}
                                                    </span>
                                                </li>
                                                <li className='contact'>
                                                    <MdMail />
                                                    <span className="contact_address_tag">{ourCampuses?.[0]?.email}</span>
                                                </li>
                                                <li className='contact'>
                                                    <IoCall />
                                                    <span className="contact_address_tag">{ourCampuses?.[0]?.phone}</span>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-4 curriculum_mobile">
                                <div className='curriculum_slider rr_campus_tabs'>
                                    <img
                                        src={`${FRONT_ASSETS_URL}${ourCampuses?.[1]?.contactpage_image}`}
                                        alt="Slide 1"
                                    />
                                </div>
                            </div>
                            <div className="col-8">
                                <div className="text-start contact_us_our_campus">
                                <h2>{ourCampuses?.[1]?.short_name}</h2>
                                <p>{ourCampuses?.[1]?.content} </p>
                                    <ul className=" contact_us_location">
                                        <li className=" all_kc_footer">
                                            <h5>Connect with us</h5>
                                            <ul className="list-unstyled   contact_us_olympia">
                                                <li className="contact_us_location">
                                                    <FaLocationDot className='fa_locate'/>
                                                    <span className="contact_address_tag">
                                                    {ourCampuses?.[1]?.address}
                                                    </span>
                                                </li>
                                                <li className='contact'>
                                                    <MdMail />
                                                    <span className="contact_address_tag">{ourCampuses?.[1]?.email}</span>
                                                </li>
                                                <li className='contact'>
                                                    <IoCall />
                                                    <span className="contact_address_tag">{ourCampuses?.[1]?.phone}</span>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div >
    )
}

export default OurCampus;
