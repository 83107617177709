import React, { useState, useEffect, useRef } from "react";
import Header from "../../Header/header";
import Footer from "../../footer/footer";
import "../admission_section.css";
import Common_faq from "../../Academics_Section/common_faq";
import { FaEnvelope, FaPhoneAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import rr_campus_img from "../../images/rr_campus.png";
import admission_indo from "../../images/admission_info.png";
import admission_video from "../../video/admission_info3.mp4";
// import GetInTouch from './get_in_touch/get_in_touch';
import Process_Step from "./admission_process_tabs_Section/process_step";
import {
  bannerHome,
  cmsFAQ,
  list,
} from "../../../controllers/commonController/cmsController";
import { FRONT_ASSETS_URL } from "../../../config/constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchOurCampusData } from "../../../features/tasks/ourCampusSlice";

function Admission_info() {
  const navigate = useNavigate();
  const handleMoveToRegestrationPagek = () => {
    navigate("/enter-student");
  };
  const [activeSection, setActiveSection] = useState(null);
  const [hoveredSection, setHoveredSection] = useState(null);
  // const [cmsContentList, setcmsContentList] = useState([]);
    const [cmsHomeBanner, setcmsHomeBanner] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sections, setSections] = useState([]);
  const [FAQ, setFAQ] = useState([]);
  const [stats, setstats] = useState([]);
  const { generalDatas } = useSelector((state) => state.footerReducer);
  const generalData = generalDatas?.[0] || {};
  const dispatch = useDispatch();
  const { ourCampuses } = useSelector((state) => state.ourCampusReducer);
  // console.log(generalData, "general data");
  const [isDataFetched, setIsDataFetched] = useState(false);

  const sectionsRef = useRef([]);
  const statsRef = useRef([]);
  const faqRef = useRef([]);
  /*********************************************************
   *  This function is use to fetch cms content list
   *********************************************************/
  const cmsContent = async () => {
    // setcmsContentList([]);
    setIsLoading(true);
    try {
      const option = {
        type: "",
        condition: {
          status: "A",
          type: "child-potential",
        },
      };
      const listData = await list(option);
      if (listData?.status === true) {
        // console.log(listData?.result, "child potential");
        const updatedSections = listData.result.map((item) => ({
          id: item._id,
          title: item.title,
          description: item.content,
          image: `${FRONT_ASSETS_URL}${item.image}`,
        }));
        setSections(updatedSections);
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      setIsLoading(false);
    }
  }; //end
  /*********************************************************
   *  This function is use to fetch cms content list
   *********************************************************/
  const cmsContentStat = async () => {
    // setcmsContentStat([]);
    setIsLoading(true);
    try {
      const option = {
        type: "",
        condition: {
          status: "A",
          type: "Stats",
        },
      };
      const listData = await list(option);
      if (listData?.status === true) {
        // console.log(listData?.result, "stat");
        const updatedState = listData.result.map((item) => ({
          number: item.title,
          text: item.content,
        }));
        setstats(updatedState);
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      setIsLoading(false);
    }
  }; //end
  /*********************************************************
   *  This function is use to fetch cms content list
   *********************************************************/
  const cmsFAQAdmission = async () => {
    setFAQ([]);
    setIsLoading(true);
    try {
      const option = {
        type: "",
        condition: {
          status: "A",
          page: {
            $in: ["admission-process"],
          },
        },
      };
      const listData = await cmsFAQ(option);
      if (listData?.status === true) {
        // console.log(listData?.result, "FAQ");
        setFAQ(listData?.result);
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      setIsLoading(false);
    }
  }; //end

  const cmsHomeBannerList = async () => {
    setcmsHomeBanner([]);
    setIsLoading(true);
    try {
      const listData = await bannerHome();
      if (listData?.status === true) {
        setcmsHomeBanner(listData?.result);
      }
    } catch (error) {
      console.error("Failed to fetch banner list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    cmsContent();
    cmsContentStat();
    cmsFAQAdmission();
  }, []);
  useEffect(() => {
    if (ourCampuses.length === 0) {
      dispatch(fetchOurCampusData());
    }
  }, [ourCampuses]);
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
    cmsHomeBannerList()
  }, []);

  const [isMobileView, setIsMobileView] = useState(false);
  
    // Check screen size on mount and resize
    useEffect(() => {
      const checkScreenSize = () => setIsMobileView(window.innerWidth <= 768);
      checkScreenSize(); // Initial check
      window.addEventListener("resize", checkScreenSize);
  
      return () => {
        window.removeEventListener("resize", checkScreenSize);
      };
    }, []);
  return (
    <>
      <Header></Header>
      <div className="policies_gapping_div">
        <div className="container-fluid">
          <div className="row">
            <div className=" col-md-12 ">
              <div className="text-center calendar_school_text admission_info_mobile">
                <h2>
                  Unlock Your <span> Child’s Potential</span>
                </h2>
                <p className="founder_paragraph">
                  Join a vibrant and supportive community where every child is
                  inspired to learn, explore, and thrive. At our school, we
                  celebrate each student’s unique strengths, encouraging them to
                  reach their fullest potential in a safe, inclusive
                  environment. From innovative academics to character-building
                  activities, we’re committed to helping your child.
                </p>
              </div>
            </div>
          </div>
          <div className="admission_hover_section">
            <img
              // src={hoveredSection || admission_indo}
              src={process.env.PUBLIC_URL+'/images/AdmissionProcess.jpg'}
              alt="Background"
              className="admission_background_image"
            />
            <div className="row m-0">
              {sections.map((section) => (
                <div
                  key={section.id}
                  className={`col-md-3 p-0 admsiion_section_active ${activeSection === section.id ? "active" : ""
                    }`}
                  onMouseEnter={() => {
                    setActiveSection(section.id);
                    setHoveredSection(section.image);
                  }}
                  onMouseLeave={() => {
                    setActiveSection(null);
                    setHoveredSection(null);
                  }}
                >
                  <h5 className="admission_section_title">{section.title}</h5>
                  <div
                    className={`admission_overlay ${activeSection === section.id ? "visible" : ""
                      }`}
                  >
                    <div
                      className={`admission_section_content ${activeSection === section.id ? "visible" : ""
                        }`}
                    >
                      <p>{section.description.slice(0,100)}</p>
                      <Link to="/#" className="admission_read_more">
                        Read More <span>&rarr;</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="career_option_connect info_gapping_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 career_connect_div">
              <div className="text-start career_with_us admission_global_text">
                <h2>
                  <span>KC </span>High
                </h2>
                <p>
                  Where global perspectives meet a rooted ethos: a Cambridge and
                  IB school in Chennai, guiding students from Nursery to high
                  school.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex justify-content-center ">
                {stats.map((stat, index) => (
                  <div
                    key={index}
                    className="col-md-4 text-center admission_stats_card"
                  >
                    <div className="stats-content admission_stats_content">
                      <h3 className="stat-number">{stat.number}</h3>
                      <p className="stat-text">{stat.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Process_Step />
      </div>
      <div className="home_founder_section">
        <div className="container-fluid">
          <div className="all_section_margin">
            <div className="row">
              <div className="align-items-center academic_early_message shaping_tomorrow">
                <div className="col-md-10">
                  <div className="d-flex align-items-center home_founder_sub_image">
                    <div className="founder-text ms-3 text-start founder_message_all_text">
                      <h2 className="academics_ready_text">
                        Shaping Tomorrow’s Leaders Today
                      </h2>
                      <p className="text-white together">
                        Together, we will build a strong foundation for your
                        child's future, nurturing a lifelong love of learning
                        and exploration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-2">
                  <div
                    className="text-end"
                    onClick={handleMoveToRegestrationPagek}
                  >
                    <button className="academics_button">Register Now!</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //RR campus */}
      <div className=" d-flex justify-content-center info_gapping_section">
        <div className="container-fluid age_group_m_div">
          <div className="row justify-content-center w-100 age_group_mobile">
            <div className="col-md-2">
              <div className='admission_info_campus'>
                Campus
              </div>
            </div>
            {ourCampuses.map((campus, index) => (
              <div className="col-md-5" key={index}>
                <div className="rr_main_div">
                  <div className="rr_card_bg">
                    <div className="card-body text-start">
                      <h5 className="card-title">{campus.short_name}</h5>
                      <h6 className="card-text">
                        Age Group :<span> {campus.age_group}</span>
                      </h6>
                      <div className="position-relative text-white rr_hovering_section">
                        <img
                          src={`${FRONT_ASSETS_URL}${campus.admissionpage_image}`}
                          alt="Campus Background"
                          className="campus-image"
                        />
                        <div className="campus-content position-absolute rr_hovering_text rr_hovering_content">
                          <p>{campus.content}</p>
                          <div className="form_subbmit_btn rr_hovering_btn">
                            <button type="button">
                            <a
                          href={campus?.location}
                          target="_blank"
                          style={{ textDecoration: "none", color: "inherit" }}
                        >Get Direction
                        </a></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="admission_faq_bakground">
        <Common_faq faqData={FAQ}></Common_faq>
      </div>
      {/* ////// Virtual Schoold Tour */}
      <div className="virtual_school_tour_wrapper d-flex align-items-center info_gapping_section">
        <div className="container-fluid react_us_mobile">
          <div className="row w-100" id="admissionDetail">
            <div className="col-md-6">
              <div className="text-column d-flex flex-column justify-content-center ps-md-5 text-start virtual_tour_heading">
                <h2>
                  Reach <span>Us</span>
                </h2>
                <p>For any admission related queries,</p>
                <h5>
                  Contact <span>{generalData?.admission_name}</span>
                </h5>
                <div className="contact_detail">
                  <div className="info_icon_container">
                    <FaEnvelope className="icon" />
                  </div>
                  <span>{generalData?.admission_email}</span>
                </div>
                <div className="contact_detail">
                  <div className="info_icon_container">
                    <FaPhoneAlt className="icon" />
                  </div>
                  <span className="text-decoration-none">
                    {generalData?.admission_contact}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-6 px-0">
              {/* <div className="info_image_container">
                <img
                  src={virtual_img}
                  alt="School Tour"
                  className="img-fluid rounded-image"
                />
              </div> */}
              <div className="info_image_container">
                <img src={cmsHomeBanner?.link?.link} alt="image"  className="img-fluid rounded-video"style={{
    width: "500px",
    height: isMobileView? "175px" : "238px", // Add "px" for valid CSS
    objectFit: "cover",
    marginBottom:isMobileView?"-20px":""
}}
 />
                {/* <video
                  width="100%"
                  height="100%"
                  // controls
                  className="img-fluid rounded-video"
                  autoPlay
                  muted
                  loop
                >
                  <source src={admission_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Admission_info;
