// src/components/Footer.js
import "./footer.css"; // Custom styles
import { FaFacebookF, FaSquareYoutube, FaYoutube } from "react-icons/fa6";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaLinkedinIn } from "react-icons/fa";
import { IoLocationSharp } from "react-icons/io5";
import { IoIosCall } from "react-icons/io";
import React, { useState, useEffect } from "react";
import { FaChevronDown, FaChevronUp, FaTwitter } from "react-icons/fa";
import { Collapse } from "react-collapse";
import logo from "../images/logo.png";
import { FaLocationDot } from "react-icons/fa6";
import { MdMail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { MdKeyboardArrowUp } from "react-icons/md";
import { fetchGeneralData } from "../../features/tasks/footerSlice";

const Footer = () => {
  const dispatch = useDispatch();
  const { generalDatas, isLoading, error } = useSelector((state) => state.footerReducer);
  // Fallback to an empty object if data is unavailable
  const generalData = generalDatas?.[0] || {};
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [showButton, setShowButton] = useState(false);


  useEffect(() => {
    // Dispatch the fetch action only if data is not already present
    if (generalDatas.length === 0) {
      dispatch(fetchGeneralData());
    }

    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch, generalDatas]);


  // const generalData = generalDatas|| {};

  const [openSection, setOpenSection] = useState(null);

  const toggleDropdown = (section) => {
    setOpenSection(openSection === section ? null : section);
  };




  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop = window.scrollY;
  //     const windowHeight = document.documentElement.scrollHeight - window.innerHeight;
  //     const scrollPercentage = (scrollTop / windowHeight) * 100;
  //     setScrollProgress(scrollPercentage);
  //     setShowButton(scrollTop > 200); // Show button after 200px
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, []);
  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  const handleScroll = () => {
    const scrollTotal = document.documentElement.scrollHeight - window.innerHeight;
    const scrollPosition = window.scrollY;

    const scrollPercentage = (scrollPosition / scrollTotal) * 100;
    setScrollProgress(scrollPercentage);

    setShowButton(scrollPosition > 200); // Show button after scrolling 200px
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <footer className="footer  text-white mt-auto kc_footer_main_section">
      {isMobileView ? (
        <div>
          <div className="news_letter_main_section_div">
            <div className="newsletter_main_section bg-light text-dark">
              <div className="container d-flex justify-content-between align-items-center flex-wrap frisbee_mobile_for_pic">
                <div className="mobile_view_frisbee_footer_for_all_main">
                  <Link to="/" className="header_main_logo">
                    <div className="newsletter-text d-flex align-items-center news_letter_logo_for_mobile">
                      <img
                        src={logo}
                        alt="Logo"
                        className="mr-2"
                        width="50"
                        height="50"
                      />
                      <div className="mb-0 hk_up_to_date_for_news">
                        <h5>KC High</h5>
                        <p>International School</p>
                      </div>
                    </div>
                  </Link>
                  <div className="social-icons d-flex align-items-center frisbee_all_footer_icon">
                    <Link to="#" className="text-dark mr-2">
                      <FaFacebookF />
                    </Link>
                    <Link to="#" className="text-dark mr-2">
                      <FaXTwitter />
                    </Link>
                    <Link to="#" className="text-dark mr-2">
                      <FaInstagram />
                    </Link>
                    <Link to="#" className="text-dark">
                      <FaLinkedinIn />
                    </Link>
                    <Link to="#" className="text-dark">
                      <FaSquareYoutube />
                    </Link>
                  </div>
                </div>
                <div className="newsletter-form d-flex align-items-center frisbee_up_to_date_main_div">
                  <form className="form-inline">
                    <div className="inputNewsletter">
                      <input type="text" placeholder="Enter Email Address" />
                      <button className="btn footer-btn" type="button">
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row frisbee_mobile_view_row_footer">
              <div className="col-md-2 all_kc_footer">
                <h5 onClick={() => toggleDropdown("QuickLinks")}>
                  Quick Links{" "}
                  {openSection === "QuickLinks" ? (
                    <FaChevronUp className="ml-2" />
                  ) : (
                    <FaChevronDown className="ml-2" />
                  )}
                </h5>
                <Collapse isOpened={openSection === "QuickLinks"}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/#" className="text-white">
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link to="/policy" className="text-white">
                        Policy
                      </Link>
                    </li>
                    <li>
                      <Link to="/admission-info" className="text-white">
                        Admissions
                      </Link>
                    </li>
                    <li>
                      <Link to="/intro-and-benifit#intro" className="text-white">
                        Uni & Exam
                      </Link>
                    </li>
                    <li>
                      <Link to="/job-listing" className="text-white">
                        Careers
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
              <div className="col-md-2 all_kc_footer">
                <h5 onClick={() => toggleDropdown("Joinfrisbee")}>
                  About
                  {openSection === "Joinfrisbee" ? (
                    <FaChevronUp className="ml-2" />
                  ) : (
                    <FaChevronDown className="ml-2" />
                  )}
                </h5>
                <Collapse isOpened={openSection === "Joinfrisbee"}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/founder" className="text-white">
                        Founder’s Vision
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-white">
                        Our 25 Year Journey
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-white">
                        Leadership Team
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-white">
                        Awards and Recognitions
                      </Link>
                    </li>

                  </ul>
                </Collapse>
              </div>
              <div className="col-md-2 all_kc_footer">
                <h5 onClick={() => toggleDropdown("Offerings")}>
                  Academics
                  {openSection === "Offerings" ? (
                    <FaChevronUp className="ml-2" />
                  ) : (
                    <FaChevronDown className="ml-2" />
                  )}
                </h5>
                <Collapse isOpened={openSection === "Offerings"}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="/academics/early" className="text-white">
                      Pre-Primary
                      </Link>
                    </li>
                    <li>
                      <Link to="/academics/primary" className="text-white">
                        Primary
                      </Link>
                    </li>
                    <li>
                      <Link to="/academics/middle" className="text-white">
                        Middle
                      </Link>
                    </li>
                    <li>
                      <Link to="/academics/senior" className="text-white">
                     High
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
              <div className="col-md-2 all_kc_footer ">
                <h5 onClick={() => toggleDropdown("Helpsuppor")}>
                  Admissions
                  {openSection === "Helpsuppor" ? (
                    <FaChevronUp className="ml-2" />
                  ) : (
                    <FaChevronDown className="ml-2" />
                  )}
                </h5>
                <Collapse isOpened={openSection === "Helpsuppor"}>
                  <ul className="list-unstyled">
                    <li>
                      <Link to="#" className="text-white">
                        Process
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-white">
                        Fee
                      </Link>
                    </li>
                    <li>
                      <Link to="/faq" className="text-white">
                        FAQs
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="text-white">
                        School Tour & <br className="school_footer_div" /> Open
                        Houses
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </div>
              <div className="col-md-2 all_kc_footer">
                <h5 onClick={() => toggleDropdown("connect")}>
                  Connect Us
                  {openSection === "Helpsuppor" ? (
                    <FaChevronUp className="ml-2" />
                  ) : (
                    <FaChevronDown className="ml-2" />
                  )}
                </h5>
                <Collapse isOpened={openSection === "connect"}>
                  <ul className="list-unstyled kc_all_icon_for_location addrees_footer">
                    <li>
                      <FaLocationDot />
                      <span className="kc_footer_address">
                        {generalData?.generalData_address}
                      </span>
                    </li>
                    <li>
                      <MdMail />
                      <span className="kc_footer_address">
                        <Link
                          to="mailto:admin@kchigh.com"
                          className="text-white"
                        >
                          {generalData?.generalData_email}
                        </Link>
                      </span>
                    </li>
                    <li>
                      <IoCall />
                      <span className="kc_footer_address">
                        <Link to="tel:+919384827992" className="text-white">
                          {generalData?.generalData_phone}
                        </Link>
                      </span>
                    </li>
                  </ul>
                </Collapse>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="container-fluid all_fluid_main_class">
            <div className="news_letter_main_section_div">
              <div className="newsletter_main_section bg-light text-dark">
                <div className="container-fluid d-flex justify-content-between align-items-center flex-wrap">
                  <Link to="/" className="header_main_logo">
                    <div className="newsletter-text d-flex align-items-center footer_logo_img">
                      <img
                        src={logo}
                        alt="Logo"
                        className="mr-2"
                        width="50"
                        height="50"
                      />
                      <div className="mb-0 hk_up_to_date_for_news">
                        <h5>KC High</h5>
                        <p>International School</p>
                      </div>
                    </div>
                  </Link>
                  <div className="social-icons d-flex align-items-center frisbee_all_footer_icon">
                    <a
                      href={
                        generalData?.generalData_facebook
                          ? `https://${generalData?.generalData_facebook}`
                          : "#"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-dark mr-2"
                    >
                      <FaFacebookF />
                    </a>
                    <a
                      href={
                        generalData?.generalData_twitter
                          ? `https://${generalData?.generalData_twitter}`
                          : "#"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-dark mr-2"
                    >
                      <FaXTwitter />
                    </a>
                    <a
                      href={
                        generalData?.generalData_Instagram
                          ? `https://${generalData?.generalData_Instagram}`
                          : "#"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-dark mr-2"
                    >
                      <FaInstagram />
                    </a>
                    <a
                      href={
                        generalData?.generalData_linkedin
                          ? `https://${generalData?.generalData_linkedin}`
                          : "#"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-dark"
                    >
                      <FaLinkedinIn />
                    </a>
                    <a
                      href={
                        generalData?.generalData_youtube
                          ? `https://${generalData?.generalData_youtube}`
                          : "#"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-dark"
                    >
                      <FaSquareYoutube />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <ul className="footer_details">
              <li className=" all_kc_footer">
                <h5>Quick Links</h5>
                <ul className="list-unstyled all_footer_content">
                  <li>
                    <Link to="/#" className="text-white">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/policy" className="text-white">
                      Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/admission-info" className="text-white">
                      Admissions
                    </Link>
                  </li>
                  <li>
                    <Link to="/intro-and-benifit#intro" className="text-white">
                      Uni & Exam
                    </Link>
                  </li>
                  <li>
                    <Link to="/job-listing" className="text-white">
                      Careers
                    </Link>
                  </li>
                </ul>
              </li>
              <li className=" all_kc_footer">
                <h5>About</h5>
                <ul className="list-unstyled all_footer_content">
                  <li>
                    <Link to="/founder" className="text-white">
                      Founder’s Vision
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-white">
                      Our 25 Year Journey
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-white">
                      Leadership Team
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-white">
                      Awards and Recognitions
                    </Link>
                  </li>

                </ul>
              </li>
              <li className=" all_kc_footer">
                <h5>Academics</h5>
                <ul className="list-unstyled all_footer_content">
                  <li>
                    <Link to="/academics/early" className="text-white">
                    Pre-Primary
                    </Link>
                  </li>
                  <li>
                    <Link to="/academics/primary" className="text-white">
                      Primary
                    </Link>
                  </li>
                  <li>
                    <Link to="/academics/middle" className="text-white">
                      Middle
                    </Link>
                  </li>
                  <li>
                    <Link to="/academics/senior" className="text-white">
                High
                    </Link>
                  </li>
                </ul>
              </li>
              <li className=" all_kc_footer ">
                <h5>Admissions</h5>
                <ul className="list-unstyled all_footer_content">
                  <li>
                    <Link to="#" className="text-white">
                      Process
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-white">
                      Fee
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq" className="text-white">
                      FAQs
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-white">
                      School Tour & <br /> Open Houses
                    </Link>
                  </li>
                </ul>
              </li>
              <li className=" all_kc_footer">
                <h5>Connect Us</h5>
                {/* <ul className="list-unstyled kc_all_icon_for_location addrees_footer"> */}
                <ul className="list-unstyled kc_all_icon_for_location addrees_footer">
                  <li className="footer_location_text">
                    <FaLocationDot />
                    <span className="kc_footer_address">
                      {generalData?.generalData_address}
                    </span>
                  </li>
                  <li>
                    <MdMail />
                    <span className="kc_footer_address">{generalData?.generalData_email}</span>
                  </li>
                  <li>
                    <IoCall />
                    <span className="kc_footer_address">{generalData?.generalData_phone}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      )}
      <div className="text-center pt-3 kc_bottom_text">
        <p>(C) KC High International School | IGCSE & IBDP | {new Date().getFullYear()}-{new Date().getFullYear() + 1}</p>
      </div>
      {/* <div className="progress-circle ">
        <svg
          className="progress-ring"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          preserveAspectRatio="xMidYMid meet"
        >
          <circle
            className="progress-ring__circle"
            stroke="#9a1d2e"
            strokeWidth="2"
            fill="transparent"
            r="18"
            cx="20"
            cy="20"
            transform="rotate(-90 20 20)"
            vectorEffect="non-scaling-stroke"
            style={{
              strokeDasharray: `${2 * Math.PI * 18}`, // Circumference
              strokeDashoffset: `${2 * Math.PI * 18 * (1 - scrollProgress / 100)}`,
            }}
          />
        </svg>
      </div>
      {showButton && (
        <button className="back-to-top" onClick={scrollToTop}>
          <MdKeyboardArrowUp />
        </button>
      )} */}
      <div>
        {/* Progress Bar */}
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${scrollProgress}%` }}
          />
        </div>

        {/* Back-to-top Button */}
        {showButton && (
          <button className="back-to-top" onClick={scrollToTop}>
            <span className="top_icon">↑</span> {/* Custom arrow icon */}
          </button>
        )}
      </div>
    </footer>
  );
};

export default Footer;

// pppppppppp
