import React, { useState, useEffect } from "react";
import Header from "../../../Header/header";
import Footer from "../../../footer/footer";
import Common_heading from "../Common Heading/common_heading";
import Registration_side_bar from "../registration_side_bar/registration_side_bar";
import {
  siblingDetail,
  registrationDetails,
} from "../../../../controllers/submissionsController/submissionsController";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
function Detailsofsiblings() {
  const navigate = useNavigate();
  const [applicationId, setApplicationId] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    sibling_applied_to_kc: "No",
    sibling_name : "",
    sibling_grade : "",
    // sibling_state: "",
    is_applying_for_more : "",
  });
  const [errors, setErrors] = useState({});

  const handleRadioChange = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({ ...prev, sibling_applied_to_kc: value  }));

    if (value === "Yes") {
      setShowForm(true);
    } else {
      setShowForm(false);
      setFormData({
        id: applicationId,
        sibling_applied_to_kc: "No",
        sibling_name : "",
        sibling_grade : "",
        // sibling_state: "",
        is_applying_for_more : "No",
      });
      setErrors({});
    }
  };

  const handleRadioChange2 = (e) => {
    const { value } = e.target;
    setFormData((prev) => ({ ...prev, is_applying_for_more: value  }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    // validateForm(name, value);
  };

  const validateForm = () => {
    const newErrors = {};
    if (formData.sibling_applied_to_kc === "Yes") {
      if (!formData.sibling_name )
        newErrors.sibling_name  = "Sibling name is required.";
      
      if (!formData.sibling_grade )
        newErrors.sibling_grade  = "Sibling grade is required.";
      
      if (!formData.is_applying_for_more ) newErrors.is_applying_for_more  = "This Field is required.";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const rawBody = {
      ...formData,
      sibling_applied_to_kc:
        formData.sibling_applied_to_kc === "Yes" ? "Yes" : "No",
        // is_applying_for_more :
        // formData.is_applying_for_more  === "Yes" ? "Yes" : "No",
    };

    try {
      const response = await siblingDetail(rawBody);
      if (response?.status === true) {
        // alert('Details submitted successfully.');
        notification.open({
          message: "Submitted.",
          description: `Details submitted successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
        window.history.pushState({}, "", "/previously-attended-details");
        window.dispatchEvent(new Event("popstate"));
      } else {
        // alert('Failed to submit details.');
        notification.open({
          message: "Opps!",
          description: `${"Failed to submit details"}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      console.error("Submission error:", error);
      alert("An error occurred while submitting the details.");
    }
  };

  useEffect(() => {
    const fetchApplicationDetails = async () => {
      const encodedId = localStorage.getItem("applicationId");
      if (!encodedId) {
        console.error("Encoded applicationId not found in localStorage");
        navigate("/enter-student");
        return;
      }
      try {
        const decodedId = atob(encodedId);
        setApplicationId(decodedId);
        const response = await registrationDetails({ id: decodedId });
        if (response?.status === true) {
          const prevData = response.result;
          setFormData({
            id: decodedId || "null",
            sibling_applied_to_kc: prevData.sibling_applied_to_kc || "No",
            sibling_name : prevData.sibling_name  || "",
            sibling_grade : prevData.sibling_grade  || "",
            is_applying_for_more : prevData.is_applying_for_more  || "",
          });
          setShowForm(prevData.sibling_applied_to_kc === "Yes");
        }
      } catch (error) {
        console.error("Error fetching registration details:", error);
        navigate("/enter-student");
      }
    };

    fetchApplicationDetails();
  }, [navigate]);

  return (
    <div>
      <Header />
      <div className="inner_mainbanner">
        <Common_heading />
      </div>
      <div className="exam_gapping_div">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <Registration_side_bar />
            </div>
            <div className="col-md-9">
              <form>
                <div className="form_heading_container mb-3">
                  <h5 className="heading-text">Details of Siblings</h5>
                </div>
                <div className="d-flex justify-content-between align-items-center radio-container regestration_div">
                  <label className="regestration_applied_heading">
                  Do you have a sibling who is currently studying or has studied at KC?
                  </label>
                  <div className="d-flex gap-3">
                    <label className="regestration_kc_radio">
                      <input
                        type="radio"
                        name="kcApplication"
                        value="Yes"
                        checked={formData.sibling_applied_to_kc === "Yes"}
                        onChange={handleRadioChange}
                        id="radio_btn"
                      />
                      <span className="regestration_applied_yes">Yes</span>
                    </label>
                    <label className="regestration_kc_radio">
                      <input
                        type="radio"
                        name="kcApplication"
                        value="No"
                        checked={formData.sibling_applied_to_kc === "No"}
                        onChange={handleRadioChange}
                        id="radio_btn"
                      />
                      <span className="regestration_applied_yes">No</span>
                    </label>
                  </div>
                </div>
                <hr />
                {showForm && (
                  <>
                    <div className="row g-3">
                      <div className="col-md-4 regestration_form_input">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Sibling’s Name *"
                          name="sibling_name"
                          value={formData.sibling_name  || ""}
                          onChange={handleChange}
                        />
                        {errors.sibling_name  && (
                          <p className="error-text">
                            {errors.sibling_name }
                          </p>
                        )}
                      </div>
                      <div className="col-md-4 regestration_form_input">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Sibling’s Grade Level *"
                          name="sibling_grade"
                          value={formData.sibling_grade  || ""}
                          onChange={handleChange}
                        />
                        {errors.sibling_grade  && (
                          <p className="error-text">
                            {errors.sibling_grade }
                          </p>
                        )}
                      </div>
                      
                      {/* <div className="col-md-4 regestration_form_input">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Are you applying for more than one child? Yes/No"
                          name="is_applying_for_more "
                          value={formData.is_applying_for_more  || ""}
                          onChange={handleChange}
                        />
                        {errors.is_applying_for_more  && (
                          <p className="error-text">
                            {errors.is_applying_for_more }
                          </p>
                        )}
                      </div> */}
                    </div>
                    <div className="d-flex justify-content-between align-items-center radio-container regestration_div mt-4">
                  <label className="regestration_applied_heading">
                  Are you applying for more than one child?
                  </label>
                  <div className="d-flex gap-3">
                    <label className="regestration_kc_radio">
                      <input
                        type="radio"
                        name="is_applying_for_more"
                        value="Yes"
                        checked={formData.is_applying_for_more === "Yes"}
                        onChange={handleRadioChange2}
                        id="radio_btn"
                      />
                      <span className="regestration_applied_yes">Yes</span>
                    </label>
                    <label className="regestration_kc_radio">
                      <input
                        type="radio"
                        name="is_applying_for_more"
                        value="No"
                        checked={formData.is_applying_for_more === "No"}
                        onChange={handleRadioChange2}
                        id="radio_btn"
                      />
                      <span className="regestration_applied_yes">No</span>
                    </label>
                  </div>
                </div>
                  </>
                )}
                <div className="form_subbmit_btn mt-3">
                  <button type="button" onClick={handleSubmit}>
                    Proceed to Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Detailsofsiblings;
