import React, { useState, useEffect } from "react";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { FaSearch } from "react-icons/fa";
import logo from "../images/header-logo.png";
import "./header.css";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import "../../../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { FaRegUser } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa";
import { RiMenu3Fill, RiCloseLine } from "react-icons/ri";
import { IoChevronUp } from "react-icons/io5";
import { Link } from "react-router-dom";
import ScrollIndicator from '../scrool_indactor/scrool_indactor.js';
import { feedbackReview } from "../../controllers/FeedBackReviewController/feedbackReview.js";
import FeedbackPopup from '../FeedBack popup/feedbackPopup.jsx'
const Header = () => {
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const [showAboutDropdown, setShowAboutDropdown] = useState(false);
  const [showAcademicsDropdown, setShowAcademicsDropdown] = useState(false);
  const [showAdmissionsDropdown, setShowAdmissionsDropdown] = useState(false);
  const [showNewsDropdown, setShowNewsDropdown] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  // Toggle the dropdown visibility
  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);

    // Prevent scrolling when the menu is open
    if (!isMenuOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  };
  // Toggle the search bar expansion
  const toggleSearchExpansion = () => {
    setIsSearchExpanded(!isSearchExpanded);
  };
  ///for open clode Ul li
  const handleMenu = (menuName) => {
    setActiveMenu(activeMenu === menuName ? null : menuName);
  };

  ///////// for sticky

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 60) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

 
  const [showFeedback, setShowFeedback] = useState(false);

  useEffect(() => {
    const feedbackShown = sessionStorage.getItem("feedbackShown");

    if (!feedbackShown) {
      const timer = setTimeout(() => {
        setShowFeedback(true);
        sessionStorage.setItem("feedbackShown", "true");
      }, 60000); // 60 seconds

      return () => clearTimeout(timer);
    }
  }, []);
  return (
    <>
          {showFeedback && <FeedbackPopup  onClose={() => setShowFeedback(false)}/>}

      {/* Top bar with Search, Alumni, Calendar, etc. */}
      <div className="header_main_section text-white py-2">
        <Container
          fluid
          className="d-flex justify-content-end align-items-center all_fluid_main_class"
        >
          <div className="search-container d-flex align-items-center header_expend_search">
            <input
              type="text"
              className={`search-input form-control bg-transparent text-white ${isSearchExpanded ? "expanded" : ""
                }`}
              placeholder="Search..."
              style={{
                width: isSearchExpanded ? "200px" : "0",
                opacity: isSearchExpanded ? 1 : 0,
                transition: "width 0.3s ease, opacity 0.3s ease",
              }}
            />
            <FaSearch
              className="me-2 header_search_svg"
              style={{ cursor: "pointer" }}
              onClick={toggleSearchExpansion}
            />
          </div>

          {/* Desktop view links */}
          {/* <span className="mx-2 d-none d-md-inline">|</span> */}
          {/* <Link
            to="/alumni"
            className="text-white header_top_text mx-2 d-none d-md-inline"
          >
            Alumni
          </Link> */}
          <span className="mx-2 d-none d-md-inline">|</span>
          <Link
            to="/calendar"
            className="text-white header_top_text mx-2 d-none d-md-inline"
          >
            Calendar
          </Link>
          <span className="mx-2 d-none d-md-inline">|</span>
          <Link
            to="/contact-us"
            className="text-white header_top_text mx-2 d-none d-md-inline"
          >
            Contact Us
          </Link>
          <span className="mx-2 d-none d-md-inline">|</span>
          <Link
            to="/policy"
            className="text-white header_top_text mx-2 d-none d-md-inline"
          >
            Policy
          </Link>
          <span className="mx-2 d-none d-md-inline">|</span>
          <Link
            to="/#"
            className="text-white header_top_text mx-2 d-none d-md-inline"
          >
            Login
          </Link>

          {/* Mobile view with dropdown */}
          <div className="mobile-nav d-flex d-md-none align-items-center top_header_mobile_svg">
            <span className="mx-4">|</span>
            <FaRegUser className="header_use_svg me-2" />
            {/* <FaAngleDown onClick={toggleDropdown} /> */}
          </div>

          {/* Dropdown menu for mobile view */}
          {/* {isDropdownVisible && (
            <div className="dropdown-menu top_header_kc_alumni">
              <Link to="/alumni" className="dropdown-item">
                Alumni
              </Link>
              <Link to="/calendar" className="dropdown-item">
                Calendar
              </Link>
              <Link to="/contact-us" className="dropdown-item">
                Contact Us
              </Link>
              <Link to="/policy" className="dropdown-item">
                Policy
              </Link>
            </div>
          )} */}
        </Container>
      </div>

      {/*desktop view Main navigation with logo and menu */}
      {/* <div className='main_desktop_view_nav_bar'> */}
      <div className={`main_desktop_view_nav_bar ${isSticky ? "sticky" : ""}`}>
        <Navbar bg="light" expand="lg">
          <Container fluid className="all_fluid_main_class_second">
            <Navbar.Brand>
              <Link to="/" className="header_main_logo">
                <img src={logo} alt="KC High" style={{ height: "40px" }} />
                <div></div>
                <div className="header_logo_text">
                  <h5>
                    KC <span>HIGH</span>{" "}
                  </h5>
                  <span>International School</span>
                </div>
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ms-auto align-items-center header_dropdown_section">
                {/* About Us Dropdown */}
                <NavDropdown
                  title={
                    <span className="header_title_name kc_header_after" >
                      About Us <MdOutlineKeyboardArrowDown />
                    </span>
                  }
                  id="about-dropdown"
                  className="dropdown dropdown_about"
                  bsPrefix="header_custom_dropdown"
                  onMouseEnter={() => setShowAboutDropdown(true)}
                  onMouseLeave={() => setShowAboutDropdown(false)}
                  show={showAboutDropdown} // Control dropdown visibility
                >
                  <Link to="/founder" className="">
                    <span className="rounded-md px-3 py-2 kc_header_sub_categories sub_heading_text">
                      Founder’s Vision
                    </span>
                  </Link>
                  {/* <Link to="/leadership" className="">
                    <span className="rounded-md px-3 py-2 kc_header_sub_categories sub_heading_text">
                      Leadership Team
                    </span>
                  </Link> */}
                </NavDropdown>

                <span className="mx-2 header_line">|</span>

                {/* Academics Dropdown */}

                <NavDropdown
                  title={
                    <span className="header_title_name kc_header_after">
                      Academics <MdOutlineKeyboardArrowDown />
                    </span>
                  }
                  bsPrefix="header_custom_dropdown"
                  id="academics-dropdown"
                  className="dropdown dropdown_academic"
                  onMouseEnter={() => setShowAcademicsDropdown(true)}
                  onMouseLeave={() => setShowAcademicsDropdown(false)}
                  show={showAcademicsDropdown} // Control dropdown visibility
                >
                  <Link to="/academics/early" className="">
                    <span className="rounded-md px-3 py-2 kc_header_sub_categories sub_heading_text">
                    Pre-Primary
                    </span>
                  </Link>
                  <Link to="/academics/primary" className="">
                    <span className="rounded-md px-3 py-2 kc_header_sub_categories sub_heading_text">
                      Primary
                    </span>
                  </Link>
                  <Link to="/academics/middle" className="">
                    <span className="rounded-md px-3 py-2 kc_header_sub_categories sub_heading_text">
                      Middle
                    </span>
                  </Link>
                  <Link to="/academics/senior" className="">
                    <span className="rounded-md px-3 py-2 kc_header_sub_categories sub_heading_text">
                      High
                    </span>
                  </Link>
                </NavDropdown>

                <span className="mx-2 header_line">|</span>

                {/* Admissions Dropdown */}
                <NavDropdown
                  title={
                    <span className="header_title_name kc_header_after">
                      Admissions <MdOutlineKeyboardArrowDown />
                    </span>
                  }
                  bsPrefix="header_custom_dropdown"
                  id="admissions-dropdown"
                  className="dropdown dropdown_admission"
                  onMouseEnter={() => setShowAdmissionsDropdown(true)}
                  onMouseLeave={() => setShowAdmissionsDropdown(false)}
                  show={showAdmissionsDropdown} // Control dropdown visibility
                >
                  <Link to="/admission-info" className="">
                    <span className="rounded-md px-3 py-2 kc_header_sub_categories sub_heading_text">
                      Admission Process
                    </span>
                  </Link>
                  <Link to="/enter-student" className="">
                    <span className="rounded-md px-3 py-2 kc_header_sub_categories sub_heading_text">
                      Registration
                    </span>
                  </Link>
                </NavDropdown>

                <span className="mx-2 header_line">|</span>

                {/* News and Events Dropdown */}
                <NavDropdown
                  // title="News and Events"
                  title={
                    <span className="header_title_name kc_header_after">
                      Exam Services <MdOutlineKeyboardArrowDown />
                    </span>
                  }
                  bsPrefix="header_custom_dropdown"
                  id="news-dropdown"
                  className="dropdown dropdown_news_and_events"
                  onMouseEnter={() => setShowNewsDropdown(true)}
                  onMouseLeave={() => setShowNewsDropdown(false)}
                  show={showNewsDropdown} // Control dropdown visibility
                >
                  <Link to="/counselling-department" className="">
                    <span className="rounded-md px-3 py-2 kc_header_sub_categories sub_heading_text">
                      University Counselling
                    </span>
                  </Link>
                  <Link to="/intro-and-benifit" className="">
                    <span className="rounded-md px-3 py-2 kc_header_sub_categories sub_heading_text">
                      Uni & Exam
                    </span>
                  </Link>
                  {/* <Link to="/news" className="">
                    <span className="rounded-md px-3 py-2 kc_header_sub_categories sub_heading_text">
                      News
                    </span>
                  </Link>
                  <Link to="/event" className="">
                    <span className="rounded-md px-3 py-2 kc_header_sub_categories sub_heading_text">
                      Events
                    </span>
                  </Link> */}


                </NavDropdown>

                <span className="mx-2 header_line">|</span>

                {/* Careers Link */}
                {/* <Nav.Link href="/career-rr-campus">Careers</Nav.Link> */}
                <Link to="/job-listing" className="">
                  <span className="rounded-md px-3 py-2 kc_header_sub_categories careeer_header kc_header_after_career">
                    Careers
                  </span>
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      {/*desktop view end main nav */}
      {/* mobile view nav bar start here */}
      <div className="main_mobile_view_nav_bar">
        {/* <div className={`main_mobile_view_nav_bar ${isSticky ? 'sticky_mobile' : ''}`}> */}
        <Navbar bg="light" expand="lg">
          <Container fluid className="all_fluid_main_class_second header_tabs_view">
            <Link to="/" className="navbar-brand">
              <div className="header_main_logo">
                <img src={logo} alt="KC High" style={{ height: "40px" }} />
                <div className="header_logo_text">
                  <h5>
                    KC <span>HIGH</span>
                  </h5>
                  <span>International School</span>
                </div>
              </div>
            </Link>

            {/* Hamburger or Close Icon */}
            <div
              onClick={toggleMenu}
              style={{ cursor: "pointer" }}
              className="hamburger_menu_button"
            >
              {isMenuOpen ? (
                <RiCloseLine size={23} />
              ) : (
                <RiMenu3Fill size={23} />
              )}
            </div>

            <Navbar.Collapse
              id="basic-navbar-nav"
              className={`collapse-mobile-menu ${isMenuOpen ? "show" : ""}`}
            >
              <Nav className="ms-auto  header_dropdown_section">
                <ul className="kc_mobile_header_main_section">
                  <li>
                    <div
                      className="kc_mobile_header_main_div"
                      onClick={() => handleMenu("about")}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="rounded-md px-3 py-2 ">About Us</span>
                      <IoChevronUp
                        className={
                          activeMenu === "about"
                            ? "navbar_rotate_0"
                            : "navbar_rotate_180"
                        }
                        size={18}
                        color="#41333B"
                      />
                    </div>

                    {activeMenu === "about" && (
                      <div className="kc_sub_header_sub_contentt">
                        <Link to="/founder" className="">
                          <span className="rounded-md px-3 py-2 kc_header_sub_categories mobile_view_subheading">
                            Founder’s Vision
                          </span>
                        </Link>
                        {/* <Link to="/leadership" className="">
                          <span className="rounded-md px-3 py-2 kc_header_sub_categories">
                            Leadership Team
                          </span>
                        </Link> */}
                      </div>
                    )}
                  </li>
                  <li>
                    <div
                      className="kc_mobile_header_main_div"
                      onClick={() => handleMenu("academics")}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="rounded-md px-3 py-2 ">Academics</span>
                      <IoChevronUp
                        className={
                          activeMenu === "academics"
                            ? "navbar_rotate_0"
                            : "navbar_rotate_180"
                        }
                        size={18}
                        color="#41333B"
                      />
                    </div>

                    {activeMenu === "academics" && (
                      <div className="kc_sub_header_sub_contentt">
                        <Link to="/academics/early" className="">
                          <span className="rounded-md px-3 py-2 kc_header_sub_categories mobile_view_subheading">
                          Pre-Primary
                          </span>
                        </Link>
                        <Link to="/academics/primary" className="">
                          <span className="rounded-md px-3 py-2 kc_header_sub_categories mobile_view_subheading">
                            Primary
                          </span>
                        </Link>
                        <Link to="/academics/middle" className="">
                          <span className="rounded-md px-3 py-2 kc_header_sub_categories mobile_view_subheading">
                            Middle
                          </span>
                        </Link>
                        <Link to="/academics/senior" className="">
                          <span className="rounded-md px-3 py-2 kc_header_sub_categories mobile_view_subheading">
                            High
                          </span>
                        </Link>
                      </div>
                    )}
                  </li>
                  <li>
                    <div
                      className="kc_mobile_header_main_div"
                      onClick={() => handleMenu("admissions")}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="rounded-md px-3 py-2 ">Admissions</span>
                      <IoChevronUp
                        className={
                          activeMenu === "admissions"
                            ? "navbar_rotate_0"
                            : "navbar_rotate_180"
                        }
                        size={18}
                        color="#41333B"
                      />
                    </div>

                    {activeMenu === "admissions" && (
                      <div className="kc_sub_header_sub_contentt">
                        <Link to="/admission-info" className="">
                          <span className="rounded-md px-3 py-2 kc_header_sub_categories mobile_view_subheading">
                            Admission Process
                          </span>
                        </Link>
                        <Link to="/enter-student" className="">
                          <span className="rounded-md px-3 py-2 kc_header_sub_categories mobile_view_subheading">
                            Registration
                          </span>
                        </Link>
                      </div>
                    )}
                  </li>
                  <li>
                    <div
                      className="kc_mobile_header_main_div"
                      onClick={() => handleMenu("newsandevents")}
                      style={{ cursor: "pointer" }}
                    >
                      <span className="rounded-md px-3 py-2 ">
                        Exam Services
                      </span>
                      <IoChevronUp
                        className={
                          activeMenu === "newsandevents"
                            ? "navbar_rotate_0"
                            : "navbar_rotate_180"
                        }
                        size={18}
                        color="#41333B"
                      />
                    </div>

                    {activeMenu === "newsandevents" && (
                      <div className="kc_sub_header_sub_contentt">

                        <Link to="/counselling-department" className="">
                          <span className="rounded-md px-3 py-2 kc_header_sub_categories mobile_view_subheading">
                            University Counselling
                          </span>
                        </Link>
                        <Link to="/intro-and-benifit" className="">
                          <span className="rounded-md px-3 py-2 kc_header_sub_categories mobile_view_subheading">
                            Uni & Exam
                          </span>
                        </Link>
                        {/* <Link to="/news" className="">
                          <span className="rounded-md px-3 py-2 kc_header_sub_categories">
                            News
                          </span>
                        </Link>
                        <Link to="/event" className="">
                          <span className="rounded-md px-3 py-2 kc_header_sub_categories">
                            Events
                          </span>
                        </Link> */}
                      </div>
                    )}
                  </li>
                  <li>
                    <div
                      className="kc_mobile_header_main_div"
                      style={{ cursor: "pointer" }}
                    >
                      <Link to="/job-listing" className="">
                        <span className="rounded-md px-3 py-2 ">Careers</span>
                      </Link>
                    </div>
                  </li>
                </ul>

                <ul className="kc_mobile_header_main_section mobile_header_top">
                  {/* <li>
                    <div
                      className="kc_mobile_header_main_div"
                      style={{ cursor: "pointer" }}
                    >
                      <Link to="/alumni" className="">
                        <span className="rounded-md px-3 py-2 ">Alumni</span>
                      </Link>
                    </div>
                  </li> */}
                  <li>
                    <div
                      className="kc_mobile_header_main_div"
                      style={{ cursor: "pointer" }}
                    >
                      <Link to="/calendar" className="">
                        <span className="rounded-md px-3 py-2 ">Calendar</span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div
                      className="kc_mobile_header_main_div"
                      style={{ cursor: "pointer" }}
                    >
                      <Link to="/contact-us" className="">
                        <span className="rounded-md px-3 py-2 ">Contact Us</span>
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div
                      className="kc_mobile_header_main_div"
                      style={{ cursor: "pointer" }}
                    >
                      <Link to="/policy" className="">
                        <span className="rounded-md px-3 py-2 ">Policy</span>
                      </Link>
                    </div>
                  </li>
                </ul>
              </Nav>
            </Navbar.Collapse>


          </Container>
        </Navbar>
      </div>
      {/*mobile view end main nav */}
      <ScrollIndicator></ScrollIndicator>
    </>
  );
};

export default Header;
