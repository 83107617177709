import React from 'react'

function Common_heading() {
    return (
        <div>
            <div className="text-center redgestration_form">
                <h2>
                    Online <span >Registration </span>
                </h2>
                <h6>A SEAMLESS START TO YOUR <span>SCHOOL JOURNEY</span> </h6>
                <p>
                    Begin the enrollment process online, and secure your child’s spot for a bright educational future.
                </p>
            </div>
        </div>
    )
}

export default Common_heading