import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useEffect, useState } from 'react';
import { FRONT_ASSETS_URL } from '../../config/constants';
import { partnerList } from '../../controllers/commonController/cmsController';

function Accrediation_slider() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 6,
        autoplay: true,
        slidesToScroll: 1,
        arrows: false,
        cssEase: 'linear',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    const [partners, setPartnersList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


/*********************************************************
  *  This function is use to fetch partners list
 *********************************************************/
  const partnersList = async () => {
    setPartnersList([]);
    setIsLoading(true);
    try {
      const listData = await partnerList();
      if (listData?.status === true) {
        setPartnersList(listData?.result);
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      setIsLoading(false);
    }
  }
  useEffect(() => {
    partnersList();
  }, [])

    const replicateData = (data) => {
        return data.length === 1 ? Array(5).fill(data[0]) : data;
    };

    // Preprocess the data using replicateData
    const partnersrep = replicateData(partners);

    return (
        <div className="row justify-content-around align-items-center accreditations_image">
            <Slider {...settings}>
                {partnersrep.map((item, index) => (
                    <div className="col-6 col-md-2 my-3" key={index}>
                        <img src={`${FRONT_ASSETS_URL}${item.image}`} alt={item.name}  className="img-fluid"/>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

export default Accrediation_slider;
