export const API_KEY = "d42a0d190464a2be90977c3996382811";
// export const API_BASE_URL = "http://localhost:3500/front/v1/";
// export const FRONT_ASSETS_URL = "http://localhost:3500/";
// export const FRONT_URL = "http://localhost:3500/";

export const FRONT_ASSETS_URL= 'https://kc-high.s3.ap-south-1.amazonaws.com/'

// export const API_BASE_URL = "https://algodev.in:63010/front/v1/";
// export const FRONT_ASSETS_URL = "https://algodev.in:63010/";
// export const FRONT_URL = "https://algodev.in:63030/";



export const API_BASE_URL = "https://api.kchigh.com/front/v1/";
// export const FRONT_ASSETS_URL = "https://api.kchigh.com/";
export const FRONT_URL = "https://www.kchigh.com/";


// export const API_BASE_URL = "https://api.kchigh.online/front/v1/";
// export const FRONT_ASSETS_URL = "https://api.kchigh.online/";
// export const FRONT_URL = "https://www.kchigh.online/";


// pdf FRONT_ASSETS_URL
export const PDF_FRONT_ASSETS_URL='https://api.kchigh.com/'