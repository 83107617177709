import React, { useState } from 'react';
import './FeedbackPopup.css'; // We'll create this CSS file next
import { IoCloseSharp } from "react-icons/io5";
import { feedbackReview } from '../../controllers/FeedBackReviewController/feedbackReview';
import { notification } from 'antd';
import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

const FeedbackPopup = ({ onClose }) => {
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [error,setError]=useState('')
  const handleStarClick = (starIndex) => {
    setRating(starIndex + 1);
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    if (!rating || rating < 1 || rating > 5) {
      setError("Please provide a valid rating between 1 and 5.");
      return;
    }
  
    if (!feedback.trim()) {
      setError("Review cannot be empty.");
      return;
    }
    const formData={
      star:rating,
      review:feedback
    }
    try{
      const result = await feedbackReview(formData);
      if (result?.status === true) {
        notification.open({
          message: "Submitted.",
          description: `Thanks For Your Feedback!`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
        sessionStorage.setItem("feedbackShown", "true"); // Store in session
        onClose(); 
      } else {
        notification.open({
          message: "Opps!",
          description: `Error: ${result.message}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    }catch(error){
      console.log(error)
    }
    }

  return (
    <div className="popup-overlay">
      <div className="popup-content">
                     <div className="offcanvas-header canvas_btn btn-control-feedback">
                          
                               <button
                                 type="button"
                                 className="close-icon"
                                 aria-label="Close"
                                 onClick={()=>{onClose()}}
                               >
                                 <IoCloseSharp size={20} />
                               </button>
                             </div>
      <h2 className="popup-title">
  {/* <span className="stars">★★★</span> */}
   Rate your Website Experience!
</h2>
        <p>Help us improve the KC Website by sharing your suggestions and feedback!</p>

        <div className="star-rating">
  {[...Array(5)].map((_, index) => (
    <span
      key={index}
      className={`star ${index < rating ? 'filled' : ''}`}
      onClick={() => handleStarClick(index)}
    >
      <svg
        viewBox="0 0 24 24"
        fill={index < rating ? "#ffcc00" : "#ccc"}
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* Round Star SVG Path */}
        <path
          d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
          stroke={index < rating ? "#ffcc00" : "#ccc"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  ))}
</div>
        
        {/* <div className="star-rating">
          {[...Array(5)].map((_, index) => (
            <span
              key={index}
              className={`star ${index < rating ? 'filled' : ''}`}
              onClick={() => handleStarClick(index)}
            >
              ★
            </span>
          ))}
        </div> */}

        <textarea
          placeholder="Share your suggestions here..."
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
        {error&&<p style={{color:"red",fontSize:"12px" ,marginBottom:"15px",lineHeight:"10px"}}>{error}</p>}

        <button onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

export default FeedbackPopup;