import React, { useState, useEffect } from "react";
import Header from "../../../Header/header";
import Footer from "../../../footer/footer";
import Common_heading from "../Common Heading/common_heading";
import Registration_side_bar from "../registration_side_bar/registration_side_bar";
import { useNavigate, Link } from "react-router-dom";
import {
  homeDetail,
  registrationDetails,
} from "../../../../controllers/submissionsController/submissionsController";
import { notification } from "antd";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
function Homedetails() {
  const navigate = useNavigate();
  const [applicationId, setApplicationId] = useState("");
  const [formData, setFormData] = useState({
    home_address: "",
    phone: "",
    pin_code: "",
    state: "",
    city: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
    // validateForm(name, value);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.home_address) {
      newErrors.home_address = "This field is required.";
    }
    if (!formData.phone) {
      newErrors.phone = "This field is required.";
    } 

    if (!formData.pin_code || !formData.pin_code.trim()) {
      newErrors.pin_code = "Pin code is required.";
    } else {
      const pinCodeRegex = /^\d{6}$/;
      if (!pinCodeRegex.test(formData.pin_code)) {
        newErrors.pin_code = "Pin code must be exactly 6 digits.";
      }
    }
    if (!formData.state) {
      newErrors.state = "This field is required.";
    }
    if (!formData.city) {
      newErrors.city = "This field is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      const response = await homeDetail({
        id: applicationId,
        home_address: formData.home_address,
        phone: formData.phone,
        pin_code: formData.pin_code,
        state: formData.state,
        city: formData.city,
      });

      if (response?.status === true) {
        notification.open({
          message: "Submitted.",
          description: `Details submitted successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        }); // alert('Home details submitted successfully.');
        // navigate("/other-details", { state: { userId: response.result._id } });
        window.history.pushState({}, "", "/details-of-father");
        window.dispatchEvent(new Event("popstate"));
      } else {
        notification.open({
          message: "Opps!",
          description: `${"Failed to submit details"}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        }); // alert('Failed to submit Home details.');
      }
    } catch (error) {
      console.error("Submission error:", error);
      alert("An error occurred while submitting the details.");
    }
  };

  useEffect(() => {
    const fetchApplicationDetails = async () => {
      const encodedId = localStorage.getItem("applicationId");

      if (!encodedId) {
        console.error("Encoded applicationId not found in localStorage");
        navigate("/enter-student");
        return;
      }

      try {
        const decodedId = atob(encodedId); // Decode the Base64 encoded ID
        // console.log("Decoded applicationId:", decodedId);

        if (!decodedId) {
          throw new Error("Decoded ID is invalid or empty");
        }

        // Set the applicationId state
        setApplicationId(decodedId);

        // Fetch registration details based on the decoded ID
        const response = await registrationDetails({ id: decodedId });

        if (response?.status === true) {
          const prevData = response.result;

          // Update formData with fetched registration details
          setFormData({
            id: decodedId, // Use the decodedId directly
            home_address: prevData.home_address,
            phone: prevData.phone,
            pin_code: prevData.pin_code,
            state: prevData.state,
            city: prevData.city,
          });
        } else {
          console.error(
            "Failed to fetch registration details:",
            response?.message || "Unknown error"
          );
        }
      } catch (error) {
        console.error(
          "Error decoding applicationId or fetching registration details:",
          error
        );
        navigate("/enter-student");
      }
    };

    fetchApplicationDetails();
  }, [navigate]); // Dependency array includes navigate

  /*********************************************************
   * Update formData.id whenever applicationId changes
   *********************************************************/
  useEffect(() => {
    if (applicationId) {
      setFormData((prev) => ({ ...prev, id: applicationId }));
    }
  }, [applicationId]); //end

  return (
    <div>
      <Header />
      <div className="inner_mainbanner">
        <Common_heading />
      </div>
      <div className="exam_gapping_div">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <Registration_side_bar />
            </div>
            <div className="col-md-9">
              <form>
                <div className="form_heading_container  mb-3">
                  <h5 className="heading-text">Home Details</h5>
                </div>
                <div>
                  <div className="row g-3">
                    <div className="col-md-12 regestration_form_input">
                      <input
                        type="text"
                        className="form-control"
                        name="home_address"
                        value={formData.home_address}
                        onChange={handleChange}
                        placeholder="Home Address *"
                      />
                      {errors.home_address && (
                        <div className="text-danger">{errors.home_address}</div>
                      )}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="number"
                        className="form-control"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Phone (Residence) *"
                        style={{ appearance: "textfield" }}
                      />
                      {errors.phone && (
                        <div className="text-danger">{errors.phone}</div>
                      )}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="number"
                        className="form-control"
                        name="pin_code"
                        value={formData.pin_code}
                        onChange={handleChange}
                        placeholder="Pin code *"
                      />
                      {errors.pin_code && (
                        <div className="text-danger">{errors.pin_code}</div>
                      )}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <select
                        className="form-select"
                        name="state"
                        value={formData.state}
                        onChange={handleChange}
                      >
                        <option value="">Select State/UT</option>
                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                        <option value="Assam">Assam</option>
                        <option value="Bihar">Bihar</option>
                        <option value="Chhattisgarh">Chhattisgarh</option>
                        <option value="Goa">Goa</option>
                        <option value="Gujarat">Gujarat</option>
                        <option value="Haryana">Haryana</option>
                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                        <option value="Jharkhand">Jharkhand</option>
                        <option value="Karnataka">Karnataka</option>
                        <option value="Kerala">Kerala</option>
                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                        <option value="Maharashtra">Maharashtra</option>
                        <option value="Manipur">Manipur</option>
                        <option value="Meghalaya">Meghalaya</option>
                        <option value="Mizoram">Mizoram</option>
                        <option value="Nagaland">Nagaland</option>
                        <option value="Odisha">Odisha</option>
                        <option value="Punjab">Punjab</option>
                        <option value="Rajasthan">Rajasthan</option>
                        <option value="Sikkim">Sikkim</option>
                        <option value="Tamil Nadu">Tamil Nadu</option>
                        <option value="Telangana">Telangana</option>
                        <option value="Tripura">Tripura</option>
                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                        <option value="Uttarakhand">Uttarakhand</option>
                        <option value="West Bengal">West Bengal</option>
                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                        <option value="Lakshadweep">Lakshadweep</option>
                        <option value="Delhi">Delhi</option>
                        <option value="Puducherry">Puducherry</option>
                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                        <option value="Ladakh">Ladakh</option>
                      </select>
                      {errors.state && (
                        <div className="text-danger">{errors.state}</div>
                      )}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        value={formData.city}
                        onChange={handleChange}
                        placeholder="City"
                      />
                      {errors.city && (
                        <div className="text-danger">{errors.city}</div>
                      )}
                    </div>
                  </div>
                  {/* <p className="regestration_note">
                    Note for Phone: Country Code + Area Code (for landlines
                    only) Example: +91 or +91 (44)
                  </p> */}
                </div>
                <div className="form_subbmit_btn">
                  <button type="button" onClick={handleSubmit}>
                    Proceed to Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Homedetails;
