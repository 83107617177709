import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FRONT_ASSETS_URL } from "../../../config/constants";

function Learning_domain_slider({ domainData }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    if (domainData?.length) {
      setSliderData(domainData);
    }
  }, [domainData]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    cssEase: "linear",
    centerMode: true,
    focusOnSelect: true,
    afterChange: (index) => setActiveIndex(index),
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container-fluid my-5 all_fluid_main_class_next">
      <div className="row align-items-center">
        <div className="col-md-4 text-start">
          <div className="domain_slider">
            <h2>
              Learning <br />
              <span>Domains</span>
            </h2>
            <p>
              Cambridge Learning Domains for Primary focus on essential skills
              like communication, critical thinking, and creativity. Through
              core subjects, students explore concepts, build knowledge, and
              apply skills, setting a strong foundation for lifelong learning.
            </p>
          </div>
        </div>
        <div className="col-md-8">
          <Slider {...settings} className="domian_slider_dot">
            {sliderData.map((domain, index) => (
              <div
                key={domain._id}
                className={`col-lg-3 col-md-6 mb-4 home_diffrent_main_section`}
              >
                <div
                  className={`h-100 position-relative domain_slider_div ${
                    activeIndex === index ? "domain_slide" : ""
                  }`}
                >
                  <div className="position-relative domain_img_div">
                    <img
                      src={`${FRONT_ASSETS_URL}${domain.image}`}
                      className="card-img-top img-fluid domain_img_img"
                      alt={domain.title}
                    />
                  </div>
                  <div className="domain_slider_content">
                    <div className="card-body text-start domain_slider_text">
                      <h5 className="card-title">{domain.title}</h5>
                      <p className="card-text">{domain.content}</p>
                    </div>
                  </div>
                  {domain.pdffile &&
                              domain.pdffile !== "undefined" &&
                              domain.pdffile.trim() !== "" && (
                  <button  onClick={() => window.open(`${FRONT_ASSETS_URL}${domain.pdffile}`, '_blank')}>Know More</button>
                              )}
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default Learning_domain_slider;
