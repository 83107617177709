import { message } from "antd";
import { API_BASE_URL } from "../../config/constants";
import { getRequest, postRequest } from "../API";

/*********************************************************
* Function Name : List
* Description   : post contactus form 
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const contactUs = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}submission/contact-us`,
            postData: options
        }
        const res = await postRequest(params);     
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : registrationDetails
* Description   : registrationDetails
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const registrationDetails = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}submission/registration-details`,
            postData: options
        }
        const res = await postRequest(params);     
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : sendOtp
* Description   : sendOtp
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const sendOtp = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}submission/send-otp`,
            postData: options
        }
        const res = await postRequest(params);     
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : verifyOtp
* Description   : verifyOtp
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const verifyOtp = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}submission/verify-admission-email`,
            postData: options
        }
        const res = await postRequest(params);     
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : passportDetail
* Description   : passportDetail
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const passportDetail = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}submission/submit-passport-details`,
            postData: options
        }
        const res = await postRequest(params);     
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : prevSchoolDetail
* Description   : prevSchoolDetail
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const prevSchoolDetail = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}submission/submit-prevschool-details`,
            postData: options
        }
        const res = await postRequest(params);     
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : homeDetail
* Description   : homeDetail
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const homeDetail = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}submission/submit-home-details`,
            postData: options
        }
        const res = await postRequest(params);     
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : otherDetail
* Description   : otherDetail
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const otherDetail = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}submission/submit-other-details`,
            postData: options
        }
        const res = await postRequest(params);     
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : otherDetail
* Description   : otherDetail
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const siblingDetail = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}submission/submit-siblings-details`,
            postData: options
        }
        const res = await postRequest(params);     
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : otherDetail
* Description   : otherDetail
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const fatherDetail = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}submission/submit-father-details`,
            postData: options
        }
        const res = await postRequest(params);     
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : motherDetail
* Description   : motherDetail
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const motherDetail = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}submission/submit-mother-details`,
            postData: options
        }
        const res = await postRequest(params);     
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : programForAdm
* Description   : programForAdm
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const programForAdm = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}submission/submit-contact-program-details`,
            postData: options
        }
        const res = await postRequest(params);     
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : submitQuestionnaires
* Description   : submitQuestionnaires
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const submitQuestionnaires = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}submission/submit-questionnaires`,
            postData: options
        }
        const res = await postRequest(params);   
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return{status:false, message: res?.response?.data?.response?.error}
            // return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : submitQuestionnaires
* Description   : submitQuestionnaires
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const RegProgList = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/admission-programs`,
            postData: options
        }
        const res = await getRequest(params);     
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end
