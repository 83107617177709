import React, { useState, useEffect } from 'react';
import Header from '../../../Header/header';
import Footer from '../../../footer/footer';
import Common_heading from '../Common Heading/common_heading';
import Registration_side_bar from '../registration_side_bar/registration_side_bar';
import { useNavigate, Link } from "react-router-dom";
import { prevSchoolDetail, registrationDetails } from '../../../../controllers/submissionsController/submissionsController';
import { notification } from "antd";
import {CheckCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";
function Previouslyattendeddetails() {
    const navigate = useNavigate();
   
    const [applicationId, setApplicationId] = useState("");

    const [formData, setFormData] = useState({
        previous_school_name: '',
        current_grade: '',
        reason_of_leaving: ''
    });

    const [errors, setErrors] = useState({});

    const validateDate = (date) => {
        const dateRegex = /^\d{4}\/\d{2}\/\d{2}$/;
        return dateRegex.test(date);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
        // validateForm(name, value);
    };

    const validateForm = () => {
        const newErrors = {};
        const isNA = (value) => value.trim().toUpperCase() === "N/A";
    
        // Validation for "previous_school_name"
        if (!formData.previous_school_name || (!isNA(formData.previous_school_name) && !formData.previous_school_name.trim())) {
            newErrors.previous_school_name = 'This field is required.';
        } else if (!isNA(formData.previous_school_name)) {
            const trimmedName = formData.previous_school_name.trim();
            if (/\d/.test(trimmedName)) {
                newErrors.previous_school_name = "Cannot contain numbers.";
            } else if (/[^a-zA-Z\s'-]/.test(trimmedName)) {
                newErrors.previous_school_name = "Can only contain alphabets, apostrophes, and hyphens.";
            } else if (trimmedName.length < 2) {
                newErrors.previous_school_name = "Must be at least 2 characters long.";
            } else if (trimmedName.length > 50) {
                newErrors.previous_school_name = "Cannot exceed 50 characters.";
            }
        }
    
        // Validation for "current_grade"
        if (!formData.current_grade || (!isNA(formData.current_grade) && !formData.current_grade.trim())) {
            newErrors.current_grade = 'This field is required.';
        } else if (!isNA(formData.current_grade)) {
            if (!/^\d+$/.test(formData.current_grade)) {
                newErrors.current_grade = "Grade must be a number.";
            } else {
                const grade = parseInt(formData.current_grade, 10);
                if (grade < 1 || grade > 12) {
                    newErrors.current_grade = "Grade must be between 1 and 12.";
                }
            }
        }
    
        // Validation for "reason_of_leaving"
        if (!formData.reason_of_leaving || (!isNA(formData.reason_of_leaving) && !formData.reason_of_leaving.trim())) {
            newErrors.reason_of_leaving = 'This field is required.';
        } else if (!isNA(formData.reason_of_leaving)) {
            if (formData.reason_of_leaving.trim().length > 100) {
                newErrors.reason_of_leaving = "Cannot exceed 100 characters.";
            }
        }
    
        // Set errors and return validation result
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    
    
    
    const handleSubmit = async () => {
        if (!validateForm()) return;

        try {
            const response = await prevSchoolDetail({
                id: applicationId,
                previous_school_name: formData.previous_school_name,
                current_grade: formData.current_grade,
                reason_of_leaving: formData.reason_of_leaving
            });

            if (response?.status === true) {
                notification.open({
                    message: "Submitted.",
                    description: `Details submitted successfully.`,
                    placement: "topRight",
                    icon: <CheckCircleOutlined style={{ color: "green" }} />,
                    duration: 2,
                  });  // alert('Previous school details submitted successfully.');
                // navigate("/home-details", { state: { userId: response.result._id } });
                window.history.pushState({}, "", "/parent-questionnaires");
                window.dispatchEvent(new Event("popstate")); 
            } else {
                notification.open({
                    message: "Opps!",
                    description: `${"Failed to submit details"}`,
                    placement: "topRight",
                    icon: <InfoCircleOutlined style={{ color: "red" }} />,
                    duration: 2,
                  });// alert('Failed to submit previous school details.');
            }
        } catch (error) {
            console.error("Submission error:", error);
            alert('An error occurred while submitting the details.');
        }
    };
    /*********************************************************
 * Fetch and decode applicationId from localStorage.
 *********************************************************/
useEffect(() => {
    const fetchApplicationDetails = async () => {
      const encodedId = localStorage.getItem("applicationId");
  
      if (!encodedId) {
        console.error("Encoded applicationId not found in localStorage");
        navigate("/enter-student");
        return;
      }
  
      try {
        const decodedId = atob(encodedId); // Decode the Base64 encoded ID
        // console.log("Decoded applicationId:", decodedId);
  
        if (!decodedId) {
          throw new Error("Decoded ID is invalid or empty");
        }
  
        // Set the applicationId state
        setApplicationId(decodedId);
  
        // Fetch registration details based on the decoded ID
        const response = await registrationDetails({ id: decodedId });
  
        if (response?.status === true) {
          const prevData = response.result;
  
          // Update formData with fetched registration details
          setFormData({
            id: decodedId, // Use the decodedId directly
            previous_school_name: prevData.previous_school_name || null,
            current_grade: prevData.current_grade || null,
            reason_of_leaving: prevData.reason_of_leaving || null,
          });
        } else {
          console.error("Failed to fetch registration details:", response?.message || "Unknown error");
        }
      } catch (error) {
        console.error("Error decoding applicationId or fetching registration details:", error);
        navigate("/enter-student");
      }
    };
  
    fetchApplicationDetails();
  }, [navigate]); // Dependency array includes navigate
  
  
  /*********************************************************
   * Update formData.id whenever applicationId changes
   *********************************************************/
  useEffect(() => {
    if (applicationId) {
      setFormData((prev) => ({ ...prev, id: applicationId }));
    }
  }, [applicationId]);//end

    return (
        <div>
            <Header />
            <div className="inner_mainbanner">
                <Common_heading />
            </div>
            <div className='exam_gapping_div'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <Registration_side_bar />
                        </div>
                        <div className="col-md-9">
                            <form>
                                <div className="form_heading_container  mb-3">
                                    <h5 className="heading-text">Previously attended school details</h5>
                                </div>
                                <div className="enter_na_form mt-4 mb-3">
                                    <h5 className="heading-text">Enter N/A if none</h5>
                                </div>
                                <div>
                                    <div className="row g-3">
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="previous_school_name"
                                                value={formData.previous_school_name}
                                                onChange={handleChange}
                                                placeholder="Name of the School *"
                                            />
                                            {errors.previous_school_name && (
                                                <div className="text-danger">{errors.previous_school_name}</div>
                                            )}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="current_grade"
                                                value={formData.current_grade}
                                                onChange={handleChange}
                                                placeholder="Current grade the child is studying *"
                                            />
                                            {errors.current_grade && (
                                                <div className="text-danger">{errors.current_grade}</div>
                                            )}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="reason_of_leaving"
                                                value={formData.reason_of_leaving}
                                                onChange={handleChange}
                                                placeholder="Reason for leaving*"
                                            />
                                            {errors.reason_of_leaving && (
                                                <div className="text-danger">{errors.reason_of_leaving}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='form_subbmit_btn'>
                                {/* <Link to="/home-details"> */}
                      <button type="button" onClick={handleSubmit}>
                        Proceed to Continue
                      </button>
                    {/* </Link> */}
                                    {/* <button type='button' onClick={handleSubmit}>Proceed to Continue</button> */}
                                </div>
                      
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Previouslyattendeddetails;
