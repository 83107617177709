import {configureStore} from "@reduxjs/toolkit"
import footerReducer  from "../features/tasks/footerSlice";
import ourCampusReducer  from "../features/tasks/ourCampusSlice";

// create a redux stoe using the reducer redux toolkit
export const store = configureStore({
    reducer: {
        footerReducer,
        ourCampusReducer,
    },
});

