import { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import process_slide from "../../../images/process_slide.png";
import {
  admissionList,
  list,
} from "../../../../controllers/commonController/cmsController";
import { FRONT_ASSETS_URL } from "../../../../config/constants";
import { useNavigate } from "react-router-dom";

const Process_Step = () => {
  const navigate= useNavigate()
  const [activeTab, setActiveTab] = useState("01");
  const [isLoading, setIsLoading] = useState(true);
  const [admission, setAdmission] = useState([]);
  /*********************************************************
   *  This function is use to fetch cms content list
   *********************************************************/
  const cmsAdmissionProcess = async () => {
    setAdmission([]);
    setIsLoading(true);
    try {
      const option = {
        type: "",
        condition: {
          status: "A",
        },
      };
      const listData = await admissionList(option);
      if (listData?.status === true) {
        setAdmission(listData?.result);
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      setIsLoading(false);
    }
  }; //end

  useEffect(() => {
    cmsAdmissionProcess();
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      const container = document.querySelector(".get_in_touch_content");
      const contentSections = document.querySelectorAll(".content-block");

      contentSections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Check if section is partially visible in the container
        if (
          rect.top < containerRect.bottom &&
          rect.bottom > containerRect.top
        ) {
          setActiveTab(section.id); // Update active tab instantly
        }
      });
    };

    const container = document.querySelector(".get_in_touch_content");
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const handleStepClick = (stepId) => {
    setActiveTab(stepId);

    const container = document.querySelector(".get_in_touch_content");
    const element = document.getElementById(stepId);

    if (container && element) {
      const elementOffset = element.offsetTop; // Position of the element relative to the container
      container.scrollTo({
        top: elementOffset,
        behavior: "smooth",
      });
    }
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  //////////// like FAQ static for mobile view
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle open/close on click
  };
  const handleNaviagte=(item)=>{
    if(item.btntext==='Get in touch'){
      window.scrollTo(0, document.documentElement.scrollHeight / 1.4);
  }else if(item.btntext==='Start your process'){
    navigate('/enter-student')
  }
  }
console.log(admission,"admission---")
  return (
    <div className=" process_step_gapping">
      <div className="container-fluid">
        <div className="row">
          <div className=" col-md-12 mb-4">
            <div className="text-center calendar_school_text">
              <h2>
                The Admissions <span> Process</span>
              </h2>
              <p className="founder_paragraph">
                Our goal is to help you make the best decision possible for your
                child.
              </p>
            </div>
          </div>
          {/* Steps Section */}
          <div className="col-md-3">
            <ul className="nav flex-column admission_process_tabs text-start admission_limit_line">
              <h6>Process Steps</h6>
              {admission.map((item, index) => {
                const stepId = (index + 1).toString().padStart(2, "0");
                return (
                  <li
                    key={stepId}
                    className={`step-item ${
                      activeTab === stepId ? "active" : ""
                    }`}
                    onClick={() => handleStepClick(stepId)}
                  >
                    <h6 className="admission_step_number">{stepId}</h6>
                    <span>{item.title.split(" via ")[0]}</span>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* Content Section */}
          <div className="col-md-6 text-start">
            <div className="get_in_touch_content">
              <div className="enquiry-section">
                <div className="process_right_gap">
                  {admission.map((item, index) => {
                    const stepId = (index + 1).toString().padStart(2, "0"); // Formats 01, 02, etc.
                    const [mainTitle, subtitle] = item.title.split(" via ");
                    return (
                      <div
                        id={stepId}
                        key={item._id}
                        className="content-block for_circle text-start"
                        data-step={stepId} // This dynamically sets the step content
                      >
                        <div className="container d-flex justify-content-center align-items-center">
                          <div className="text-start p-4 process_year_content">
                            <h6>{mainTitle}</h6>
                            <h5>{subtitle || ""}</h5>
                            <h4>{item.sub_title}</h4>
                            <p>{item.content}</p>
                            {item.btnlink &&
                              item.btnlink !== "undefined" &&
                              item.btnlink.trim() !== "" && (
                            <div className="event_slider_left_btn process_step_btn">
                              <button type="button"onClick={() => {

handleNaviagte(item)
                                      // Determine if the link is absolute
                                      const isAbsolute =
                                        item.btnlink.startsWith("http://") ||
                                        item.btnlink.startsWith("https://") ||
                                        item.btnlink.startsWith("//");

                                      // Construct the full link
                                      const fullLink = isAbsolute
                                        ? item.btnlink
                                        : `${
                                            window.location.origin
                                          }/${item.btnlink.replace(/^\/+/, "")}`;

                                      // Open the link in a new tab
                                      // window.open(fullLink, "_blank");
                                    }} >{item.btntext}</button>
                            </div>
                             )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="prodecc_like_FAQ">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-12 faq_tabs">
                    {admission.map((item, index) => (
                      <div className={`details`} key={item._id}>
                        <div
                          className={`details__container ${
                            openIndex === index ? "open" : ""
                          }`}
                          onClick={() => toggleFAQ(index)}
                        >
                          <summary className="details__summary">
                            <h2 className="faq_details__title">
                              {item.title.split(" via ")[0]}
                            </h2>
                          </summary>
                        </div>
                        <div
                          className={`details__desc ${
                            openIndex === index ? "open" : ""
                          }`}
                        >
                          <div className="details__desc-inner">
                            <h4 className="academisn_faq_sub_title">{item.sub_title}</h4>
                            <p>{item.content}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Extra Content Section */}
          <div className="col-md-3">
            <div className="extra-content">
              <Slider {...sliderSettings} className="curriculum_slider">
                {/* Map over the images array from admission[0] */}
                {admission?.[0]?.image.map((imgSrc, index) => (
                  <div key={index}>
                    <img
                      src={`${FRONT_ASSETS_URL}${imgSrc}`}
                      alt={`Slide ${index + 1}`}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process_Step;
