import React, { useEffect, useState } from "react";
import Header from "../Header/header";
import Footer from "../footer/footer";
import "./career.css";
import { FaUpload } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { careerApply } from "../../controllers/careerController/careerController";
import { beforeUploadPDF } from "../../controllers/common";
import { notification } from "antd";
import {CheckCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Country, State, City } from "country-state-city";

function Career() {
  const [inputValue, setInputValue] = useState("");
  const [dragging, setDragging] = useState(false);
  const [file, setFile] = useState(null);
  const location = useLocation(); // Access the current location object
  const state = location.state; // Retrieve the passed state

  const [formData, setFormData] = useState({
    fullName: "",
    job_title: state?.job_title||'',
    mobile: "",
    email: "",
    message: "",
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    kcApplication: "",
    reason: "",
    file: null,
  });
  const [errors, setErrors] = useState({});



  // Country City & State
    const selectedCountry = Country.getAllCountries().find(
      (c) => c.name === formData.country
    );
    
    // Get selected state object
    const selectedState = selectedCountry
      ? State.getStatesOfCountry(selectedCountry.isoCode).find(
          (s) => s.name === formData.state
        )
      : null;

  // END

  // Handle drag enter
  const handleDragEnter = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(true);
  };

  // Handle drag leave
  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);
  };

  // Handle drop
  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragging(false);

    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      setFile(droppedFiles[0]);
    }
  };
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: "" });
  };

  const handleFileChange = async (event) => {
    const uploadedFile = event.target.files[0];
    if (await beforeUploadPDF(uploadedFile)) {
      setFormData({ ...formData, file: uploadedFile });
      setErrors({ ...errors, file: "" });
    }
  };
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
      reason: value === "Yes" ? "" : "null", // Reset reason if "No" is selected
    });
    setErrors({ ...errors, [name]: "" });
  };
  

  const validateForm = () => {
    const newErrors = {};
    if (!formData.fullName) newErrors.fullName = "Full Name is required.";
    else {
      // Check for numbers
      if (/\d/.test(formData.fullName)) {
        newErrors.fullName = "name cannot contain numbers.";
      }
      // Check for special characters (except apostrophes and hyphens)
      if (/[^a-zA-Z\s'-]/.test(formData.fullName)) {
        newErrors.fullName = "name can only contain alphabets, apostrophes, and hyphens.";
      }
      // Check minimum character limit (e.g., minimum 2 characters)
      if (formData.fullName.trim().length < 2) {
        newErrors.fullName = "name must be at least 2 characters long.";
      }
    }
  // Mobile Number Validation (10 digits)
  if (!formData.mobile) {
    newErrors.mobile = "Mobile Number is required.";
  } else {
    // Check for exactly 10 digits
    const phoneRegex = /^\d{10}$/;
    if (!phoneRegex.test(formData.mobile)) {
      newErrors.mobile = "Phone number must be exactly 10 digits.";
    }
    // Check if phone number starts with zero
    if (/^0/.test(formData.mobile)) {
      newErrors.mobile = "Phone number cannot start with zero.";
    }}

  // Email Validation (Proper email format)
  if (!formData.email) {
    newErrors.email = "Email Address is required.";
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
    newErrors.email = "Please enter a valid email address.";
  }
    if (!formData.address1) newErrors.address1 = "Address Line 1 is required.";
    if (!formData.country) newErrors.country = "Country is required.";
    if (!formData.state) newErrors.state = "State is required.";
    if (!formData.city) newErrors.city = "City is required.";
    // Pincode Validation (6-digit number)
    if (!formData.pincode) {
      newErrors.pincode = "Pincode is required.";
  } else if (!/^\d{6}$/.test(formData.pincode)) {
      newErrors.pincode = "Pincode must be exactly 6 digits.";
  }
    if (!formData.kcApplication)
        newErrors.kcApplication = "Please select an option.";
    if (formData.kcApplication === "Yes" && !formData.reason) {
        newErrors.reason = "Reason is required if you have applied before.";
      }
      
    if (!formData.file) newErrors.file = "Resume is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  /*********************************************************
   *  This function is use to fetch job list based on filters.
   *********************************************************/
  const handleSubmit = async () => {
    if (validateForm()) {
     const applicationData = new FormData(); // Avoid shadowing `formData` state
     applicationData.append("Job_Id", state._id); // From state
     applicationData.append("JobId", state.job_Id);
     applicationData.append("name", formData.fullName);
     applicationData.append("mobile", formData.mobile);
      applicationData.append("email", formData.email);
      applicationData.append("message", formData.message);
      applicationData.append("address1", formData.address1);
      applicationData.append("address2", formData.address2);
      applicationData.append("country", formData.country);
      applicationData.append("state", formData.state);
      applicationData.append("city", formData.city);
      applicationData.append("pincode", formData.pincode);
      applicationData.append("applied", formData.kcApplication === "Yes" ? "Y" : "N");
      applicationData.append("applied_text", formData.reason || "null"); // Dummy text if not filled
      applicationData.append("file", formData.file);

      try {
        const result = await careerApply(applicationData);
        if (result?.status === true) {
          // alert("Form submitted successfully!");
          notification.open({
            message: "Submitted.",
            description: `Form submitted successfully!`,
            placement: "topRight",
            icon: <CheckCircleOutlined style={{ color: "green" }} />,
            duration: 2,
          });
          setFormData({
            fullName: "",
            mobile: "",
            email: "",
            message: "",
            address1: "",
            address2: "",
            country: "",
            state: "",
            city: "",
            pincode: "",
            kcApplication: "",
            reason: "",
            file: null,
          });
        } else {
          // alert(`Error: ${result.message}`);
          notification.open({
            message: "Opps!",
            description: `Error: ${result.message}`,
            placement: "topRight",
            icon: <InfoCircleOutlined style={{ color: "red" }} />,
            duration: 2,
          });
        }
      } catch (error) {
        console.error("Form submission failed:", error);
        alert("An unexpected error occurred.");
      }
    }
  };
  // Scroll to the top of the page when the component mounts
useEffect(() => {
  window.scrollTo(0, 0);
}, []);

  return (
    <div>
      <Header></Header>
      <div className="career_section_gap">
        <div className="container-fluid">
          <div className="text-center career_form">
            <h6>APPLY FOR JOB: :</h6>
            <h2>{state?.job_title} </h2>
            <h4>{state?.campus?.join(", ") || ""}</h4>
          </div>
          <div className="career_form_section">
            <form>
              <div className="row g-3">
                <div className="col-md-4 regestration_form_input position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Full Name *"
                    name="fullName"
                    value={formData.fullName}
                    onChange={handleInputChange}
                  />
                  {errors.fullName && (
                    <p class="text-danger">{errors.fullName}</p>
                  )}
                </div>
                <div className="col-md-4 regestration_form_input">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Mobile Number "
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                  />
                  {errors.mobile && (
                    <p class="text-danger">{errors.mobile}</p>
                  )}
                </div>
                <div className="col-md-4 regestration_form_input">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email Address *"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {errors.email && (
                    <p class="text-danger">{errors.email}</p>
                  )}
                </div>
                <div className="col-md-12 regestration_form_input">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Your Message Here... "
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                  {errors.message && (
                    <p class="text-danger">{errors.message}</p>
                  )}
                </div>
                <div className="col-md-6 regestration_form_input">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address line 1 * "
                    name="address1"
                    value={formData.address1}
                    onChange={handleInputChange}
                  />
                  {errors.address1 && (
                    <p class="text-danger" >{errors.address1}</p>
                  )}
                </div>
                <div className="col-md-6 regestration_form_input">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Address line 2 "
                    name="address2"
                    value={formData.address2}
                    onChange={handleInputChange}
                  />
                  {errors.address2 && (
                    <p style={{ color: "red" ,textAlign: "left"}}>{errors.address2}</p>
                  )}
                </div>
  
    {/* Country Dropdown */}
    <div className="col-md-4 regestration_form_input">
      <select
        className="form-select"
        name="country"
        value={formData.country}
        onChange={handleInputChange}
      >
        <option value="">Select Country *</option>
        {Country.getAllCountries().map((country) => (
          <option key={country.isoCode} value={country.name}>
            {country.name} {/* Display full name and store name */}
          </option>
        ))}
      </select>
      {errors.country && <p className="text-danger">{errors.country}</p>}
    </div>

    {/* State Dropdown */}
    <div className="col-md-4 regestration_form_input">
      <select
        className="form-select"
        name="state"
        value={formData.state}
        onChange={handleInputChange}
        disabled={!formData.country} // Disable if no country is selected
      >
        <option value="">Select State *</option>
        {selectedCountry &&
          State.getStatesOfCountry(selectedCountry.isoCode)?.map((state) => (
            <option key={state.isoCode} value={state.name}>
              {state.name} {/* Display full name and store name */}
            </option>
          ))}
      </select>
      {errors.state && <p className="text-danger">{errors.state}</p>}
    </div>

    {/* City Dropdown */}
    <div className="col-md-4 regestration_form_input">
      <select
        className="form-select"
        name="city"
        value={formData.city}
        onChange={handleInputChange}
        disabled={!formData.state} // Disable if no state is selected
      >
        <option value="">Select City *</option>
        {selectedState &&
          City.getCitiesOfState(selectedCountry.isoCode, selectedState.isoCode)?.map(
            (city) => (
              <option key={city.name} value={city.name}>
                {city.name} {/* Display full name and store name */}
              </option>
            )
          )}
      </select>
      {errors.city && <p className="text-danger">{errors.city}</p>}
    </div>

                <div className="col-md-4 regestration_form_input">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Pincode *"

                    name="pincode"
                    value={formData.pincode}
                    onChange={handleInputChange}
                  />
                  {errors.pincode && (
                    <p class="text-danger">{errors.pincode}</p>
                  )}
                </div>
                <div className="d-flex justify-content-between align-items-center radio-container regestration_div">
                  <label className="regestration_applied_heading">
                    Have you previously applied at KC High International
                    School? *
                  </label>
                  <div className="d-flex gap-3 regestration_child_div">
                    <label className="regestration_kc_radio">
                      <input
                        type="radio"
                        name="kcApplication"
                        value="Yes"
                        checked={formData.kcApplication === "Yes"}
                        onChange={handleRadioChange}
                         id="radio_btn"
                      />
                      <span className="regestration_applied_yes">Yes</span>
                    </label>
                    <label className="regestration_kc_radio">
                      <input
                        type="radio"
                        name="kcApplication"
                        value="No"
                        checked={formData.kcApplication === "No"}
                        onChange={handleRadioChange}
                         id="radio_btn"
                      />
                      <span className="regestration_applied_yes">No</span>
                    </label>
                  </div>
                </div>
                <hr className="career_hr"></hr>
                {formData.kcApplication === "Yes" && (
                <div className="d-flex justify-content-between radio-container  regestration_text_area career_text_area">
                  <textarea
                    className="custom-textarea"
                    placeholder="If you selected Yes on the left, then give us more information about the position you applied for and date of application."
                    name="reason"
                    value={formData.reason}
                    onChange={handleInputChange}
                    disabled={formData.kcApplication !== "Yes"}
                  ></textarea>
                  {errors.reason && (
                    <p class="text-danger">{errors.reason}</p>
                  )}
                </div>
                )}
                <div className="career_upload_resume">
                  <div className="uplaod_resume_program">
                    <div className="online_program_section mt-4 mb-3">
                      <h5 className="heading-text">Upload Resume :</h5>
                    </div>
                    {/* <div className="form_file_upload d-flex justify-content-center align-items-center">
                                            <label className="file-upload-label  px-4 py-2">
                                                <FaUpload className="me-2" />
                                                Click to choose a file or drag here
                                                <input type="file" className="d-none" />
                                            </label>
                                        </div> */}
                    <div
                      className={`form_file_upload d-flex justify-content-center align-items-center ${
                        dragging ? "dragging" : ""
                      }`}
                      onDragEnter={handleDragEnter}
                      onDragOver={handleDragEnter}
                      onDragLeave={handleDragLeave}
                      onDrop={handleDrop}
                      style={{
                        backgroundColor: dragging ? "#f8f9fa" : "transparent",
                      }}
                    >
                      <label className="file-upload-label px-4 py-2">
                      <FaUpload className="me-2" />
                      {formData.file ? formData.file.name : "Click to choose a file or drag here"}
                        <input
                          type="file"
                          className="d-none"
                          onChange={handleFileChange}
                        />
                      </label>
                    </div>
                    {errors.file && <p class="text-danger">{errors.file}</p>}
                  </div>
                </div>
                <div className="form_subbmit_btn career_submit_btn">
                  <button type="button" onClick={handleSubmit}>Submit Application</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Career;
