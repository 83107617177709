import React, { useEffect, useRef, useState } from "react";
import Header from "../../Header/header";
import { useNavigate } from "react-router-dom";
import Footer from "../../footer/footer";
import Career_option_sidebar from "../career_option_sidebar/career_option_sidebar";
import Career_option_banner from "../career_option_banner/career_option_banner";
import "../career_option.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import { FiPhone } from "react-icons/fi";
import career1 from "../../images/career_option1.png";
import career2 from "../../images/career_option2.png";
import career3 from "../../images/career_option3.png";
import career4 from "../../images/career_option4.png";
import career5 from "../../images/career_option5.png";
import { IoCloseSharp } from "react-icons/io5";
import { careerList } from "../../../controllers/careerController/careerController";
import { useSelector } from "react-redux";
import { fetchGeneralData } from "../../../features/tasks/footerSlice";
import { galleryList } from "../../../controllers/commonController/cmsController";
import { FRONT_ASSETS_URL } from "../../../config/constants";

function Career_rr_campus() {
  const [isLoading, setIsLoading] = useState(true);
  const [jobList, setjobList] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [filters, setFilters] = useState({});
  const navigate = useNavigate();
  const { generalDatas } = useSelector((state) => state.footerReducer);
  const generalData = generalDatas?.[0] || {};

  /*********************************************************
   *  This function is use to fetch job list based on filters.
   *********************************************************/
  const gallerydata = async () => {
    setGalleryImages([]);
    setIsLoading(true);
    try {
      const listData = await galleryList();
      if (listData?.status === true) {
        setGalleryImages(listData?.result);
      }
    } catch (error) {
      console.log("failed to fetch gallery image list:", error);
    } finally {
      setIsLoading(false);
    }
  }; //end
  /*********************************************************
   *  This function is use to fetch job list based on filters.
   *********************************************************/
  const jobListFunction = async () => {
    setjobList([]);
    setIsLoading(true);
    try {
      const options = {
        type: "",
        condition: {
          status: "A",
          ...filters, // Dynamically apply filters
        },
        select: {},
        sort: { _id: -1 },
        populate: {
          key: "",
          select: " ",
        },
        skip: 0,
        limit: 10,
      };
      const listData = await careerList(options);
      if (listData?.status === true) {
        setjobList(listData?.result);
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      setIsLoading(false);
    }
  }; //end

  /*********************************************************
   *  This function is use to nevigate to next page with states
   *********************************************************/
  const handleApplyClick = (selectedJob) => {
    // Navigate to the desired page with state
    navigate("/career", {
      state: {
        _id: selectedJob._id,
        job_Id: selectedJob.job_Id,
        job_title: selectedJob.job_title,
        campus: selectedJob.campus,
      },
    });
  }; //end

  /*********************************************************
   *  This function handles filter updates.
   *********************************************************/
  // const handleFiltersChange = (selectedFilters) => {
  //   console.log("Filters updated:", selectedFilters);

  //   // Check if selectedFilters is empty
  //   if (Object.keys(selectedFilters).length === 0) {
  //     setFilters({}); // Clear all filters if selectedFilters is empty
  //   } else {
  //     // Otherwise, merge the new filters with the existing ones
  //     setFilters((prevFilters) => ({
  //       ...prevFilters,
  //       ...selectedFilters, // Overwrite with new filters
  //     }));
  //   }
  // }; //end
  const handleFiltersChange = (selectedFilters) => {
    // console.log("Filters updated:", selectedFilters);

    // Check if selectedFilters is empty
    if (Object.keys(selectedFilters).length === 0) {
      setFilters({}); // Clear all filters if selectedFilters is empty
    } else {
      // Transform filters to use `$in` for certain keys
      const transformedFilters = {};
      for (const [key, values] of Object.entries(selectedFilters)) {
        if (
          [
            "subject",
            "experience",
            "campus",
            "position",
            "school_level",
          ].includes(key)
        ) {
          transformedFilters[key] = { $in: values };
        } else {
          transformedFilters[key] = values;
        }
      }

      // Merge with existing filters
      setFilters((prevFilters) => ({
        ...prevFilters,
        ...transformedFilters, // Overwrite with transformed filters
      }));
    }
  };
  /*********************************************************
   *  Trigger jobListFunction whenever filters change.
   *********************************************************/
  useEffect(() => {
    jobListFunction();
    gallerydata();
  }, [filters]); // Dependency on filters//end

  // useEffect(() => {
  //   if (generalDatas.length === 0) {
  //     dispatch(fetchGeneralData());
  //   }
  // }, [dispatch, generalDatas]); // Dependency on filters//end

  /*********************************************************
   *  handle job click
   *********************************************************/
  const handleJobClick = (job) => {
    setSelectedJob(job);
  }; //end
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [showMore, setShowMore] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);

  // Check screen size on mount and resize
  useEffect(() => {
    const checkScreenSize = () => setIsMobileView(window.innerWidth <= 768);
    checkScreenSize(); // Initial check
    window.addEventListener("resize", checkScreenSize);

    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const toggleText = () => {
    setShowMore(!showMore);
  };

  const content = selectedJob?.content || "No description available";

  const imageClasses = [
    { colClass: "col-md-4", size: "small" },
    { colClass: "col-md-8", size: "large" },
    { colClass: "col-md-8", size: "small" },
    { colClass: "col-md-4", size: "small" },
  ];

  return (
    <div>
      <Header />
      <div className="career_option_banner">
        <Career_option_banner></Career_option_banner>
      </div>
      <div className="exam_gapping_div mt-4 career_gapping_mobile">
        <div className="container-fluid">
          <div className="row">
       <div className="col-md-3">
              <Career_option_sidebar
                onFiltersChange={handleFiltersChange}
              ></Career_option_sidebar> 
            </div>
            <div className="col-md-9">
              <div className="list-group">
                {isLoading ? (
                  <p>Loading jobs...</p>
                ) : jobList.length === 0 ? (
                  <p>No results found.</p>
                ) : (
                  jobList.map((job, index) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-center career_tech"
                      role="button"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#offcanvasTop"
                      aria-controls="offcanvasTop"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleJobClick(job)}
                    >
                      <span>{job.job_title}</span>{" "}
                      {/* Display job title dynamically */}
                      <div className="d-flex align-items-center career_go_right_arrow">
                        <FaArrowRightLong size={16} />
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
            {/*  off canvas top to bottom */}
            <div
              className="offcanvas offcanvas-top career_option_off_canvas"
              tabIndex="-1"
              id="offcanvasTop"
              aria-labelledby="offcanvasTopLabel"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
            >
              <div className="offcanvas-header canvas_btn">
                <h5 id="offcanvasTopLabel">
                  {selectedJob ? selectedJob.job_title : "Job Title"}
                </h5>
                <button
                  type="button"
                  className="close-icon"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <IoCloseSharp size={20} />
                </button>
              </div>
              <div className="offcanvas-body career_offcanvas_body">
                {selectedJob && (
                  <>
                    <div className="row text-start">
                      <div className="col-md-3 career_canvs_location">
                        <h6 className="mb-1 text-muted">Location:</h6>
                        <p className="fw-bold">
                          {selectedJob.campus?.join(", ") || "Not specified"}
                        </p>
                      </div>
                      <div className="col-md-3 career_canvs_location">
                        <h6 className="mb-1 text-muted">School Level:</h6>
                        <p className="fw-bold">
                          {selectedJob.school_level?.join(", ") ||
                            "Not specified"}
                        </p>
                      </div>
                      <div className="col-md-3 career_canvs_location">
                        <h6 className="mb-1 text-muted">Position:</h6>
                        <p className="fw-bold">
                          {selectedJob.position?.join(", ") || "Not specified"}
                        </p>
                      </div>
                      <div className="col-md-3 career_canvs_location">
                        <h6 className="mb-1 text-muted">Experience:</h6>
                        <p className="fw-bold">
                          {selectedJob.experience?.join(", ") ||
                            "Not specified"}
                        </p>
                      </div>
                    </div>
                    <div className="text-start career_canvas_text">
                      <h5>Job Description</h5>
                      <p>
                        {isMobileView && !showMore ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: content.slice(0, 100),
                            }}
                          ></span>
                        ) : (
                          <span
                            dangerouslySetInnerHTML={{ __html: content }}
                          ></span>
                        )}
                        {isMobileView && content.length > 100 && (
                          <span
                            onClick={toggleText}
                            style={{
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                            className="read_more_read_less"
                          >
                            {showMore ? "Read Less" : "...Read More"}
                          </span>
                        )}
                      </p>
                    </div>
                    <div className="form_subbmit_btn">
                      <button
                        type="button"
                        data-bs-dismiss="offcanvas"
                        onClick={() => handleApplyClick(selectedJob)}
                      >
                        Apply Now
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="career_option_connect career_optiontabs">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6 career_connect_div">
              <div className="text-start career_with_us career_with_tabs">
                <h2>
                  Connect <span>With Us</span>
                </h2>
                <p>
                  Have questions about careers, company culture, or the hiring
                  process? Our HR team is here to help!
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="career_mail_section">
                <div className="d-flex gap-3">
                  {/* Email Card */}
                  <div className="contact-card">
                    <div className="icon-container">
                      <MdEmail className="text-white fs-4" />
                    </div>
                    <p className="mb-0 text-center">
                      {generalData?.generalData_email}
                    </p>
                  </div>

                  {/* Phone Card */}
                  <div className="contact-card">
                    <div className="icon-container">
                      <FiPhone className="text-white fs-4" />
                    </div>
                    <p className="mb-0 text-center">
                      {generalData?.generalData_phone}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="policies_gapping_div">
        <div className="container-fluid">
          <div className="row">
            <div className=" col-md-12 ">
              <div className="text-center calendar_school_text">
                <h2>
                  Life at <span> KC High</span>
                </h2>
                <p className="founder_paragraph">
                  Admissions are now open for children in multiple age groups,
                  from early learners to advanced grades. Join us in providing
                  your child with a strong foundation and a path to excellence!
                </p>
              </div>
            </div>
            {galleryImages?.[0]?.image && (
              <div className="col-md-5 mt-4">
                <div className="w-100 h-100 career_left_img_section">
                  <img
                    src={`${FRONT_ASSETS_URL}${galleryImages[0].image}`}
                    alt="Full Image"
                    className="img-fluid rounded"
                  />
                </div>
              </div>
            )}
            {/* Right Side Images */}
            <div className="col-md-7 mt-4">
              <div className="row g-3">
                {galleryImages.slice(1, 5).map((image, index) => (
                  <div
                    key={index}
                    className={`${imageClasses[index].colClass} career_left_img_section`}
                  >
                    <img
                      src={`${FRONT_ASSETS_URL}${image?.image}`}
                      alt={`${imageClasses[index].size} Image`}
                      className="img-fluid rounded"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Career_rr_campus;
