import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { generalData } from "../../controllers/commonController/cmsController";

// Async thunk to fetch general data
export const fetchGeneralData = createAsyncThunk(
    'task/fetchGeneralData',
    async (_, { getState, rejectWithValue }) => {
        try {
            const { generalDatas } = getState().footerReducer; 
            if (Array.isArray(generalDatas) && generalDatas.length > 0) {
                // Return existing state data if already present
                return generalDatas;
            }

            const options = {
                type: "",
                condition: { 
                    status: "A"
                },
                select: {},
                sort: { _id: -1 },
                populate: { 
                    key: "",
                    select: " "
                },
                skip: 0,
                limit: 10
            };
            const res = await generalData(options); 
            return res?.result || []; 
        } catch (error) {
            console.error("API Error:", error);
            return rejectWithValue(error.message); 
        }
    }
);

// Redux Toolkit slice
const footerReducer = createSlice({
    name: 'footer',
    initialState: {
        generalDatas: [], // Initialize task as an empty array
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchGeneralData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchGeneralData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.generalDatas = action.payload; // Update the state with fetched data
            })
            .addCase(fetchGeneralData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; 
            });
    },
});

// Export the reducer
export default footerReducer.reducer;


