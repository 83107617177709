import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../Header/header";
import Footer from "../footer/footer";
import { Helmet } from "react-helmet";
import {
  articles,
  articlesDetail,
} from "../../controllers/commonController/cmsController";
import { FRONT_ASSETS_URL } from "../../config/constants";
import moment from "moment/moment";

function Details_page() {
  const [isSticky, setIsSticky] = useState(false);
  const footerRef = useRef(null); // Reference to the footer element
  const [activeSection, setActiveSection] = useState(""); // Track the active section
  const { slug } = useParams();
  const [cmsArticleDetail, setcmsArticleDetail] = useState("");

  const [cmsArticleList, setcmsArticleList] = useState([]);

  /*********************************************************
   *  This function is use to fetch cms artical list
   *********************************************************/
  const cmsArticles = async () => {
    setcmsArticleList([]);
    // setIsLoading(true);
    try {
      const options = {
        // type: "news",
        condition: {
          status: "A",
          type: cmsArticleDetail?.type,
          limit: 2,
        },
      };
      const listData = await articles(options);
      if (listData?.status === true) {
        setcmsArticleList(listData?.result);
        // console.log("related stories,", listData.result);
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      //   setIsLoading(false);
    }
  }; //end

  useEffect(() => {
    cmsArticles();
  }, [cmsArticleDetail]);
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  // Fetch CMS article details
  const cmsArticlesDetails = async () => {
    try {
      const options = { slug };
      const listData = await articlesDetail(options);
      if (listData?.status === true) {
        setcmsArticleDetail(listData?.result);
      }
    } catch (error) {
      console.error("Failed to fetch article details:", error);
    }
  };

  useEffect(() => {
    cmsArticlesDetails();
  }, [slug]);

  useEffect(() => {
    const handleScroll = () => {
      const footer = footerRef?.current;
      const scrollPosition = window.scrollY;

      // Sticky footer logic
      if (footer) {
        const footerPosition = footer.getBoundingClientRect();
        const isFooterVisible = footerPosition.top <= window.innerHeight;

        if (isFooterVisible) {
          setIsSticky(false); // Footer is visible, disable sticky class
        } else if (scrollPosition > 380) {
          setIsSticky(true); // Footer is not visible, enable sticky class
        } else {
          setIsSticky(false); // Ensure class is disabled if below threshold
        }
      } else {
        // Fallback logic for sticky class if footer is not defined
        setIsSticky(scrollPosition > 380);
      }

      // Active section logic
      if (cmsArticleDetail?.sections) {
        cmsArticleDetail.sections.forEach((section, index) => {
          const sectionId = `section-${index}`;
          const sectionElement = document.getElementById(sectionId);

          if (sectionElement) {
            const top = sectionElement.offsetTop - 150;
            const bottom = top + sectionElement.offsetHeight;

            if (scrollPosition >= top && scrollPosition < bottom) {
              setActiveSection(sectionId);
            }
          }
        });
      }
    };

    // Attach scroll listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Cleanup listener
      window.removeEventListener("scroll", handleScroll);
    };
  }, [cmsArticleDetail]);


  const handleLinkClick = (event, targetId) => {
    event.preventDefault();
    setActiveSection(targetId);

    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 85,
        behavior: "smooth",
      });
    }
  };

  const navigate = useNavigate();
  //     const navigate = useNavigate();
  const handleMoveToRegestrationPagek = () => {
    navigate("/online-form");
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };
  //   useEffect(() => {
  //     if (cmsArticleDetail) {
  //         // Set the document title
  //         document.title = cmsArticleDetail.metaTitle || "Default Title";

  //         // Set the meta description
  //         const metaDescription = document.querySelector('meta[name="description"]');
  //         if (metaDescription) {
  //             metaDescription.setAttribute("content", cmsArticleDetail.metaDescription || "Default description");
  //         } else {
  //             const meta = document.createElement("meta");
  //             meta.name = "description";
  //             meta.content = cmsArticleDetail.metaDescription || "Default description";
  //             document.head.appendChild(meta);
  //         }
  //     }
  // }, [cmsArticleDetail]);

  return (
    <div>
      <Helmet>
        <title>{cmsArticleDetail?.metaTitle || "Default Title"}</title>
        <meta
          name="description"
          content={cmsArticleDetail?.metaDescription || "Default description"}
        />
      </Helmet>
      <Header />
      <div>
        <div className="inner_mainbanner">
          <div className="exam_gapping_div">
            <div className="container-fluid">
              <div className="news_banner_main_div details_banner_section">
                <div className="row align-items-center justify-content-center">
                  <div className="col-md-6 text-start details_banner_heading next_deatils_tabs">
                    <h4>
                      {cmsArticleDetail?.title && (
                        <>
                          {cmsArticleDetail.title.split(" ")[0]}{" "}
                          <span>
                            {cmsArticleDetail.title
                              .split(" ")
                              .slice(1)
                              .join(" ")}
                          </span>
                        </>
                      )}
                    </h4>
                    <hr />
                    <div className="details_meta">
                      <p>
                        Written by <br />
                        <span>{cmsArticleDetail?.author}</span>
                      </p>
                      <p>
                        Posted on <br />
                        <span>{moment(cmsArticleDetail?.posted_date).format("Do MMMM YYYY")}</span>

                      </p>
                    </div>
                  </div>
                  <div className="col-md-6 d-flex justify-content-center">
                    <div className="details_banner_img">
                      <img
                        src={`${FRONT_ASSETS_URL}${cmsArticleDetail?.image}`}
                        alt="common_banner"
                        className="img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="exam_gapping_div">
          <div className="container-fluid">
            <div className="info_all_nav mb-4">
              <div className="row align-items-center">
                <div className="col-md-12">
                  <nav aria-label="breadcrumb" className="news_info_breadcrumb">
                    <ol className="breadcrumb mb-0">
                      <li className="breadcrumb-item">
                        <Link to="/#">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        <Link to={`/${cmsArticleDetail?.type}`}>
                          <span>{cmsArticleDetail?.type}</span>
                        </Link>
                      </li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="details_step_gapping next_deatils_gapping">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div
                  className={`all_sticky ${isSticky ? "sticky_info_for" : ""}`}
                >
                  <ul className="nav flex-column admission_process_tabs text-start details_border">
                    <h6>Table of Content</h6>
                    {cmsArticleDetail?.sections?.map((section, index) => (
                      <li key={index}>
                        <Link
                          to={`#section-${index}`}
                          onClick={(e) =>
                            handleLinkClick(e, `section-${index}`)
                          }
                          className={
                            activeSection === `section-${index}` ? "active" : ""
                          }
                        >
                          <span>{section.title}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="col-md-9 text-start">
                {cmsArticleDetail?.sections?.map((section, index) => (
                  <div
                    key={section._id}
                    id={`section-${index}`}
                    className="content-block text-start"
                  >
                    <div className="text-start exam_program_text ">
                      <h1>{section.title}</h1>
                      <h5>{section.subtitle}</h5>
                      <div
                        className="details_page_inner_html"
                        dangerouslySetInnerHTML={{ __html: section.content }}
                      />
                    </div>
                  </div>
                ))}
              </div>

              {/* Additional right-side content */}
              {/* <div className="col-md-3">
                <div className="mb-5">
                  <div className="home_founder_section  details_founder">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="align-items-center academic_early_message deatails_padding">
                          <div className="d-flex align-items-center home_founder_sub_image">
                            <div className="founder-text  details_right_section_text">
                              <h2>Shaping Tomorrow’s Leaders Today</h2>
                              <p className="text-white together mt-4">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's
                              </p>
                              <div
                                className="mt-4"
                                onClick={handleMoveToRegestrationPagek}
                              >
                                <button className="academics_button">
                                  Register Now!
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {cmsArticleList?.length > 1 && (
                  <>
                <div className="text-start exam_program_text new_details_title">
                  <h1>
                    Related <span>{cmsArticleDetail?.type}</span>
                  </h1>
                </div>
                {cmsArticleList
                  ?.filter((item) => item?.slug !== cmsArticleDetail?.slug) 
                  .map((article, index) => (
                    <div key={index} className="news_lsiting_slide mb-4">
                      <Link to={`/${article?.slug}`}>
                        <div className="home_event_card event_card">
                          <div className="home_event_custom_card card text-center border">
                            <div className="home_event_card_body text-start event_slider_contnet new_deatils details_card_content">
                              <h4 className="event-label">
                                {article?.type}{" "}
                                <span>/ By {article?.author || "Unknown"}</span>
                              </h4>
                              <h5 className="card-title text-start">
                                {article?.title}
                              </h5>
                              <p className="text-start">{article?.content}</p>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="text-start event-date home_event_slider_bottom">
                              {formatDate(article?.createdAt)}
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  ))}
                  </>
                )}
              </div> */}
              <div className="col-md-12">
                {/* <div className="mb-5">
                  <div className="home_founder_section  details_founder">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="align-items-center academic_early_message deatails_padding">
                          <div className="d-flex align-items-center home_founder_sub_image">
                            <div className="founder-text  details_right_section_text">
                              <h2>Shaping Tomorrow’s Leaders Today</h2>
                              <p className="text-white together mt-4">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry. Lorem Ipsum has been
                                the industry's
                              </p>
                              <div
                                className="mt-4"
                                onClick={handleMoveToRegestrationPagek}
                              >
                                <button className="academics_button">
                                  Register Now!
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                {cmsArticleList?.length > 1 && (
                  <>
                    <div className="text-start exam_program_text new_details_title">
                      <h1>
                        Related <span>{cmsArticleDetail?.type}</span>
                      </h1>
                    </div>
                    <div className="expam_program_new_card">
                      {cmsArticleList
                        ?.filter((item) => item?.slug !== cmsArticleDetail?.slug)
                        .map((article, index) => (
                          <div key={index} className="news_lsiting_slide mb-4 deatils_row_card">
                            <Link to={`/${article?.slug}`}>
                              <div className="home_event_card event_card">
                                <div className="home_event_custom_card card text-center border">
                                  <div className="home_event_card_body text-start event_slider_contnet new_deatils details_card_content">
                                    <h4 className="event-label deatils_mobile_event">
                                      {article?.type}{" "}
                                      <span>/ By {article?.author || "Unknown"}</span>
                                    </h4>
                                    <h5 className="card-title text-start">
                                      {article?.title}
                                    </h5>
                                    <p className="text-start">{article?.content}</p>
                                  </div>
                                </div>
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="text-start event-date home_event_slider_bottom">
                                    {formatDate(article?.createdAt)}
                                  </div>
                                </div>
                              </div>
                            </Link>
                          </div>
                        ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer ref={footerRef}>
        <Footer />
      </footer>
    </div>
  );
}

export default Details_page;
