import './App.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js"
import Home from './component/Home/home';
import About from './component/About/about';
import Academics_early from './component/Academics_Section/academics_early/academics_early.js';
import Academic_primary from './component/Academics_Section/academic_primary/academic_primary.js';
import Academics_middle from './component/Academics_Section/academics_middle/academics_middle.js';
import Academics_senior from './component/Academics_Section/academics_senior/academics_senior.js';
import Admission_info from './component/Admission_Section/admision_info/admission_info.js';
import ContactUS from './component/contact_us/contact-us.js';
// import Registration_and_schedule from './component/UnitExam/ap_exam/Registration and Schedule/registration_and_schedule.js';
import Intro_and_benifit from './component/UnitExam/ap_exam/Intro and benifit/intro_and_benifit.js';
import Advanced_course from './component/UnitExam/ap_course/advanced_course/advanced_course.js';
// import Course from './component/UnitExam/ap_course/course/course.js';
// import Schedule from './component/UnitExam/sap_exam/schedule/schedule.js';
import SapExamIntro from './component/UnitExam/sap_exam/intro-and-benifit/sap_exam_intro.js';
import Career from './component/career section/career.js';
import Privacy_policy from './component/Privacy_policy/privacy_policy.js';
import Calendar from './component/Calendar/calendar.js';
import Founder from './component/Founder/founder.js';
/////////////////////// online registration form routing
import Enterstudent from './component/Admission_Section/online_regestration/Enter Student/enter-student.js';
import ForeignNationalsOnly from './component/Admission_Section/online_regestration/Foreign Nationals only/foreign-nationals-only.js';
import Previouslyattendeddetails from './component/Admission_Section/online_regestration/Previously attended details/previously-attended-details.js';
import homedetails from './component/Admission_Section/online_regestration/home-details/home-details.js';
import OtherDetails from './component/Admission_Section/online_regestration/Other Details/other-details.js';
import Detailsofsiblings from './component/Admission_Section/online_regestration/Details of Siblings/details-of-siblings.js';
import Detailsoffather from './component/Admission_Section/online_regestration/Details of Father/details-of-father.js';
import Detailsofmother from './component/Admission_Section/online_regestration/details-of-mother/details-of-other.js';
import EmergencyContacts from './component/Admission_Section/online_regestration/Emergency Contacts/emergency-contacts.js';
import Programforadmission from './component/Admission_Section/online_regestration/Programme/program-for_admission.js';
import Parentquestionnaires from './component/Admission_Section/online_regestration/Parent Questionnaires/parent-questionnaires.js';
import Career_rr_campus from './component/career option/career campus/career_rr_campus.js';
import Career_op_campus from './component/career option/career campus/career_op_campus.js';
import Homedetails from './component/Admission_Section/online_regestration/home-details/home-details.js';
import Counselling_department from './component/counselling department/counselling_department.js';
import News from './component/News/news.js';
import Event from './component/Event-Section/event.js';
import NewsEvent from './component/News-Events/news-event.js';
import Details_page from './component/DeatilsPage/details_page.js';
import New_details from './component/New-Deatils/new_details.js';
import Faq from './component/FAQ/faq.js';
import FeedbackPopup from './component/FeedBack popup/feedbackPopup.jsx';
import { useState } from 'react';
import NotFound from './component/404NotFount.js';


function App() {
  const [showPopup, setShowPopup] = useState(false);
  return (
    <div className="App">
  {/* <div className="App">
      <h1>Welcome to the KC Website</h1>
      <button onClick={() => setShowPopup(true)}>Leave Feedback</button>

      {showPopup && <FeedbackPopup onClose={() => setShowPopup(false)} />}
    </div> */}

      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound/>} />
          <Route path="/about" element={<About />} />
          <Route path="/academics/early" element={<Academics_early />} />
          <Route path="/academics/primary" element={<Academic_primary />} />
          <Route path="/academics/middle" element={<Academics_middle />} />
          <Route path="/academics/senior" element={<Academics_senior />} />
          <Route path="/admission-info" element={<Admission_info />} />
          <Route path="/contact-us" element={<ContactUS />} />
          {/* <Route path="/registration-and-schedule" element={<Registration_and_schedule/>} /> */}
          <Route path="/intro-and-benifit" element={<Intro_and_benifit />} />
          <Route path="/advanced-course" element={<Advanced_course />} />
          {/* <Route path="/course" element={<Course/>} /> */}
          {/* <Route path="/schedule" element={<Schedule/>} /> */}
          <Route path="/sap-exam-intro" element={<SapExamIntro />} />
          <Route path="/career" element={<Career />} />
          <Route path="/policy" element={<Privacy_policy />} />
          <Route path="/calendar" element={<Calendar />} />
          <Route path="/founder" element={<Founder />} />
          {/* ////////////////////// Online registration form routing  */}
          <Route path="/enter-student" element={<Enterstudent />} />
          <Route path="/foreign-nationals-Only" element={<ForeignNationalsOnly />} />
          <Route path="/previously-attended-details" element={<Previouslyattendeddetails />} />
          <Route path="/home-details" element={<Homedetails />} />
          <Route path="/other-details" element={<OtherDetails />} />
          <Route path="/details-of-siblings" element={<Detailsofsiblings />} />
          <Route path="/details-of-father" element={<Detailsoffather />} />
          <Route path="/details-of-mother" element={<Detailsofmother />} />
          <Route path="/emergency-contacts" element={<EmergencyContacts />} />
          <Route path="/program-for-admission" element={<Programforadmission />} />
          <Route path="/parent-questionnaires" element={<Parentquestionnaires />} />
          {/* ////////////////////// Online registration end form routing  */}
          <Route path="/job-listing" element={<Career_rr_campus />} />
          {/* <Route path="/career-op-campus" element={<Career_op_campus/>} /> */}
          <Route path="/counselling-department" element={<Counselling_department />} />
          <Route path="/news" element={<News />} />
          <Route path="/event" element={<Event />} />
          <Route path="/news-events" element={<NewsEvent />} />
          <Route path="/:slug" element={<Details_page />} />
          <Route path="/new_details" element={<New_details />} />
          <Route path="/faq" element={<Faq />} />
          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
