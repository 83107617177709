import React, { useEffect, useState } from 'react';
import Header from '../../../Header/header';
import Footer from '../../../footer/footer';
import Common_banner from '../../common_banner/common_banner';
import Sidebar from '../../side_bar/side_bar';
import "../advanced_course.css";
import coursed_btn_img from "../../../images/courses_btn.png";
import { apCourses } from "../../../../controllers/newsExamController/newsExamController";
import { FRONT_ASSETS_URL } from '../../../../config/constants';

function Advanced_course() {
    const [courses, setCourses] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    /*********************************************************
     *  This function is used to fetch the apCoursesList
     *********************************************************/
    const apCoursesList = async () => {
        setCourses([]);
        setIsLoading(true);
        try {
            const listData = await apCourses();
            if (listData?.status === true) {
                setCourses(listData?.result);
            }
        } catch (error) {
            console.error('Failed to fetch policy list:', error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        apCoursesList();
    }, []);
      // Scroll to the top of the page when the component mounts
useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

    return (
        <div>
            <Header />
            <div className="inner_mainbanner">
                <Common_banner />
            </div>
            <div className="exam_gapping_div">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3 ">
                            <Sidebar />
                        </div>
                        <div className="col-md-9">
                            <div className="exam_placement_program" id="course">
                                <div className="text-start exam_program_text">
                                    <div className="courses_placement">
                                        <h1>
                                            Advanced Placement <span> Courses</span>
                                        </h1>
                                        <button type="button">
                                            <img
                                                src={coursed_btn_img}
                                                alt="coursed_btn_img"
                                            />
                                        </button>
                                    </div>

                                    {/* Description */}
                                    <p className="text-muted">
                                        We offer Advanced Placement (AP) Courses
                                    </p>
                                </div>
                                <div className="text-start exam_benifits_section">
                                    <h5>Hybrid Digital Subjects</h5>
                                </div>

                                {/* Render courses dynamically */}
                                {courses.map((course, index) => (
                                    <div
                                        key={course._id}
                                        className="d-flex align-items-center justify-content-between px-4 exam_course_btn"
                                    >
                                        <span className="text-start">
                                            {course.name}
                                        </span>
                                        <div className="event_slider_left_btn exam_btn">
                                            <button
                                                type="button"
                                                className="early_read_btn"
                                                onClick={() => window.open(`${FRONT_ASSETS_URL}${course.file}`, '_blank')}
                                            >
                                                Know More
                                            </button>
                                        </div>
                                    </div>
                                ))}

                                {/* Show loading spinner if data is being fetched */}
                                {isLoading && (
                                    <div className="text-center">
                                        <p>Loading courses...</p>
                                    </div>
                                )}

                                {/* Show message if no courses are found */}
                                {!isLoading && courses.length === 0 && (
                                    <div className="text-center">
                                        <p>No courses available at the moment.</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Advanced_course;
