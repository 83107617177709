import React, { useEffect, useState } from "react";
import Header from "../../Header/header";
import Footer from "../../footer/footer";
import "../academics.css";
import Academic_early_slider from "./academic_early_slider";
import Common_faq from "../common_faq";
import Common_sticky_button from "../../Home/common_sticky_button";
import { academicsDetails } from "../../../controllers/academicsController/academicsController";
import { FRONT_ASSETS_URL } from "../../../config/constants";
import { Link } from "react-router-dom";

function Academics_early() {
  const [activeTab, setActiveTab] = useState(1); // Default to "3 - 4 years"
  const [isLoading, setIsLoading] = useState(false);
  const [academicsEarly, setAcademicsEarly] = useState("");

  /*********************************************************
   *  This function is use to fetch cms content list
   *********************************************************/
  const academicDetailsData = async () => {
    setAcademicsEarly([]);
    setIsLoading(true);
    try {
      const options = {
        slug: "early",
      };
      const listData = await academicsDetails(options);
      if (listData?.status === true) {
        setAcademicsEarly(listData?.result);
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      setIsLoading(false);
    }
  }; //end

  useEffect(() => {
    academicDetailsData();
  }, []);
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  

  ///////////////for safari
  useEffect(() => {
    // Detect Safari (exclude Chrome by checking userAgent)
    const userAgent = navigator.userAgent;
    if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
        // Add 'safari' class to the body element
        document.body.classList.add('for_safari');
    }
}, []);
  return (
    <div>
      <Header></Header>
      <section className="early_years_section">
        <div className="container-fluid">
          <div className="content-container">
            <div className="row">
              <div className="sec_adjust">
                <div className="col-12 col-md-6 col-lg-5">
                  <div className="text-start early_year_heading_Section">
                  {academicsEarly?.bannerData?.page==='early'?
                   <h2 style={{lineHeight:"60px"}}>Pre-Primary <span>School</span></h2>:
                    <h2>
                      <span>
                        {" "}
                        {academicsEarly?.bannerData?.title?.split(" ")[0]}
                      </span>{" "}
                      {academicsEarly?.bannerData?.title
                        ?.split(" ")
                        .slice(1)
                        .join(" ")}
                    </h2>
}
                    <h4>
                      {academicsEarly?.bannerData?.subtitle?.split(" ")[0]}
                      <span>
                        {" "}
                        {academicsEarly?.bannerData?.subtitle
                          ?.split(" ")
                          .slice(1)
                          .join(" ")}
                      </span>{" "}
                    </h4>
                    <p>{academicsEarly?.bannerData?.content}</p>
                  </div>
                </div>
                {/* <div className='col-12 col-md-6 col-lg-7'>
                  <div>
                    <img
                      src={early_top_img}
                      alt="Early Years Program"
                      className="early_circular_image"
                    />
                  </div>
                </div> */}
                <div className="col-12 col-md-6 col-lg-7">
                  <div className="pulse-container">
                    <img
                      src={`${FRONT_ASSETS_URL}${academicsEarly?.bannerData?.image}`}
                      alt="Early Years Program"
                      className="early_circular_image"
                    />
                    <div className="pulse pulse-1"></div>
                    <div className="pulse pulse-2"></div>
                    <div className="pulse pulse-3"></div>
                    <div className="pulse pulse-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center home_school_heading each_stage_main_div">
          <h2>
            Stages of <span className="highlight">Exploration</span>
          </h2>
          <p className="each_stage">
            Each stage of early learning is designed to nurture curiosity and
            build essential skills. Through <br></br> age-appropriate
            activities, children explore, discover, and grow at their own pace.
          </p>
        </div>
        <div className="all_tabs_section">
          <div className="early_tabs_container">
            {academicsEarly?.stagesData?.map((stage, index) => (
              <button
                key={stage._id}
                className={`tab-item ${
                  activeTab === index + 1 ? "active" : ""
                }`}
                onClick={() => setActiveTab(index + 1)}
              >
                {stage.grade}
              </button>
            ))}
          </div>

          <div className="early_tab_content">
            {academicsEarly?.stagesData?.map(
              (stage, index) =>
                activeTab === index + 1 && (
                  <div key={stage._id} className="tadpoles_div">
                    <div className="container-fluid">
                      <div className="row g-0">
                        <div className="col-md-5">
                          <div className="early_tabs_img_section">
                            <img
                              src={`${FRONT_ASSETS_URL}${stage.image}`}
                              alt={stage.title}
                              className="img-fluid rounded"
                            />
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="text-start early_tabs_tadpoles_div">
                            <h2 className="fw-bold">{stage.title}</h2>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: stage.content,
                              }}
                            />
                            {stage.link &&
                              stage.link !== "undefined" &&
                              stage.link.trim() !== "" && (
                                <div className="event_slider_left_btn">
                                  <button
                                    type="button"
                                    className="btn early_read_btn"
                                    onClick={() => {
                                      // Determine if the link is absolute
                                      const isAbsolute =
                                        stage.link.startsWith("http://") ||
                                        stage.link.startsWith("https://") ||
                                        stage.link.startsWith("//");

                                      // Construct the full link
                                      const fullLink = isAbsolute
                                        ? stage.link
                                        : `${
                                            window.location.origin
                                          }/${stage.link.replace(/^\/+/, "")}`;

                                      // Open the link in a new tab
                                      window.open(fullLink, "_blank");
                                    }}
                                  >
                                    Read More
                                  </button>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </section>
      <Common_sticky_button></Common_sticky_button>
      {/* /////////// */}
      {/* //////// Faq section */}
      <Common_faq faqData={academicsEarly?.faqData}></Common_faq>
      <div className="home_founder_section">
        <div className="container-fluid">
          <div className="all_section_margin">
            <div className="row">
              <div className=" academic_early_message">
                <div className="col-md-10">
                  <div className="d-flex align-items-center home_founder_sub_image">
                    <div className="founder-text ms-3 text-start founder_message_all_text">
                      <h2 className="academics_ready_text">
                        Ready to take the first step with us?
                      </h2>
                      <p className="text-white together">
                        Together, we will build a strong foundation for your
                        child's future, nurturing a lifelong love of learning
                        and exploration.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-2 academic_early_register_btn">
                  <div className="text-end register_now_btn">
                    <Link to={"/enter-student"}>
                      <button className="academics_button">
                        Register Now!
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Academic_early_slider slug={"early"}></Academic_early_slider>
      <Footer></Footer>
    </div>
  );
}

export default Academics_early;
