import { API_BASE_URL } from "../../config/constants";
import { postRequest, getRequest } from "../API";

/*********************************************************
* Function Name : careerList
* Description   : careerList
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const careerList = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}careers/list`,
            postData: options
        }
        const res = await postRequest(params);
        // console.log( "career list controller response from careers/list: ", res);
        
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : careerApply
* Description   : careerApply
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const careerApply = async (formData) => {
    try {
        const params = {
            url: `${API_BASE_URL}careers/apply`,
            postData: formData
        }
        // console.log(params, "params");
        
        const res = await postRequest(params);
        // console.log( "career apply controller response: ", res);
        
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end