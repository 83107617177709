import React, { useState, useEffect } from "react";
import Header from "../../../Header/header";
import Footer from "../../../footer/footer";
import Common_heading from "../Common Heading/common_heading";
import Registration_side_bar from "../registration_side_bar/registration_side_bar";
import {  useNavigate, Link } from "react-router-dom";
import { passportDetail ,registrationDetails} from "../../../../controllers/submissionsController/submissionsController";
import { notification } from "antd";
import {CheckCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";
function ForeignNationalsOnly() {
  const navigate = useNavigate();
  const [applicationId, setApplicationId] = useState("");
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    id: "",
    passport_number: null,
    place_of_issue: null,
    passport_exp_date: null,
    other_passports: null,
    visa_type: null,
    visa_issue_date: null,
    visa_place_of_issue: null,
    visa_exp_date: null,
    passport_type: null,
  });


/*********************************************************
 *  This function is used to validate dates in DD-MM-YYYY format.
 *********************************************************/
const validateDate = (date) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/; // Matches YYYY-MM-DD format
  if (!regex.test(date)) return false;

  const [year, month, day] = date.split("-").map(Number);
  const enteredDate = new Date(year, month - 1, day);
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize to start of the day

  return (
    enteredDate.getFullYear() === year &&
    enteredDate.getMonth() === month - 1 &&
    enteredDate.getDate() === day &&
    enteredDate <= today // ❌ Reject future dates
  );
};


  const validateField = () => {
    const newErrors = {};
  
    // All validations apply only if passport number is provided
    if (formData.passport_number) {
      // Passport Number Validation
      if (!/^[A-Za-z0-9]{6,9}$/.test(formData.passport_number)) {
        newErrors.passport_number =
          "Passport number must be 6-9 alphanumeric characters without spaces.";
      }
  
      // Place of Issue Validation
      if (formData.place_of_issue) {
        const trimmedPlaceOfIssue = formData.place_of_issue.trim();
        if (!/^[A-Za-z\s]+$/.test(trimmedPlaceOfIssue)) {
          newErrors.place_of_issue =
            "Place of issue must only contain alphabetic characters.";
        } else if (trimmedPlaceOfIssue.length < 3) {
          newErrors.place_of_issue = "Place of issue is too short.";
        } else if (trimmedPlaceOfIssue.length > 50) {
          newErrors.place_of_issue = "Place of issue is too long.";
        }
      } else {
        newErrors.place_of_issue = "Place of issue is required.";
      }
  
      // Passport Expiration Date Validation
      if (!formData.passport_exp_date) {
        newErrors.passport_exp_date = "Passport expiration date is required.";
      } else if (!validateDate(formData.passport_exp_date)) {
        newErrors.passport_exp_date = "Invalid passport expiration date.";
      }
  
      // Visa Type Validation
      if (formData.visa_type) {
        const trimmedVisaType = formData.visa_type.trim();
        if (!/^[A-Za-z\s]+$/.test(trimmedVisaType)) {
          newErrors.visa_type = "Visa type must only contain alphabetic characters.";
        } else if (trimmedVisaType.length < 3) {
          newErrors.visa_type = "Visa type is too short.";
        } else if (trimmedVisaType.length > 30) {
          newErrors.visa_type = "Visa type is too long.";
        }
      } else {
        newErrors.visa_type = "Visa type is required.";
      }
  
      // Visa Expiration Date Validation
      if (!formData.visa_exp_date) {
        newErrors.visa_exp_date = "Visa expiration date is required.";
      } else if (!validateDate(formData.visa_exp_date)) {
        newErrors.visa_exp_date = "Invalid visa expiration date.";
      }
  
      // Visa Issue Date Validation
      if (!formData.visa_issue_date) {
        newErrors.visa_issue_date = "Visa issue date is required.";
      } else if (!validateDate(formData.visa_issue_date,true)) {
        newErrors.visa_issue_date = "Invalid visa issue date.";
      }
  
      // Visa Place of Issue Validation
      if (formData.visa_place_of_issue) {
        const trimmedVisaPlaceOfIssue = formData.visa_place_of_issue.trim();
        if (!/^[A-Za-z\s]+$/.test(trimmedVisaPlaceOfIssue)) {
          newErrors.visa_place_of_issue =
            "Visa place of issue must only contain alphabetic characters.";
        } else if (trimmedVisaPlaceOfIssue.length < 3) {
          newErrors.visa_place_of_issue = "Visa place of issue is too short.";
        } else if (trimmedVisaPlaceOfIssue.length > 50) {
          newErrors.visa_place_of_issue = "Visa place of issue is too long.";
        }
      } else {
        newErrors.visa_place_of_issue = "Visa place of issue is required.";
      }
  
      // Passport Type Validation
      if (!formData.passport_type) {
        newErrors.passport_type = "Passport type is required.";
      }
    }
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  

  /*********************************************************
   *  Handle Input Change
   *********************************************************/
  const handleChange = (e) => {
    const { name } = e.target;
    let value = e.target.value; // Use let instead of const
    if (name === "visa_exp_date"||name === "passport_exp_date"||name === "visa_issue_date") {
     // Remove non-numeric characters
     value = value.replace(/\D/g, "");
  
     // Auto-format as YYYY-MM-DD
     if (value.length > 8) value = value.slice(0, 8); // Restrict to 8 digits
     if (value.length >= 5) value = value.slice(0, 4) + (value.length > 4 ? "-" : "") + value.slice(4);
     if (value.length >= 8) value = value.slice(0, 7) + (value.length > 7 ? "-" : "") + value.slice(7);
    }
    setFormData({
      ...formData,
      [name]: value || null,
    });
    setErrors({ ...errors, [name]: '' });
  };

  // /*********************************************************
  //  *  This function is use to handle change .
  //  *********************************************************/
  // const handleChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData({
  //     ...formData,
  //     [name]: value || null,
  //   });
  //   if (name.includes("date") && !validateDate(value)) {
  //     setErrors((prev) => ({
  //       ...prev,
  //       [name]: "Date format must be YYYY/MM/DD",
  //     }));
  //   } else {
  //     setErrors((prev) => {
  //       const newErrors = { ...prev };
  //       delete newErrors[name];
  //       return newErrors;
  //     });
  //   }
  // }; //end

  /*********************************************************
   *  This function is handle final submit .
   *********************************************************/
  /*********************************************************
 *  This function handles the final submit.
 *********************************************************/
// const handleSubmit = async () => {
//   try {
//     // Validate dates in the form data
//     const invalidDates = Object.keys(formData).filter(
//       (key) => key.includes("date") && !validateDate(formData[key])
//     );

//     if (invalidDates.length > 0) {
//       // Notify user about the invalid dates
//       notification.open({
//         message: "Oops!",
//         description: "Please correct the date formats.",
//         placement: "topRight",
//         icon: <InfoCircleOutlined style={{ color: "red" }} />,
//         duration: 2,
//       });
//       return; // Exit function if dates are invalid
//     }

//     // Proceed to submit passport details if dates are valid
//     const response = await passportDetail(formData);

//     if (response?.status === true) {
//       // Notify user about successful submission
//       notification.open({
//         message: "Submitted.",
//         description: "Details submitted successfully.",
//         placement: "topRight",
//         icon: <CheckCircleOutlined style={{ color: "green" }} />,
//         duration: 2,
//       });

//       // Navigate to the next route
//       window.history.pushState({}, "", "/previously-attended-details");
//       window.dispatchEvent(new Event("popstate"));
//     } else {
//       // Notify user about submission failure
//       notification.open({
//         message: "Oops!",
//         description: "Failed to submit details.",
//         placement: "topRight",
//         icon: <InfoCircleOutlined style={{ color: "red" }} />,
//         duration: 2,
//       });
//     }
//   } catch (error) {
//     console.error("Submission error:", error);
//   }
// };

 /*********************************************************
   *  Handle Form Submission
   *********************************************************/
 const handleSubmit = async () => {
  if (!validateField()) return;

  try {
    const response = await passportDetail(formData);
    if (response?.status === true) {
      notification.open({
        message: "Submitted.",
        description: "Details submitted successfully.",
        placement: "topRight",
        icon: <CheckCircleOutlined style={{ color: "green" }} />,
        duration: 2,
      });
      navigate("/home-details");
    } else {
      notification.open({
        message: "Oops!",
        description: "Failed to submit details.",
        placement: "topRight",
        icon: <InfoCircleOutlined style={{ color: "red" }} />,
        duration: 2,
      });
    }
  } catch (error) {
    console.error("Submission error:", error);
  }
};
/*********************************************************
 * Fetch and decode applicationId from localStorage.
 *********************************************************/
useEffect(() => {
  const fetchApplicationDetails = async () => {
      const encodedId = localStorage.getItem('applicationId');
      if (!encodedId) {
          console.error('Encoded applicationId not found in localStorage');
          navigate('/enter-student');
          return;
      }
      try {
          const decodedId = atob(encodedId);
          setApplicationId(decodedId);
          const response = await registrationDetails({ id: decodedId });
          if (response?.status === true) {
              const prevData = response.result;
              setFormData({
                id: decodedId, // Use the decodedId directly
                passport_number: prevData.passport_number || null,
                place_of_issue: prevData.place_of_issue || null,
                passport_exp_date: prevData.passport_exp_date || null,
                other_passports: prevData.other_passports || null,
                visa_type: prevData.visa_type || null,
                visa_issue_date: prevData.visa_issue_date || null,
                visa_place_of_issue: prevData.visa_place_of_issue || null,
                visa_exp_date: prevData.visa_exp_date || null,
                passport_type: prevData.passport_type || null,
              });
          }
      } catch (error) {
          console.error('Error fetching registration details:', error);
          navigate('/enter-student');
      }
  };

  fetchApplicationDetails();
}, [navigate]);


  return (
    <div>
      <Header />
      <div className="inner_mainbanner">
        <Common_heading />
      </div>
      <div className="exam_gapping_div">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <Registration_side_bar />
            </div>
            <div className="col-md-9">
              <form>
                <div className="form_heading_container mb-3">
                  <h5 className="heading-text">
                    To be filled by Foreign Nationals only
                  </h5>
                </div>
                <div>
                  <div className="row g-3">
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="text"
                        value={formData.passport_number || ""}
                        className="form-control"
                        name="passport_number"
                        placeholder="Passport Number of the Child"
                        onChange={handleChange}
                      />
                      {errors.passport_number && <small className="text-danger">{errors.passport_number}</small>}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="text"
                        className="form-control"
                        name="place_of_issue"
                        value={formData.place_of_issue || ""}
                        placeholder="Place of Issue of Passport"
                        onChange={handleChange}
                      />
                      {errors.place_of_issue && <small className="text-danger">{errors.place_of_issue}</small>}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="text"
                        className="form-control"
                        name="passport_exp_date"
                        value={formData.passport_exp_date || ""}
                        placeholder="Expiration Date of Passport (YYYY-MM-DD)"
                        onChange={handleChange}
                      />
                      {errors.passport_exp_date && (
                        <small className="text-danger">
                          {errors.passport_exp_date}
                        </small>
                      )}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="text"
                        className="form-control"
                        name="other_passports"
                        value={formData.other_passports || ""}
                        placeholder="Other Passports (Specify)"
                        onChange={handleChange}
                      />
                      {errors.other_passports && <small className="text-danger">{errors.other_passports}</small>}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="text"
                        className="form-control"
                        name="visa_type"
                        value={formData.visa_type || ""}
                        placeholder="Type of Visa for the Child"
                        onChange={handleChange}
                      />
                      {errors.visa_type && <small className="text-danger">{errors.visa_type}</small>}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="text"
                        className="form-control"
                        name="visa_issue_date"
                        value={formData.visa_issue_date || ""}
                        placeholder="Issue Date of Visa (YYYY-MM-DD)"
                        onChange={handleChange}
                      />
                      {errors.visa_issue_date && (
                        <small className="text-danger">
                          {errors.visa_issue_date}
                        </small>
                      )}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="text"
                        className="form-control"
                        name="visa_place_of_issue"
                        value={formData.visa_place_of_issue || ""}
                        placeholder="Place of Issue of Visa"
                        onChange={handleChange}
                      />
                      {errors.visa_place_of_issue && <small className="text-danger">{errors.visa_place_of_issue}</small>}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="text"
                        className="form-control"
                        name="visa_exp_date"
                        value={formData.visa_exp_date || ""}
                        placeholder="Expiration Date of Visa (YYYY-MM-DD)"
                        onChange={handleChange}
                      />
                      {errors.visa_exp_date && (
                        <small className="text-danger">
                          {errors.visa_exp_date}
                        </small>
                      )}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <select
                        className="form-select"
                        name="passport_type"
                        value={formData.passport_type || ""}
                        onChange={handleChange}
                      >
                        <option value="">Type of Passport</option>
                        <option value="regular">Regular</option>
                        <option value="diplomatic">Diplomatic</option>
                        <option value="official">Official</option>
                      </select>
                      {errors.passport_type && <small className="text-danger">{errors.passport_type}</small>}
                    </div>
                  </div>
                </div>
                <div className="form_subbmit_btn">
                 
                      <button type="button" onClick={handleSubmit}>
                        Proceed to Continue
                      </button>
                    
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ForeignNationalsOnly;
