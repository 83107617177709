// ScrollIndicator.js
import React, { useEffect, useState } from 'react';
import './scrool_indactor.css'; // Import the CSS styles

const ScrollIndicator = () => {
  const [scrollPercent, setScrollPercent] = useState(0);

  const updateScrollIndicator = () => {
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    const percent = (scrollTop / scrollHeight) * 100;
    setScrollPercent(percent);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScrollIndicator);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', updateScrollIndicator);
    };
  }, []);

  return (
    <div
      className="scroll-indicator"
      style={{ width: `${scrollPercent}%` }}
    />
  );
};

export default ScrollIndicator;
