import React, { useEffect } from "react";
import Calendar_slider from "./calendar_slider";
import Header from "../Header/header";
import Footer from "../footer/footer";

function Calendar() {
  const calendarDays = Array(4)
    .fill(null)
    .concat(Array.from({ length: 30 }, (_, i) => i + 1));
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <div className="policies_gapping_div">
        {/* <Calendar_slider></Calendar_slider> */}
        <div className="container-fluid">
          <div className="row calander_row">
            <div className=" col-md-12 ">
              <div className="text-center calendar_school_text">
                <h2>
                  School <span>Calendar {new Date().getFullYear()-1}-{new Date().getFullYear() }</span>
                </h2>
                <p>
                Explore the Academic Year, Fully Organized and Accessible.
                </p>
              </div>
            </div>

            {/* Calendar Column */}
            <div className="col-12 calnder_col">
              <div className="calendar-container p-3">
                {/* Google Calendar Embed */}
                <div
                  className="clader_tg"
                  style={{ overflow: "hidden", padding: "20px" }}
                >
                  <iframe
                    src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=Asia%2FKolkata&showTitle=0&title=KCH%202022-23&src=a2NoaWdob2ZmaWNpYWxAZ21haWwuY29t&color=%23039BE5"
                    style={{ border: 0 }}
                    width="100%"
                    height="600"
                    frameBorder="0"
                    scrolling="no"
                    title="Google Calendar"
                  ></iframe>
                </div>

                {/* Top Heading */}
                {/* <div className="d-flex justify-content-between mb-3 calendar_week">
                  {["Mon", "Tue", "Wed", "Thr", "Fri", "Sat", "Sun"].map((day, index) => (
                    <div key={index} className="text-center calendar_day-header">
                      {day}
                    </div>
                  ))}
                </div> */}

                {/* Calendar Days */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Calendar;
