import { useState, useEffect, useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";


const CareerOptionSidebar = ({ onFiltersChange = () => { } }) => {
  const [activeKey, setActiveKey] = useState("0");
  const [selectedFilters, setSelectedFilters] = useState({}); // Store selected options by category
  const categoryKeyMap = {
    "School Levels": "school_level",
    Campus: "campus",
    Positions: "position",
    Subjects: "subject",
    Experience: "experience",
  };
  const handleDropdownChange = (category, event) => {
    const value = event.target.value;

    setSelectedFilters((prev) => {
      const updated = { ...prev };
      const mappedCategory = categoryKeyMap[category] || category;

      if (value === "default") {
        // If default is selected, clear the filter for this category
        delete updated[mappedCategory];
      } else {
        // Set the selected option as a single-element array
        updated[mappedCategory] = [value];
      }

      return updated;
    });
  };

  useEffect(() => {
    // Filter and map to lowercase
    const filteredData = Object.fromEntries(
      Object.entries(selectedFilters)
        .filter(([_, values]) => values.length > 0) // Only keep non-empty arrays
        .map(([key, values]) => [
          key.toLowerCase(),
          values.map((v) => v.toLowerCase()),
        ]) // Convert keys and values to lowercase
    );

    onFiltersChange(filteredData); // Send only the filtered key-value pairs
  }, [selectedFilters]);

  const handleAccordionToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const handleFilterClick = (category, option) => {
    const mappedCategory = categoryKeyMap[category] || category;
    const lowercasedOption = option.toLowerCase(); // Convert option to lowercase

    setSelectedFilters((prev) => {
      const updated = { ...prev };
      updated[mappedCategory] = updated[mappedCategory] || [];

      if (updated[mappedCategory].includes(lowercasedOption)) {
        // Remove the option if it already exists
        updated[mappedCategory] = updated[mappedCategory].filter(
          (item) => item !== lowercasedOption
        );
        if (updated[mappedCategory].length === 0)
          delete updated[mappedCategory];
      } else {
        // Add the option if it doesn't exist
        updated[mappedCategory].push(lowercasedOption);
      }

      return updated;
    });
  };

  const clearFilters = () => {
    setSelectedFilters({});
  };

  const isSelected = (category, option) => {
    const mappedCategory = categoryKeyMap[category] || category;
    return selectedFilters[mappedCategory]?.includes(option.toLowerCase());
  };






  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="text-center career_filter_text filter_btn_hide_in_mobile_view">
        <h4>Filters</h4>
      </div>
      <Accordion
        activeKey={activeKey}
        onSelect={handleAccordionToggle}
        className="accordian_body_main_div campus_filter_for_mobile"
      >
        {/* Campus Dropdown */}
        <Accordion.Item eventKey="0" className="side_bar_item">
          <Accordion.Header className="career_side_bar_div">
            <span>Campus</span>
            <span
              className="side_bar_plus_minus"
              style={{ marginLeft: "auto", fontSize: "18px" }}
            >
              {activeKey === "0" ? <FiChevronUp /> : <FiChevronDown />}
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="services_names">
              {["OP-Campus", "RR-Campus"].map((option) => (
                <li
                  key={option}
                  className={`side_bar_item_fix ${isSelected("Campus", option) ? "active" : ""
                    }`}
                  onClick={() => handleFilterClick("Campus", option)}
                >
                  {option}
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        {/* School Levels Dropdown */}
        <Accordion.Item eventKey="1" className="side_bar_item">
          <Accordion.Header className="career_side_bar_div">
            <span>School Levels</span>
            <span
              className="side_bar_plus_minus"
              style={{ marginLeft: "auto", fontSize: "18px" }}
            >
              {activeKey === "1" ? <FiChevronUp /> : <FiChevronDown />}
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <ul className="services_names">
              {["Early", "Primary", "Middle", "Senior"].map((option) => (
                <li
                  key={option}
                  className={`side_bar_item_fix ${isSelected("School Levels", option) ? "active" : ""
                    }`}
                  onClick={() => handleFilterClick("School Levels", option)}
                >
                  {option}
                </li>
              ))}
            </ul>
          </Accordion.Body>
        </Accordion.Item>

        {/* Other Dropdowns (Positions, Subjects, Experience) */}
        {[
          {
            category: "Positions",
            options: ["Junior", "Senior", "Administrator"],
          },
          {
            category: "Subjects",
            options: ["English", "Science", "Hindi", "Mathematics"],
          },
          {
            category: "Experience",
            options: ["0-1 years", "1-3 years", "3-5 years", "5-10 years"],
          },
        ].map(({ category, options }, index) => (
          <Accordion.Item
            eventKey={`${index + 2}`}
            className="side_bar_item"
            key={category}
          >
            <Accordion.Header className="career_side_bar_div">
              <span>{category}</span>
              <span
                className="side_bar_plus_minus"
                style={{ marginLeft: "auto", fontSize: "18px" }}
              >
                {activeKey === `${index + 2}` ? (
                  <FiChevronUp />
                ) : (
                  <FiChevronDown />
                )}
              </span>
            </Accordion.Header>
            <Accordion.Body>
              <ul className="services_names">
                {options.map((option) => (
                  <li
                    key={option}
                    className={`side_bar_item_fix ${isSelected(category, option) ? "active" : ""
                      }`}
                    onClick={() => handleFilterClick(category, option)}
                  >
                    {option}
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        ))}

        {/* Clear Filters Button */}
        {Object.keys(selectedFilters).length > 0 && (
          <div className="form_subbmit_btn career_option_filter_btn ">
            <button type="button" onClick={clearFilters}>
              Clear Filters
            </button>
          </div>
        )}
      </Accordion>

      {/* ////////////// campus select for mobile view */}
      
      <div className="text-start career_filter_text mob_hide">
        {/* Header Section */}
        <h4 className="mobile_filter_collapse" onClick={toggleAccordion} style={{ cursor: "pointer" }}>
          Filters {isOpen ? <FiChevronUp /> : <FiChevronDown />}
        </h4>

        {/* Accordion Content */}
        <div
          className="accordion-content filer_accordian_content"
          ref={contentRef}
          style={{
            maxHeight: isOpen ? `${contentRef.current.scrollHeight}px` : "0px",
            overflow: "hidden",
            transition: "max-height 0.3s ease",
          }}
        >
          <div className="mt-3">
            <div className="select_for_career">
              <select
                className="form-select"
                aria-label="Select Campus"
                onChange={(e) => handleDropdownChange("Campus", e)}
                value={selectedFilters.campus ? selectedFilters.campus[0] : "default"}
              >
                <option value="default" disabled>
                  Campus
                </option>
                <option value="og-campus">OP-Campus</option>
                <option value="or-campus">RR-Campus</option>
              </select>

              <select
                className="form-select"
                aria-label="Select School Levels"
                onChange={(e) => handleDropdownChange("School Levels", e)}
                value={
                  selectedFilters.school_level
                    ? selectedFilters.school_level[0]
                    : "default"
                }
              >
                <option value="default" disabled>
                  School Levels
                </option>
                <option value="early">Early</option>
                <option value="primary">Primary</option>
                <option value="middle">Middle</option>
                <option value="senior">Senior</option>
              </select>

              <select
                className="form-select"
                aria-label="Select Positions"
                onChange={(e) => handleDropdownChange("Positions", e)}
                value={
                  selectedFilters.position ? selectedFilters.position[0] : "default"
                }
              >
                <option value="default" disabled>
                  Positions
                </option>
                <option value="junior">Junior</option>
                <option value="senior">Senior</option>
                <option value="administrator">Administrator</option>
              </select>

              <select
                className="form-select"
                aria-label="Select Subjects"
                onChange={(e) => handleDropdownChange("Subjects", e)}
                value={
                  selectedFilters.subject ? selectedFilters.subject[0] : "default"
                }
              >
                <option value="default" disabled>
                  Subjects
                </option>
                <option value="english">English</option>
                <option value="science">Science</option>
                <option value="hindi">Hindi</option>
                <option value="mathematics">Mathematics</option>
              </select>

              <select
                className="form-select"
                aria-label="Select Experience"
                onChange={(e) => handleDropdownChange("Experience", e)}
                value={
                  selectedFilters.experience
                    ? selectedFilters.experience[0]
                    : "default"
                }
              >
                <option value="default" disabled>
                  Experience
                </option>
                <option value="0-1 years">0-1 years</option>
                <option value="1-3 years">1-3 years</option>
                <option value="3-5 years">3-5 years</option>
                <option value="5-10 years">5-10 years</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {/* Clear Filters Button */}
      {Object.keys(selectedFilters).length > 0 && (
        <div className="form_subbmit_btn career_option_filter_btn clear_filter_btn">
          <button type="button" onClick={clearFilters}>
            Clear Filters
          </button>
        </div>
      )}
    </>
  );
};

export default CareerOptionSidebar;
