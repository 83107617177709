import React, { useEffect, useState } from 'react';
import Header from '../../../Header/header';
import Footer from '../../../footer/footer';
import Common_heading from '../Common Heading/common_heading';
import Registration_side_bar from '../registration_side_bar/registration_side_bar';
import { motherDetail, registrationDetails } from '../../../../controllers/submissionsController/submissionsController';
import { useNavigate } from 'react-router-dom';
import { notification } from "antd";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
function Detailsofmother() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        id: '',
        mother_name: '',
        mother_occupation: '',
        mother_company_name: '',
        mother_work_address: '',
        mother_city: '',
        mother_state: '',
        mother_pin_code: '',
        mother_country_code: '',
        mother_phone: '',
        mother_email: '',
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchApplicationDetails = async () => {
            const encodedId = localStorage.getItem('applicationId');
            if (!encodedId) {
                console.error('Encoded applicationId not found in localStorage');
                navigate('/enter-student');
                return;
            }
            try {
                const decodedId = atob(encodedId);
                const response = await registrationDetails({ id: decodedId });
                if (response?.status === true) {
                    const prevData = response.result;
                    setFormData({
                        id: decodedId || '',
                        mother_name: prevData.mother_name || '',
                        mother_occupation: prevData.mother_occupation || '',
                        mother_company_name:prevData.mother_company_name || '',
                        mother_work_address: prevData.mother_work_address || '',
                        mother_city: prevData.mother_city || '',
                        mother_state: prevData.mother_state || '',
                        mother_pin_code: prevData.mother_pin_code || '',
                        mother_country_code: prevData.mother_country_code || '',
                        mother_phone: prevData.mother_phone || '',
                        mother_email: prevData.mother_email || '',
                    });
                }
            } catch (error) {
                console.error('Error fetching registration details:', error);
                navigate('/enter-student');
            }
        };

        fetchApplicationDetails();
    }, [navigate]);

    const validateForm = () => {
        const newErrors = {};

        // Validate mother's name
        if (!formData.mother_name.trim()) {
            newErrors.mother_name = 'Mother name is required.';
        } else {
            // Check for numbers
            if (/\d/.test(formData.mother_name)) {
                newErrors.mother_name = "Name cannot contain numbers.";
            }
            // Check for special characters (except apostrophes and hyphens)
            if (/[^a-zA-Z\s'-]/.test(formData.mother_name)) {
                newErrors.mother_name = "Name can only contain alphabets, apostrophes, and hyphens.";
            }
            // Check minimum character limit (e.g., minimum 2 characters)
            if (formData.mother_name.trim().length < 2) {
                newErrors.mother_name = "Name must be at least 2 characters long.";
            }
            if (formData.mother_name.trim().length > 30) {
                newErrors.mother_name = "Name cannot exceed 30 characters.";
            }
        }


        // Validate mother's occupation
        if (!formData.mother_occupation.trim()) {
            newErrors.mother_occupation = 'Occupation is required.';
        }

        // Validate work address
        // if (!formData.mother_work_address.trim()) {
        //     newErrors.mother_work_address = 'Work address is required.';
        // }

        // // Validate city
        // if (!formData.mother_city.trim() || formData.mother_city === 'City *') {
        //     newErrors.mother_city = 'City is required.';
        // }

        // // Validate state
        // if (!formData.mother_state.trim() || formData.mother_state === 'State *') {
        //     newErrors.mother_state = 'State is required.';
        // }

        // // Validate pin code
        // if (!formData.mother_pin_code.trim()) {
        //     newErrors.mother_pin_code = 'Pin code is required.';
        // } else if (!/^\d{6}$/.test(formData.mother_pin_code)) {
        //     newErrors.mother_pin_code = 'Pin code must be exactly 6 digits.';
        // }

        // Validate country code
        if (!formData.mother_country_code.trim()) {
            newErrors.mother_country_code = 'Country code is required.';
        } else if (!/^\d{1,3}$/.test(formData.mother_country_code)) {
            newErrors.mother_country_code = 'Country code must be 1-3 digits.';
        }

        // // Validate phone number
        // if (!formData.mother_phone.trim()) {
        //     newErrors.mother_phone = 'Phone number is required.';
        // } // Validate phone number
        if (!formData.mother_phone || !formData.mother_phone.trim()) {
            newErrors.mother_phone = "Phone number is required.";
        } 
        // else {
        //     // Check for exactly 10 digits
        //     const phoneRegex = /^\d{10}$/;
        //     if (!phoneRegex.test(formData.mother_phone)) {
        //         newErrors.mother_phone = "Phone number must be exactly 10 digits.";
        //     }
        //     // Check if phone number starts with zero
        //     if (/^0/.test(formData.mother_phone)) {
        //         newErrors.mother_phone = "Phone number cannot start with zero.";
        //     }
        // }

        // Validate email
        // if (!formData.mother_email.trim()) {
        //     newErrors.mother_email = 'Email address is required.';
        // } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.mother_email)) {
        //     newErrors.mother_email = 'Invalid email address format.';
        // }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: '' });
        // validateForm(name, value);
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        const rawBody = { ...formData };

        try {
            const response = await motherDetail(rawBody);
            if (response?.status === true) {
                // alert('Details submitted successfully.');
                notification.open({
                    message: "Submitted.",
                    description: `Details submitted successfully.`,
                    placement: "topRight",
                    icon: <CheckCircleOutlined style={{ color: "green" }} />,
                    duration: 2,
                });
                // navigate('/program-for-admission');
                window.history.pushState({}, '', '/details-of-siblings');
                window.dispatchEvent(new Event('popstate'));
            } else {
                // alert('Failed to submit details.');
                notification.open({
                    message: "Opps!",
                    description: `${"Failed to submit details"}`,
                    placement: "topRight",
                    icon: <InfoCircleOutlined style={{ color: "red" }} />,
                    duration: 2,
                });
            }
        } catch (error) {
            console.error('Submission error:', error);
            alert('An error occurred while submitting the details.');
        }
    };

    return (
        <div>
            <Header />
            <div className="inner_mainbanner">
                <Common_heading />
            </div>
            <div className="exam_gapping_div">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <Registration_side_bar />
                        </div>
                        <div className="col-md-9">
                            <form>
                                <div className="form_heading_container mb-3 ">
                                    <h5 className="heading-text">Details of Mother/Guardian</h5>
                                </div>
                                <div>
                                    <div className="row g-3">
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="mother_name"
                                                value={formData.mother_name}
                                                onChange={handleInputChange}
                                                placeholder="Name of Mother/Guardian *"
                                            />
                                            {errors.mother_name && <p className="error-text">{errors.mother_name}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="mother_occupation"
                                                value={formData.mother_occupation}
                                                onChange={handleInputChange}
                                                placeholder="Occupation *"
                                            />
                                            {errors.mother_occupation && <p className="error-text">{errors.mother_occupation}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="mother_company_name"
                                                value={formData.mother_company_name}
                                                onChange={handleInputChange}
                                                placeholder="Company Name"
                                            />
                                            {errors.mother_company_name && <p className="error-text">{errors.mother_company_name}</p>}
                                        </div>
                                    </div>

                                    <div className="row g-3 guardian_details">
                                        <div className="col-md-12 regestration_form_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="mother_work_address"
                                                value={formData.mother_work_address}
                                                onChange={handleInputChange}
                                                placeholder="Office address "
                                            />
                                            {errors.mother_work_address && <p className="error-text">{errors.mother_work_address}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <select className="form-select" name="mother_state" onChange={handleInputChange} value={formData.mother_state}>
                                                <option value="">Select State/UT</option>
                                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                <option value="Assam">Assam</option>
                                                <option value="Bihar">Bihar</option>
                                                <option value="Chhattisgarh">Chhattisgarh</option>
                                                <option value="Goa">Goa</option>
                                                <option value="Gujarat">Gujarat</option>
                                                <option value="Haryana">Haryana</option>
                                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                <option value="Jharkhand">Jharkhand</option>
                                                <option value="Karnataka">Karnataka</option>
                                                <option value="Kerala">Kerala</option>
                                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                <option value="Maharashtra">Maharashtra</option>
                                                <option value="Manipur">Manipur</option>
                                                <option value="Meghalaya">Meghalaya</option>
                                                <option value="Mizoram">Mizoram</option>
                                                <option value="Nagaland">Nagaland</option>
                                                <option value="Odisha">Odisha</option>
                                                <option value="Punjab">Punjab</option>
                                                <option value="Rajasthan">Rajasthan</option>
                                                <option value="Sikkim">Sikkim</option>
                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                <option value="Telangana">Telangana</option>
                                                <option value="Tripura">Tripura</option>
                                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                <option value="Uttarakhand">Uttarakhand</option>
                                                <option value="West Bengal">West Bengal</option>
                                                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                <option value="Chandigarh">Chandigarh</option>
                                                <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                                                <option value="Lakshadweep">Lakshadweep</option>
                                                <option value="Delhi">Delhi</option>
                                                <option value="Puducherry">Puducherry</option>
                                                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                <option value="Ladakh">Ladakh</option>
                                            </select>
                                            {errors.mother_state && <p className="error-text">{errors.mother_state}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            {/* <select className="form-select" name="mother_city" onChange={handleInputChange} value={formData.mother_city}>
                                                <option>City *</option>
                                                <option>GZB</option>
                                                <option>LKO</option>
                                                <option>GKP</option>
                                            </select>
                                            {errors.mother_city && <p className="error-text">{errors.mother_city}</p>} */}
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="mother_city"
                                                value={formData.mother_city}
                                                onChange={handleInputChange}
                                                placeholder="City"
                                            />
                                            {errors.mother_city && (
                                                <div className="text-danger">{errors.mother_city}</div>
                                            )}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Pin code"

                                                name="mother_pin_code"
                                                value={formData.mother_pin_code}
                                                onChange={handleInputChange}

                                            />
                                            {errors.mother_pin_code && <p className="error-text">{errors.mother_pin_code}</p>}
                                        </div>
                                        

                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Country Code "

                                                name="mother_country_code"
                                                value={formData.mother_country_code}
                                                onChange={handleInputChange}


                                            />
                                            {errors.mother_country_code && <p className="error-text">{errors.mother_country_code}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Phone Number *"
                                                name="mother_phone"
                                                value={formData.mother_phone}
                                                onChange={handleInputChange}

                                            />
                                            {errors.mother_phone && <p className="error-text">{errors.mother_phone}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Email  Address "
                                                name="mother_email"
                                                value={formData.mother_email}
                                                onChange={handleInputChange}

                                            />
                                            {errors.mother_email && <p className="error-text">{errors.mother_email}</p>}
                                        </div>
                                        {/* Add similar input fields for city, state, pin code, country code, phone, email */}
                                    </div>
                                    {/* <p className='regestration_note'>Note  for Phone: Country Code + Area Code (for landlines only) Example: +91 or +91 (44)</p> */}
                                    <div className="form_subbmit_btn mt-4">
                                        <button type="button" onClick={handleSubmit}>
                                            Proceed to Continue
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Detailsofmother;
