import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FRONT_ASSETS_URL } from "../../../config/constants";
import { useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

function Academic_curriculum({ academicsSenior }) {
  const [activeTab, setActiveTab] = useState("IGCSE");

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const curriculumsData = academicsSenior || [];

  const [activeTabOne, setActiveTabOne] = useState(null);
  useEffect(() => {
    // Automatically set the first group as the active group tab when switching curriculum
    const currentCurriculum = curriculumsData.find(
      (curriculum) => curriculum.board === activeTab
    );
    if (currentCurriculum && currentCurriculum.group.length > 0) {
      setActiveTabOne(currentCurriculum.group[0].name);
    }
  }, [activeTab, curriculumsData]);





  const scrollContainerRef = useRef(null);

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -150, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 150, behavior: "smooth" });
    }
  };
  return (
    <div className="all_fluid_main_class section_gapping">
      <div className="container-fluid">
        <div className="text-center home_school_heading">
          <h2>
            Curriculum <span className="highlight">Offered</span>
          </h2>
          <p>
            Providing the IGCSE and International Baccalaureate Diploma Program
            (IBDP)
          </p>
        </div>

        <div className="d-flex flex-column align-items-center my-4">
          <div className="curriculum_tab_container rounded-pill">
            {curriculumsData.map((curriculum, index) => (
              <button
                key={index}
                className={`curriculum_tab_button ${activeTab === curriculum.board ? "active" : ""
                  }`}
                onClick={() => handleTabClick(curriculum.board)}
              >
                {curriculum.board}
              </button>
            ))}
          </div>
        </div>

        <div className="tab-content p-4 tabs_all_content">
          {curriculumsData.map(
            (curriculum, index) =>
              activeTab === curriculum.board && (
                <div className="row" key={index}>
                  <div className="col-4">
                    <Slider {...sliderSettings} className="curriculum_slider">
                      {(curriculum.image.length === 1
                        ? [...curriculum.image, ...curriculum.image, ...curriculum.image]
                        : curriculum.image
                      ).map((imgPath, imgIndex) => (
                        <div key={imgIndex}>
                          <img
                            src={`${FRONT_ASSETS_URL}${imgPath}`}
                            alt={`${curriculum.board} Slide ${imgIndex + 1}`}
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                  <div className="col-8">
                    <div className="text-start igssc_text">
                      <h2>{curriculum.board}</h2>
                      <h6>Subjects offered under various groups</h6>
                      <p>{curriculum.content}</p>
                    </div>

                    <div className="curriculum_all_tabs">
                      {/* Tab Navigation */}
                      {/* <ul
                        className="nav custom-nav curriculum_custom_nav"
                        role="tablist"
                      >
                        {curriculum.group.map((group, groupIndex) => (
                          <React.Fragment key={groupIndex}>
                            <li className="nav-item">
                              <button
                                className={`nav-link ${
                                  activeTabOne === group.name ? "active" : ""
                                }`}
                                onClick={() => setActiveTabOne(group.name)}
                              >
                                {group.name}
                              </button>
                            </li>
                            {groupIndex < curriculum.group.length - 1 && (
                              <li className="separator">|</li>
                            )}
                          </React.Fragment>
                        ))}
                      </ul> */}
                      <div className="scroll-container scrool_container_for_curriculum">
                        <button className="scroll-btn left left_btn" onClick={scrollLeft}>
                          <FaChevronLeft />
                        </button>
                        <ul ref={scrollContainerRef} className="nav custom-nav curriculum_custom_nav" role="tablist">
                          {curriculum.group.map((group, groupIndex) => (
                            <React.Fragment key={groupIndex}>
                              <li className="nav-item">
                                <button
                                  className={`nav-link ${activeTabOne === group.name ? "active" : ""}`}
                                  onClick={() => setActiveTabOne(group.name)}
                                >
                                  {group.name}
                                </button>
                              </li>
                              {groupIndex < curriculum.group.length - 1 && <li className="separator">|</li>}
                            </React.Fragment>
                          ))}
                        </ul>
                        <button className="scroll-btn right right_btn" onClick={scrollRight}>
                          <FaChevronRight />
                        </button>
                      </div>
                      {/* Tab Content */}
                      <div className="tab-content">
                        {curriculum.group.map(
                          (group, groupIndex) =>
                            activeTabOne === group.name && (
                              <div key={groupIndex}>
                                <div className="d-flex flex-wrap text-start curriculum_all_card">
                                  {group.items.map((item, itemIndex) => (
                                    <div
                                      key={itemIndex}
                                      className={`card curriculum_ccard`}
                                    >
                                      <div className="card-body text-center curriculum_title_name">
                                        <h5 className="card-title">
                                          {item.subject}
                                        </h5>
                                        <p className="card-text">
                                          {item.details}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </div>
  );
}

export default Academic_curriculum;
