import React, { useEffect } from 'react'
import Header from '../../Header/header'
import Footer from '../../footer/footer'
import Career_option_sidebar from '../career_option_sidebar/career_option_sidebar'
import Career_option_banner from '../career_option_banner/career_option_banner'

function Career_op_campus() {
      // Scroll to the top of the page when the component mounts
useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
    return (
        <div>
            <Header />
            <div className="inner_mainbanner">
                <Career_option_banner></Career_option_banner>
            </div>
            <div className='exam_gapping_div'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <Career_option_sidebar></Career_option_sidebar>
                        </div>
                        <div className=" col-md-9 ">
                            career op campus
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Career_op_campus