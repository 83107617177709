import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Footer from "../footer/footer";
import Home_slider from "./home_slider";
import Header from "../Header/header";
import founder_logo from "../images/home_founder_logo.png";
import { MdLocationOn } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { IoMdMail } from "react-icons/io";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { Collapse } from "react-collapse";
import Event_slider from "./event_slider";
import Diffrent_way_to_live from "./diffrent_way_to_live";
import Kc_album_section from "./kc_album_section";
import Accrediation_slider from "./accrediation_slider";
import { Link, useNavigate } from "react-router-dom";
import {
  founderDetails,
  list,
} from "../../controllers/commonController/cmsController";
import { fetchOurCampusData } from "../../features/tasks/ourCampusSlice";
import { FRONT_ASSETS_URL } from "../../config/constants";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { ourCampuses } = useSelector((state) => state.ourCampusReducer);
  // Fallback to an empty object if data is unavailable
  const campuses = ourCampuses?.[0] || {};

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [cmsContentList, setcmsContentList] = useState([]);
  const [cmsQuickConnect, setcmsQuickConnect] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const { generalDatas } = useSelector((state) => state.footerReducer);
  const generalData = generalDatas?.[0] || {};

  //  const generalData = generalDatas?.[0] || {};
  // const [isLoading, setIsLoading] = useState(false);
  const [founderData, setFounderData] = useState({});

  const cmsfounderData = async () => {
    setFounderData({});
    setIsLoading(true);
    try {
      const listData = await founderDetails();
      if (listData?.status === true) {
        setFounderData(listData?.result);
        // console.log(listData?.result, "founderdata");
      }
    } catch (error) {
      console.log("Failed to fetch founder data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  /*********************************************************
   *  This function is use to fetch cms content list
   *********************************************************/
  const cmsContent = async () => {
    setcmsContentList([]);
    setIsLoading(true);
    try {
      const option = {
        type: "",
        condition: {
          status: "A",
          type: "Features",
        },
      };
      const listData = await list(option);
      if (listData?.status === true) {
        setcmsContentList(listData?.result);
      }

      const option2 = {
        type: "",
        condition: {
          status: "A",
          type: "Qconnect",
        },
      };
      const listData2 = await list(option2);
      if (listData2?.status === true) {
        setcmsQuickConnect(listData2?.result);
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      setIsLoading(false);
    }
  }; //end

  useEffect(() => {
    cmsContent();
    cmsfounderData();
  }, []);
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (ourCampuses.length === 0) {
      dispatch(fetchOurCampusData());
    }

    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ourCampuses]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the page loads
  }, [navigate]);


  /////////// for safari
  useEffect(() => {
    // Detect Safari (exclude Chrome by checking userAgent)
    const userAgent = navigator.userAgent;
    if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
      // Add 'safari' class to the body element
      document.body.classList.add('our_campus_blue');
    }
  }, []);

  const getViewableLink = (url) => {
    return url.replace("dl.dropbox.com", "www.dropbox.com") + "?raw=1";
  };

  return (
    <>
      <Header></Header>
      <div>
        <Home_slider></Home_slider>
      </div>
      <div className="container-fluid">
        <div className="section_gapping all_fluid_main_class_next a_school">
          {/* Top Title Section */}
          <div className="text-center home_school_heading">
            <h2>
              A School <span className="highlight">Like No Other</span>
            </h2>
            <p>
              Our mission at KC High International School is to provide a joyful
              and challenging learning environment that caters to a diverse
              <br />
              student population and fosters a connection with nature and
              culture, through a team of caring and passionate facilitators.
            </p>
          </div>

          {/* Numbered Boxes Section */}
          {isMobileView ? (
            <ul className="home_school_boxes">
              {cmsContentList.map((item, index) => (
                <li key={item._id}>
                  <div
                    className={`home_school_info_box ${index === 0 ? "active" : ""
                      }`}
                  >
                    <div
                      className={`home_number_main_section ${index === 0 ? "active" : ""
                        }`}
                      onClick={() => {
                        if (index === 0) setIsOpen1(!isOpen1);
                        if (index === 1) setIsOpen2(!isOpen2);
                        if (index === 2) setIsOpen3(!isOpen3);
                      }}
                    >
                      <div className="home_school_no_div">
                        <div className="number">{`0${index + 1}`}</div>
                        <h4>{item.title}</h4>
                      </div>
                      {(index === 0 && isOpen1) ||
                        (index === 1 && isOpen2) ||
                        (index === 2 && isOpen3) ? (
                        <FiChevronUp />
                      ) : (
                        <FiChevronDown />
                      )}
                    </div>
                    <Collapse
                      isOpened={
                        (index === 0 && isOpen1) ||
                        (index === 1 && isOpen2) ||
                        (index === 2 && isOpen3)
                      }
                    >
                      <p>{item.content}</p>
                    </Collapse>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <ul className="home_school_boxes">
              {cmsContentList.map((item, index) => (
                <li key={item._id}>
                  <div
                    className={`home_school_info_box  ${index === 0 ? "active" : ""
                      }`}
                  >
                    <div className="number">{`0${index + 1}`}</div>
                    <h4>{item.title}</h4>
                    <p>{item.content}</p>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
      {/* ///////// founder message section here */}
      <div className="home_founder_section">
        <div className="container-fluid all_fluid_main_class">
          <div className="row align-items-center home_founder_message_row">
            {isMobileView ? (
              <>
                <div className="col-8 col-md-8 d-flex align-items-center home_founder_sub_image" >
                  <div className="founder_image_mobile">
                    <img src={founder_logo} alt="Founder Icon" />
                    <h2 className="text-white">
                      Founder's <br></br> Message
                    </h2>
                  </div>
                  <div className="founder-text ms-3 text-start founder_message_all_text">
                    <p className="text-white">
                      {founderData?.founderData?.[0]?.founder_message}
                    </p>
                  </div>
                </div>

                <div className="col-4 col-md-4 text-end founder_school_btn">
                  <button className="all_button" onClick={()=>{navigate('/founder')}}>Meet the Founder</button>
                  <button className="all_button" onClick={() => navigate('/founder', { state: "founder" })}>View the Message</button>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-10 d-flex align-items-center home_founder_sub_image">
                  <div className="founder-image">
                    <img src={founder_logo} alt="Founder Icon" />
                  </div>
                  <div className="founder-text ms-3 text-start founder_message_all_text">
                    <h2 className="text-white">Founder's Message</h2>
                    <p className="text-white">
                      Although it would be nice at an idealistic level to have a
                      ‘custom-built’ education for each child, we have to be
                      aware that in everything we do, we are not alone…we are
                      social beings and we will have to conform–your freedom
                      only extends as far as somebody else’s. We must allow the
                      child the freedom to speculate…
                    </p>
                  </div>
                </div>

                <div className="col-md-2 text-end founder_school_btn">
                  <Link to="/founder">
                    <button className="all_button">Meet the Founder</button>
                  </Link>
                  {/* <Link > */}
                    <button className="all_button" onClick={() => navigate('/founder', { state: "founder" })}>View the Message</button>
                  {/* </Link> */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <div>
        <Diffrent_way_to_live></Diffrent_way_to_live>
      </div>
      {/* /////// Qucik Connect */}
      {/* <div>
        <div className="quick_connect_container section_gapping">
          <div className="container-fluid text-center   ">
            <div className="quick_cc">
              <h2 className="fw-bold">Quick Connect</h2>
              <p className="mb-4">
                Experience challenges you to reach your potential. It's about
                connecting <br></br> with others and forging lifelong
                friendships.
              </p>
            </div>
            <div className="container-fluid quick_connect_section">
              <div className="row text-center py-3 quick_padding_bottom">
                <div className="col-6 col-md-3 my-3 quick_connect_item">
                  <img src={quick1} alt="Admissions" className="icon-image" />
                  <h5>Admissions</h5>
                </div>
                <div className="col-6 col-md-3 my-3 quick_connect_item">
                  <img src={quick2} alt="Campus Visit" className="icon-image" />
                  <h5>Campus Visit</h5>
                </div>
                <hr className="horizontal_line"></hr>
                <div className="col-6 col-md-3 my-3 quick_connect_item right_border">
                  <img
                    src={quick3}
                    alt="School Calendar"
                    className="icon-image"
                  />
                  <h5>School Calendar</h5>
                </div>
                <div className="col-6 col-md-3 my-3 quick_connect_item">
                  <img src={quick4} alt="Technology" className="icon-image" />
                  <h5>Technology</h5>
                </div>
              </div>

              <hr className="section_divider" />

              <div className="row text-center py-3 quick_padding_top">
                <div className="col-6 col-md-3 my-3 quick_connect_item">
                  <img src={quick5} alt="Brochure" className="icon-image" />
                  <h5>Brochure</h5>
                </div>
                <div className="col-6 col-md-3 my-3 quick_connect_item">
                  <img src={quick6} alt="Food Menu" className="icon-image" />
                  <h5>Food Menu</h5>
                </div>
                <hr className="horizontal_line"></hr>
                <div className="col-6 col-md-3 my-3 quick_connect_item right_border">
                  <img
                    src={quick7}
                    alt="Green Infrastructure"
                    className="icon-image"
                  />
                  <h5>Green Infrastructure</h5>
                </div>
                <div className="col-6 col-md-3 my-3 quick_connect_item">
                  <img src={quick8} alt="FAQs" className="icon-image" />
                  <h5>FAQs</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* /////// Quick Connect */}
      <div>
        <div className="quick_connect_container section_gapping">
          <div className="container-fluid text-center">
            <div className="quick_cc">
              <h2 className="fw-bold">Quick Connect</h2>
              <p className="mb-4">
              Quick access to essential school 
              resources at your fingertips
                {/* Experience challenges you to reach your potential. It's about
                connecting <br className="home_slider_br" /> with others and
                forging lifelong friendships. */}
              </p>
            </div>
            <div className="container-fluid quick_connect_section">
              {cmsQuickConnect?.length > 0 && (
                <>
                  <div className="row text-center py-3 quick_padding_bottom">
                    {cmsQuickConnect.slice(0, 4).map((item, index) => (
                      <div
                        key={item._id}
                        className={`col-6 col-md-3 my-3 quick_connect_item ${index === 2 ? "right_border" : ""
                          }`}
                      >
                        <Link to={item?.content}>
                          <img
                            src={`${FRONT_ASSETS_URL}${item.image}`}
                            alt={item.title}
                            className="icon-image"
                          />
                        </Link>
                        <h5>{item.title}</h5>

                      </div>
                    ))}
                  </div>
                  <hr className="section_divider" />
                  <div className="row text-center py-3 quick_padding_top">
                    {cmsQuickConnect.slice(4).map((item, index) => (
                      <div
                        key={item._id}
                        className={`col-6 col-md-3 my-3 quick_connect_item ${index === 2 ? "right_border" : ""
                          }`}
                      >
<Link to={getViewableLink(item?.content)} target="_blank" rel="noopener noreferrer">
  <img
    src={`${FRONT_ASSETS_URL}${item.image}`}
    alt={item.title}
    className="icon-image"
  />
</Link>

                        <h5>{item.title}</h5>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* //////// Event SLider */}
      <div>
        <Event_slider></Event_slider>
      </div>
      {/* ////////// Accreditations & Partnerships */}
      <div className="container-fuild text-center section_gapping accreditations_partnerships_main_section">
        {/* Section Title */}
        <h2 className="text-center mb-4 accreditation-title accreditations_partnerships">
          Accreditations <span>&</span> Partnerships
        </h2>
        <Accrediation_slider></Accrediation_slider>
      </div>
      <div>
        <Kc_album_section></Kc_album_section>
      </div>
      {/* /////////Our campuses section */}
      <div className="campus_own_gapping">
        <div className="container-fluid our_campus_main_content">
          <div className="text-center home_school_heading">
            <h2>
              Our <span className="highlight">Campuses</span>
            </h2>
            <p>
              {/* Our school has grown over time to meet increasing demand. Our{" "}
              <strong>three magnificent campuses</strong> , which today welcome{" "}
              <br className="home_united_br"></br>{" "}
              <strong>4,450 students</strong> , are united by the same values,
              while each having their own distinctive fee */}
              {/* The KC High Campus is a true reflection of our commitment to joyful learning and self-expression.  <br className="home_united_br"></br>{" "} Designed to foster collaboration, creativity, and individuality, it serves as a vibrant space for over 800 students   <br className="home_united_br"></br>{" "} and 200 dedicated faculty and staff, all coming together to create an enriching and dynamic learning experience. */}
              The KC High Campus is a vibrant space that fosters joyful learning, collaboration, and  <br className="home_united_br"></br> individuality for over 800 students and 200 faculty and staff.
            </p>
          </div>
          <div className="row our_campus_row_section">
            {/* Left card (larger) */}
            <div className="col-md-6 our_campus_map_section">
              <div>
                <iframe
                  src={generalData?.generalData_location}
                  width="100%"
                  height="300"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>

            {/* Right cards (two smaller cards stacked vertically) */}
            {ourCampuses.map((campus, index) => (
              <div className="col-md-3 campus_first" key={index}>
                <div
                  className="campus-card position-relative text-white campus_middle_card_section"
                  style={{
                    backgroundColor: campus.color,
                    "--dynamic-gradient": `linear-gradient(to bottom, transparent, ${campus.color})`,
                    "--hover-gradient": `linear-gradient(to bottom, ${campus.color}d8, ${campus.color})`,
                  }}
                >
                  <img
                    src={`${FRONT_ASSETS_URL}${campus?.homepage_image}`}
                    alt="Campus Background"
                    className="campus-image"
                  />
                  <div className="campus-content position-absolute campus_middle_card_div">
                    <ul>
                      <li className="all_campus_link">
                        <h5 className="kc_heigh_heading">{campus.name}</h5>
                        <ul className="list-unstyled kc_all_icon_for_location addrees_footer campus_location_text">
                          <li className="campus_location">
                            <MdLocationOn className="location_group" />
                            <span>{campus.address}</span>
                          </li>
                          <li className="campus_location">
                            <IoMdMail />
                            <span>{campus.email}</span>
                          </li>
                          <li className="campus_location">
                            <IoMdCall />
                            <span>{campus.phone}</span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                    <div className="text-end founder_school_btn">
                      <button className="visit_campus visit_btn">
                        <a
                          href={campus?.location}
                          target="_blank"
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          Visit Campus
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Home;

///////////////////////////
