// import React from 'react';
import React, { useEffect, useState } from "react";
import Header from "../../Header/header";
import Footer from "../../footer/footer";
import board_img from "../../images/board_img.png";
import AcademicEarlySlider from "../academics_early/academic_early_slider";
import Common_faq from "../common_faq";
import Learning_domain_slider from "../academic_primary/learning_domain_slider";
import Common_sticky_button from "../../Home/common_sticky_button";
import { academicsDetails } from "../../../controllers/academicsController/academicsController";
import { FRONT_ASSETS_URL } from "../../../config/constants";
import Discover_Slider from "../academic_primary/discover_slider";
import { Link } from "react-router-dom";

function Academics_middle() {
  const [isLoading, setIsLoading] = useState(false);
  const [academicsMiddle, setAcademicsMiddle] = useState("");

  /*********************************************************
   *  This function is use to fetch cms content list
   *********************************************************/
  const academicDetailsData = async () => {
    setAcademicsMiddle([]);
    setIsLoading(true);
    try {
      const options = {
        slug: "middle",
      };
      const listData = await academicsDetails(options);
      if (listData?.status === true) {
        setAcademicsMiddle(listData?.result);
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      setIsLoading(false);
    }
  }; //end

  useEffect(() => {
    academicDetailsData();
  }, []);
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <section className="early_years_section">
        <div className="container-fluid">
          <div className="content-container">
            <div className="row">
              <div className="sec_adjust">
                <div className="col-12 col-md-6 col-lg-5">
                  <div className="text-start early_year_heading_Section">
                    <h2>
                      {academicsMiddle?.bannerData?.title.split(" ")[0]}{" "}
                      <span>
                        {academicsMiddle?.bannerData?.title
                          ?.split(" ")
                          .slice(1)
                          .join(" ")}
                      </span>
                    </h2>
                    <h4>
                      {academicsMiddle?.bannerData?.subtitle.split(" ")[0]}{" "} 
                      <span> 
                        {academicsMiddle?.bannerData?.subtitle
                          ?.split(" ")
                          .slice(1)
                          .join(" ")}
                      </span>
                    </h4>
                    <p>{academicsMiddle?.bannerData?.content}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-7">
                  <div className="pulse-container">
                    <img
                      src={`${FRONT_ASSETS_URL}${academicsMiddle?.bannerData?.image}`}
                      alt="Early Years Program"
                      className="early_circular_image"
                    />
                    <div className="pulse pulse-1"></div>
                    <div className="pulse pulse-2"></div>
                    <div className="pulse pulse-3"></div>
                    <div className="pulse pulse-4"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center home_school_heading">
          <h2>
            Stages of <span className="highlight">Independence</span>
          </h2>
          <p>
            This curriculum encourages critical thinking, collaboration, and
            problem-solving from a young <br></br> age. It’s designed to build a
            strong foundation for lifelong learning and global awareness
          </p>
          {/* <Middle_Discover_Slider stagesData={academicsPrimary?.stagesData}></Middle_Discover_Slider> */}
          <Discover_Slider
            stagesData={academicsMiddle?.stagesData}
          ></Discover_Slider>
        </div>
      </section>
      <Common_sticky_button></Common_sticky_button>
      <div className="home_founder_section">
        <div className="container-fluid all_fluid_main_class">
          <div className="row align-items-center">
            <div className="d-flex justify-content-center home_founder_message_row">
              <div className="col-md-12 d-flex align-items-center board_Section">
                <div>
                  <img src={board_img} alt="Founder Icon" />
                </div>
                <div className="founder-text ms-3 text-start board_Section_text">
                  <h2 className="text-white">
                    The Cambridge Board for Lower
                    Secondary
                  </h2>
                  <p className="text-white">
                    Its offers a globally recognized curriculum that fosters
                    critical thinking and creativity in young learners. With a
                    focus on essential subjects and holistic development, it
                    prepares students for future academic challenges.
                    Emphasizing inquiry-based learning, the Cambridge approach
                    nurtures curiosity and a lifelong love for learning
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //////// Learning domain slider */}
      <Learning_domain_slider
        domainData={academicsMiddle?.domainData}
      ></Learning_domain_slider>
      {/* ///////// FAQ section */}
      <div className="faq_background">
        <Common_faq faqData={academicsMiddle?.faqData}></Common_faq>
      </div>
      {/* //Ready to section */}
      <div className="home_founder_section mt-4">
        <div className="container-fluid all_fluid_main_class">
          <div className="row align-items-center academic_early_message">
            <div className="col-md-10 d-flex align-items-center home_founder_sub_image">
              <div className="founder-text ms-3 text-start founder_message_all_text">
                <h2 className="academics_ready_text">
                  Ready to take the first step with us?
                </h2>
                <p className="text-white together">
                  Together, we will build a strong foundation for your child's
                  future, nurturing a lifelong love of learning and exploration.
                </p>
              </div>
            </div>

            <div className="col-md-2 text-end  register_now_btn">
              <Link to={"/enter-student"}>
                <button className="academics_button">Register Now!</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <AcademicEarlySlider slug={"middle"}></AcademicEarlySlider>
      <Footer></Footer>
    </div>
  );
}

export default Academics_middle;
