import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FRONT_ASSETS_URL } from '../../../config/constants';

function Discover_Slider({ stagesData }) {
    useEffect(() => {
        const userAgent = navigator.userAgent;
        // Check for Safari but exclude Chrome
        if (userAgent.includes('Safari') && !userAgent.includes('Chrome')) {
            document.body.classList.add('safari');
        } else {
            // Optionally remove class if it's not Safari
            document.body.classList.remove('safari');
        }
    }, []);
    const settings = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        autoplay: false,
        slidesToScroll: 1,
        centerMode: true,
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    // Ensure data is an array and preprocess it
    const replicateData = (data) => {
        if (!Array.isArray(data)) {
            return []; // Fallback if data is not an array
        }
        return data.length === 1 ? Array(4).fill(data[0]) : data;
    };

    // Preprocess the data using replicateData
    const partnersrep = replicateData(stagesData);

    return (
        <div className="academics_all_section">
            <div className="container-fluid">
                <div className="explore_slider_main_div">
                    <Slider {...settings}>
                        {partnersrep?.map((stage, index) => (
                            <div className="slide-item" key={stage._id || index}>
                                <div className="academic_slider position-relative text-white campus_middle_card_section">
                                    <img
                                        src={`${FRONT_ASSETS_URL}${stage.image}`}
                                        alt={stage.grade || 'Grade Image'}
                                        className="campus-image campus_img_heigh "
                                    />
                                    <div className="campus-content position-absolute academic_slider_cotnet">
                                        <ul>
                                            <li className="all_campus_link">
                                                <h5 className="kids_central">
                                                     {stage.grade} {/* Adjust the grade dynamically */}
                                                </h5>
                                                <h6 className="cool_cats">
                                                    {stage.title || 'Title Unavailable'}
                                                </h6>
                                                <ul className="list-unstyled academic_slider_ul academic_slider_tabs">
                                                    <li>
                                                        <span
                                                            className='discover_content_p'
                                                            dangerouslySetInnerHTML={{
                                                                __html: stage.content || 'Content Unavailable',
                                                            }}
                                                        />
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default Discover_Slider;
