import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { campuses} from "../../controllers/commonController/cmsController";

// Async thunk to fetch general data
export const fetchOurCampusData = createAsyncThunk(
    'ourCampus',
    async (_, { getState, rejectWithValue }) => {
        try {
            const { ourCampuses } = getState().ourCampusReducer; 
            if (Array.isArray(ourCampuses) && ourCampuses.length > 0) {
                // Return existing state data if already present
                return ourCampuses;
            }

            const options = {
                type: "",
                condition: { 
                    status: "A"
                },
                select: {},
                sort: { _id: -1 },
                populate: { 
                    key: "",
                    select: " "
                },
                skip: 0,
                limit: 10
            };
            const res = await campuses(options); 
            return res?.result || []; 
        } catch (error) {
            console.error("API Error:", error);
            return rejectWithValue(error.message); 
        }
    }
);

// Redux Toolkit slice
const ourCampusReducer = createSlice({
    name: 'ourCampus',
    initialState: {
        ourCampuses: [], // Initialize task as an empty array
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOurCampusData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchOurCampusData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.ourCampuses = action.payload; // Update the state with fetched data
            })
            .addCase(fetchOurCampusData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload; 
            });
    },
});

// Export the reducer
export default ourCampusReducer.reducer;

