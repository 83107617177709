import React, { useEffect, useState } from "react";
import Header from "../../Header/header";
import Footer from "../../footer/footer";
import AcademicEarlySlider from "../academics_early/academic_early_slider";
import Common_faq from "../common_faq";
import Academic_curriculum from "./academic_curriculum";
import Common_sticky_button from "../../Home/common_sticky_button";
import { FRONT_ASSETS_URL } from "../../../config/constants";
import Discover_Slider from "../academic_primary/discover_slider";
import { academicsDetails } from "../../../controllers/academicsController/academicsController";
import { Link } from "react-router-dom";

function Academics_senior() {
  const [isLoading, setIsLoading] = useState(false);
  const [academicsSenior, setAcademicsSenior] = useState("");

  /*********************************************************
   *  This function is use to fetch cms content list
   *********************************************************/
  const academicDetailsData = async () => {
    setAcademicsSenior([]);
    setIsLoading(true);
    try {
      const options = {
        slug: "senior",
      };
      const listData = await academicsDetails(options);
      if (listData?.status === true) {
        setAcademicsSenior(listData?.result);
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      setIsLoading(false);
    }
  }; //end

  useEffect(() => {
    academicDetailsData();
  }, []);
  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
console.log(academicsSenior,"academicsSenior")

  return (
    <div>
      <Header />
      <section className="early_years_section">
        <div className="container-fluid">
          <div className="content-container">
            <div className="row">
              <div className="sec_adjust">
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="text-start early_year_heading_Section">
                  {academicsSenior?.bannerData?.page==='senior'?
                   <h2>High Year <span>School</span></h2>:
               <h2>
                      {academicsSenior?.bannerData?.title.split(" ")[0]}{" "}
                      {academicsSenior?.bannerData?.title.split(" ")[1]}{" "}
                      <span>
                        {academicsSenior?.bannerData?.title
                          ?.split(" ")
                          .slice(2)
                          .join(" ")}
                      </span>
                    </h2> 
}
                    <h4>
                      {academicsSenior?.bannerData?.subtitle.split(" ")[0]}
                      <span>
                        {" "}
                        {academicsSenior?.bannerData?.subtitle
                          ?.split(" ")
                          .slice(1)
                          .join(" ")}
                      </span>
                    </h4>
                    <p>{academicsSenior?.bannerData?.content}</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6">
                  <div className="pulse-container">
                    <img
                      src={`${FRONT_ASSETS_URL}${academicsSenior?.bannerData?.image}`}
                      alt="Early Years Program"
                      className="early_circular_image"
                    />
                    <div className="pulse pulse-1"></div>
                    <div className="pulse pulse-2"></div>
                    <div className="pulse pulse-3"></div>
                    <div className="pulse pulse-4"></div>
                  </div>
                </div>
                {/* <div className="col-12 col-md-6 col-lg-7">
                                    <div className="pulse-container">
                                        <img src={early_top_img} alt="Early Years Program" className="early_circular_image" />
                                        <div className="pulse pulse-1"></div>
                                        <div className="pulse pulse-2"></div>
                                        <div className="pulse pulse-3"></div>
                                    </div>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="text-center home_school_heading">
          <h2>
            Stages of <span className="highlight">Excellence</span>
          </h2>
          <p>
            This curriculum encourages critical thinking, collaboration, and
            problem-solving from a young <br></br> age. It’s designed to build a
            strong foundation for lifelong learning and global awareness
          </p>
          {/* <Middle_Discover_Slider></Middle_Discover_Slider> */}
          <Discover_Slider
            stagesData={academicsSenior?.stagesData}
          ></Discover_Slider>
        </div>
      </section>
      <Common_sticky_button></Common_sticky_button>
      {/* //// Curriculum Offered */}
      <Academic_curriculum
        academicsSenior={academicsSenior?.curriculumsData}
      ></Academic_curriculum>
      {/* ///////// FAQ section */}
      <div className="faq_background">
        <Common_faq faqData={academicsSenior?.faqData}></Common_faq>
      </div>
      {/* //Ready to section */}
      <div className="home_founder_section mt-4">
        <div className="container-fluid all_fluid_main_class">
          <div className="row align-items-center academic_early_message">
            <div className="col-md-10 d-flex align-items-center home_founder_sub_image">
              <div className="founder-text ms-3 text-start founder_message_all_text">
                <h2 className="academics_ready_text">
                  Ready to take the first step with us?
                </h2>
                <p className="text-white together">
                  Together, we will build a strong foundation for your child's
                  future, nurturing a lifelong love of learning and exploration.
                </p>
              </div>
            </div>

            <div className="col-md-2 text-end register_now_btn">
              <Link to={"/enter-student"}>
                <button className="academics_button">Register Now!</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <AcademicEarlySlider slug={"senior"}></AcademicEarlySlider>
      <Footer></Footer>
    </div>
  );
}

export default Academics_senior;
