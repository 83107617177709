import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { academicListData } from "../../controllers/homeController.js/homeController";
import { FRONT_ASSETS_URL } from "../../config/constants";
import { Link } from "react-router-dom";

function Diffrent_way_to_live() {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
  const [activeIndex, setActiveIndex] = useState(0);
  const [academicList, setAcademicList] = useState([]);

  /*********************************************************
   *  This function is use to fetch partners list
   *********************************************************/
  const academicsData = async () => {
    setAcademicList([]);
    // setIsLoading(true);
    try {
      const listData = await academicListData();
      if (listData?.status === true) {
        setAcademicList(listData?.result);
        // console.log(listData?.result, "fhsdfd");
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      //   setIsLoading(false);
    }
  };
  useEffect(() => {
    academicsData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    autoplay: true,
    slidesToScroll: 1,
    arrows: false,
    cssEase: "linear",
    centerMode: true,
    afterChange: (index) => setActiveIndex(index),
    focusOnSelect: true,
  };

  const replicateData = (data) => {
    return data.length === 1 ? Array(5).fill(data[0]) : data;
  };

  // Preprocess the data using replicateData
  const processedSlides = replicateData(academicList);

  return (
    <div className="container-fluid section_gapping all_fluid_main_class">
      <div className="text-start home_school_heading">
        <h2>
          A different way to <span className="highlight">live and learn</span>
        </h2>
        <p>
          {/* It is a long established fact that a reader will be distracted by the
          readable content of a page when{" "}
          <br className="diffrent_way_mobile"></br> looking at its layout. The
          point of using Lorem Ipsum is that it has a more-or-less normal. */}
          At KC High, we cultivate a learning environment that sparks joy, fosters curiosity, and celebrates diversity, where every student is empowered to reach their full potential.
        </p>
      </div>

      <div className="row">
        {isMobileView ? (
          <>
            <Slider {...settings}>
              {processedSlides.map((slide, index) => (
                <div
                  key={index}
                  className={`col-lg-3 col-md-6 mb-4 home_diffrent_main_section`}
                >
                  <div
                    className={`h-100 position-relative home_diffrent_div ${activeIndex === index ? "diffrent_way_slide" : ""
                      }`}
                  >
                    <div className="position-relative home_diffrent_img_section">
                      <img
                        src={`${FRONT_ASSETS_URL}${slide?.image}`}
                        className="card-img-top img-fluid"
                        alt={slide?.name}
                      />
                    </div>
                    <div className="home_sernior">
                      <div className="card-body text-start home_diffrent_text_section">
                        <h5 className="card-title">{slide?.slug==="early"?"Pre-Primary":slide?.name==="Senior"?"High":slide?.name}</h5>
                        {/* <p className="card-text">{slide.content}</p> */}
                        {slide.content
                          .split(" ") // Split content into words
                          .slice(0, 25) // Take the first 30 words
                          .join(" ")}
                        ... {/* Join them back and add ellipsis */}
                      </div>
                    </div>
                    <Link to={`/academics/${slide.slug}`}>
                    <button className='home_live_learn'> Read More...</button>
                  </Link>
                  </div>
                </div>
              ))}
            </Slider>
            ;
          </>
        ) : (
          <>
            {processedSlides.map((slide, index) => (
              <div
                key={index}
                className={`col-lg-3 col-md-6 mb-4 home_diffrent_main_section`}
              >
                <div
                  className={`h-100 position-relative home_diffrent_div ${activeIndex === index ? "diffrent_way_slide" : ""
                    }`}
                >
                  <div className="position-relative home_diffrent_img_section">
                    <img
                      src={`${FRONT_ASSETS_URL}${slide.image}`}
                      className="card-img-top img-fluid"
                      alt={slide?.slug==="early"?"Pre-Primary":slide?.name==="Senior"?"High":slide?.name}
                    />
                  </div>
                  <div className="home_sernior">
                    <div className="card-body text-start home_diffrent_text_section">
                      <h5 className="card-title">{slide?.slug==="early"?"Pre-Primary":slide?.name==="Senior"?"High":slide?.name}</h5>
                      <p className="card-text">
                        {slide.content.split(" ").slice(0, 40).join(" ")}...
                      </p>
                    </div>
                  </div>
                  <Link to={`/academics/${slide.slug}`}>
                    <button className='home_live_learn'>Read More...</button>
                  </Link>
                </div>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}

export default Diffrent_way_to_live;
