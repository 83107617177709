import React, { useEffect, useState } from 'react';
import Header from '../../../Header/header';
import Footer from '../../../footer/footer';
import Common_heading from '../Common Heading/common_heading';
import Registration_side_bar from '../registration_side_bar/registration_side_bar';
import { fatherDetail, registrationDetails } from '../../../../controllers/submissionsController/submissionsController';
import { useNavigate } from 'react-router-dom';
import { notification } from "antd";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
function Detailsoffather() {
    const navigate = useNavigate();
    const [applicationId, setApplicationId] = useState('');
    const [formData, setFormData] = useState({
        id: '',
        father_name: '',
        Father_occupation: '',
        father_company_name: '',
        Father_work_address: '',
        father_city: '',
        father_state: '',
        father_pin_code: '',
        father_country_code: '',
        father_phone: '',
        father_email: '',
    });
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        setErrors({ ...errors, [name]: '' });
    };
    const validateField = (name, value) => {
        let error = "";

        switch (name) {
            case 'father_name':
                if (!value.trim()) {
                    error = "Father's name is required.";
                } else if (/[^a-zA-Z\s'-]/.test(value)) {
                    error = "Name can only include alphabets, spaces, hyphens, and apostrophes.";
                } else if (value.length < 2) {
                    error = "Father's name must be at least 2 characters long.";
                } else if (value.length > 50) {
                    error = "Father's name must not exceed 50 characters.";
                } else if (/^\d+$/.test(value)) {
                    error = "Father's name cannot be only numbers.";
                }
                break;
            case 'Father_occupation':
                if (!value.trim()) error = "Occupation is required.";
                // else if (/[^a-zA-Z\s,.'-]/.test(value)) error = "Occupation can include alphabets and basic punctuation.";
                break;
            // case 'Father_work_address':
            //     if (!value.trim()) error = "Work address is required.";
            //     break;
            // case 'father_city':
            // case 'father_state':
            //     if (!value.trim()) error = `${name.replace(/_/g, ' ')} is required.`;
            //     else if (/[^a-zA-Z\s]/.test(value)) error = "Only alphabets and spaces are allowed.";
            //     break;
            // case 'father_pin_code':
            //     if (!value.trim()) error = "Pin code is required.";
            //     else if (!/^\d{6}$/.test(value)) error = "Pin code must be exactly 6 digits.";
            //     break;
            case 'father_country_code':
                if (!value.trim()) error = "Country code is required.";
                else if (!/^\+\d+$/.test(value)) error = "Country code must start with '+' followed by digits.";
                break;
            case 'father_phone':
                if (!value.trim()) {
                    error = "Phone number is required.";
                } 
                break;

            // case 'father_email':
            //     if (!value.trim()) error = "Email address is required.";
            //     else {
            //         const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            //         if (!emailRegex.test(value)) error = "Invalid email format.";
            //     }
            //     break;
            default:
                break;
        }

        return error;
    };

    const validateForm = () => {
        const newErrors = {};
        Object.keys(formData).forEach((key) => {
            const error = validateField(key, formData[key]);
            if (error) newErrors[key] = error;
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) return;

        const rawBody = {
            ...formData,
        };

        try {
            const response = await fatherDetail(rawBody);
            if (response?.status === true) {
                // alert('Details submitted successfully.');
                notification.open({
                    message: "Submitted.",
                    description: `Details submitted successfully.`,
                    placement: "topRight",
                    icon: <CheckCircleOutlined style={{ color: "green" }} />,
                    duration: 2,
                });
                window.history.pushState({}, '', '/details-of-mother');
                window.dispatchEvent(new Event('popstate'));
            } else {
                // alert('Failed to submit details.');
                notification.open({
                    message: "Opps!",
                    description: `${"Failed to submit details"}`,
                    placement: "topRight",
                    icon: <InfoCircleOutlined style={{ color: "red" }} />,
                    duration: 2,
                });
            }
        } catch (error) {
            console.error('Submission error:', error);
            console.log('An error occurred while submitting the details.');
        }
    };

    useEffect(() => {
        const fetchApplicationDetails = async () => {
            const encodedId = localStorage.getItem('applicationId');
            if (!encodedId) {
                console.error('Encoded applicationId not found in localStorage');
                navigate('/enter-student');
                return;
            }
            try {
                const decodedId = atob(encodedId);
                setApplicationId(decodedId);
                const response = await registrationDetails({ id: decodedId });
                if (response?.status === true) {
                    const prevData = response.result;
                    setFormData({
                        id: decodedId || '',
                        father_name: prevData.father_name || '',
                        Father_occupation: prevData.Father_occupation || '',
                        father_company_name: prevData.father_company_name || '',
                        Father_work_address: prevData.Father_work_address || '',
                        father_city: prevData.father_city || '',
                        father_state: prevData.father_state || '',
                        father_pin_code: prevData.father_pin_code || '',
                        father_country_code: prevData.father_country_code || '',
                        father_phone: prevData.father_phone || '',
                        father_email: prevData.father_email || '',
                    });
                }
            } catch (error) {
                console.error('Error fetching registration details:', error);
                navigate('/enter-student');
            }
        };

        fetchApplicationDetails();
    }, [navigate]);

    return (
        <div>
            <Header />
            <div className="inner_mainbanner">
                <Common_heading />
            </div>
            <div className='exam_gapping_div'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <Registration_side_bar />
                        </div>
                        <div className="col-md-9">
                            <form>
                                <div className="form_heading_container mb-3 ">
                                    <h5 className="heading-text">Details of Father/Guardian</h5>
                                </div>
                                <div>
                                    <div className="row g-3">
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Name of Father/Guardian *"
                                                name="father_name"
                                                value={formData.father_name}
                                                onChange={handleChange}
                                            />
                                            {errors.father_name && <p className="error-text">{errors.father_name}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Occupation *"
                                                name="Father_occupation"
                                                value={formData.Father_occupation}
                                                onChange={handleChange}
                                            />
                                            {errors.Father_occupation && <p className="error-text">{errors.Father_occupation}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Company Name"
                                                name="father_company_name"
                                                value={formData.father_company_name}
                                                onChange={handleChange}
                                            />
                                            {errors.father_company_name && <p className="error-text">{errors.father_company_name}</p>}
                                        </div>
                                    </div>
                                    <div className="row g-3 guardian_details">
                                        <div className="col-md-12 regestration_form_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Office address "
                                                name="Father_work_address"
                                                value={formData.Father_work_address}
                                                onChange={handleChange}
                                            />
                                            {errors.Father_work_address && <p className="error-text">{errors.Father_work_address}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <select
                                                className="form-select"
                                                name="father_state"
                                                value={formData.father_state}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select State/UT</option>
                                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                <option value="Assam">Assam</option>
                                                <option value="Bihar">Bihar</option>
                                                <option value="Chhattisgarh">Chhattisgarh</option>
                                                <option value="Goa">Goa</option>
                                                <option value="Gujarat">Gujarat</option>
                                                <option value="Haryana">Haryana</option>
                                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                <option value="Jharkhand">Jharkhand</option>
                                                <option value="Karnataka">Karnataka</option>
                                                <option value="Kerala">Kerala</option>
                                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                <option value="Maharashtra">Maharashtra</option>
                                                <option value="Manipur">Manipur</option>
                                                <option value="Meghalaya">Meghalaya</option>
                                                <option value="Mizoram">Mizoram</option>
                                                <option value="Nagaland">Nagaland</option>
                                                <option value="Odisha">Odisha</option>
                                                <option value="Punjab">Punjab</option>
                                                <option value="Rajasthan">Rajasthan</option>
                                                <option value="Sikkim">Sikkim</option>
                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                <option value="Telangana">Telangana</option>
                                                <option value="Tripura">Tripura</option>
                                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                <option value="Uttarakhand">Uttarakhand</option>
                                                <option value="West Bengal">West Bengal</option>
                                                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                <option value="Chandigarh">Chandigarh</option>
                                                <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                                                <option value="Lakshadweep">Lakshadweep</option>
                                                <option value="Delhi">Delhi</option>
                                                <option value="Puducherry">Puducherry</option>
                                                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                <option value="Ladakh">Ladakh</option>
                                            </select>
                                            {errors.father_state && (
                                                <div className="text-danger">{errors.father_state}</div>
                                            )}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="City"
                                                name="father_city"
                                                value={formData.father_city}
                                                onChange={handleChange}
                                            />
                                            {errors.father_city && <p className="error-text">{errors.father_city}</p>}
                                        </div>
                                        {/* <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="State *"
                                                name="father_state"
                                                value={formData.father_state}
                                                onChange={handleChange}
                                            />
                                            {errors.father_state && <p className="error-text">{errors.father_state}</p>}
                                        </div> */}

                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Pin code "
                                                name="father_pin_code"
                                                value={formData.father_pin_code}
                                                onChange={handleChange}
                                            />
                                            {errors.father_pin_code && <p className="error-text">{errors.father_pin_code}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Country Code "
                                                name="father_country_code"
                                                value={formData.father_country_code}
                                                onChange={handleChange}
                                            />
                                            {errors.father_country_code && <p className="error-text">{errors.father_country_code}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="number"
                                                className="form-control"
                                                placeholder="Phone Number *"
                                                name="father_phone"
                                                value={formData.father_phone}
                                                onChange={handleChange}
                                            />
                                            {errors.father_phone && <p className="error-text">{errors.father_phone}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Email Address "
                                                name="father_email"
                                                value={formData.father_email}
                                                onChange={handleChange}
                                            />
                                            {errors.father_email && <p className="error-text">{errors.father_email}</p>}
                                        </div>
                                    </div>
                                    {/* <p className='regestration_note'>Note for Phone: Country Code + Area Code (for landlines only) Example: +91 or +91 (44)</p> */}
                                </div>
                                <div className='form_subbmit_btn mt-4'>
                                    <button type="button" onClick={handleSubmit}>Proceed to Continue</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Detailsoffather;
