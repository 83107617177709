import { API_BASE_URL } from "../../config/constants";
import { getRequest, postRequest } from "../API";


/*********************************************************
* Function Name : List
* Description   : Get academicList 
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const academicListData = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}academics/list`,
            postData: options
        }
        const res = await getRequest(params);
        // console.log( "generalDatadfsdf: ", res);
        
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end