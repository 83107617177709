import React, { useEffect, useState } from "react";
import Header from "../Header/header";
import Footer from "../footer/footer";
import "./privacy_policy.css";
import { policy } from "../../controllers/commonController/cmsController";
import { PDF_FRONT_ASSETS_URL } from "../../config/constants";
import {
  FaSearch,
  FaCross,
  FaCut,
  FaRemoveFormat,
  FaTimes,
} from "react-icons/fa";

function Privacy_policy() {
  const [cmsPolicyList, setcmsPolicyList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // State to track search input

  /*********************************************************
   *  This function is used to fetch the policy list
   *********************************************************/
  const policyList = async (search = "") => {
    setcmsPolicyList([]);
    setIsLoading(true);
    try {
      const options = {
        condition: {
          status: "A",
        },
        filter: {
          search: search.trim(), // Send search term to filter
        },
      };

      const listData = await policy(options);
      if (listData?.status === true) {
        setcmsPolicyList(listData?.result);
      }
    } catch (error) {
      console.error("Failed to fetch policy list:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    policyList(); // Fetch policies initially
  }, []);

  // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Handle search input change
  // const handleSearchChange = (event) => {
  //   const value = event.target.value;
  //   setSearchTerm(value); // Update search term
  //   policyList(value); // Call the API with the search term
  // };
  // Handle search input change
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value); // Update the search term
  };

  // Handle search icon click
  const handleSearchClick = () => {
    policyList(searchTerm); // Trigger the search with the current search term
  };
  const handleSearchClick2 = () => {
    policyList("");
    setSearchTerm("") // Trigger the search with the current search term
  };

  return (
    <div>
      <Header />
      <div className="policies_gapping_div">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="text-center kc_policies">
                <h2>
                  KC High <span>Policies</span>
                </h2>
                <p>
                  Clear Guidelines for a Safe, Supportive, and Inclusive KC High
                  Experience
                </p>
                <div className=" policy_expend_search">
                  <input
                    type="text"
                    placeholder="Search..."
                    style={{
                      width: "200px",
                      opacity: 1,
                      transition: "width 0.3s ease, opacity 0.3s ease",
                    }}
                    value={searchTerm} // Bind the input to searchTerm
                    onChange={handleInputChange} // Update the input value
                  />
                  {searchTerm && ( // Show FaTimes only if searchTerm has value
                  <FaTimes
                    className="me-2 header_search_svg mx-2"
                    onClick={handleSearchClick2}
                    style={{ cursor: "pointer" }}
                  />
                )}
                  <FaSearch
                    className="me-2 header_search_svg mx-2"
                    onClick={handleSearchClick} // Trigger search on click
                    style={{ cursor: "pointer" }}
                  />
                </div>
                {isLoading ? (
                  <p>Loading...</p>
                ) : cmsPolicyList.length > 0 ? (
                  cmsPolicyList.map((policy, index) => (
                    <div
                      className="d-flex align-items-center justify-content-between px-4 exam_course_btn padding_for_policy"
                      key={policy._id}
                    >
                      <span className="text-start policies_span">
                        {policy.name}
                      </span>
                      <div className="event_slider_left_btn exam_btn">
                        <button
                          type="button"
                          className="early_read_btn"
                          onClick={() =>
                            window.open(
                              `${PDF_FRONT_ASSETS_URL}${policy.file}`,
                              "_blank"
                            )
                          }
                        >
                          Know More
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No policies found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Privacy_policy;
