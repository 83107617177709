import React, { useEffect, useState } from "react";
import Header from "../../../Header/header";
import Footer from "../../../footer/footer";
import Common_heading from "../Common Heading/common_heading";
import Registration_side_bar from "../registration_side_bar/registration_side_bar";
import { Link, useNavigate } from "react-router-dom";
import { notification } from "antd";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  registrationDetails,
  sendOtp,
  verifyOtp,
} from "../../../../controllers/submissionsController/submissionsController";

function Enterstudent() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
    nationality: "",
    gaurdian_email: "",
    otp: "",
    // gaurdian_relationship: "",
  });
  const [errors, setErrors] = useState({});
  const [applicationId, setApplicationId] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const navigate = useNavigate();

/*********************************************************
 *  This function handles input changes.
 *********************************************************/
const handleInputChange = (e) => {
  const { name } = e.target;
  let value = e.target.value; // Use let instead of const

  if (name === "dob") {
    // Remove non-numeric characters
    value = value.replace(/\D/g, "");
  
    // Auto-format as YYYY-MM-DD
    if (value.length > 8) value = value.slice(0, 8); // Restrict to 8 digits
    if (value.length >= 5) value = value.slice(0, 4) + (value.length > 4 ? "-" : "") + value.slice(4);
    if (value.length >= 8) value = value.slice(0, 7) + (value.length > 7 ? "-" : "") + value.slice(7);
  }

  setFormData((prev) => ({
    ...prev,
    [name]: value,
  }));

  setErrors((prev) => ({ ...prev, [name]: "" })); // Clear the error for the current field
};


/*********************************************************
 *  This function is used to validate dates in DD-MM-YYYY format.
 *********************************************************/
const validateDate = (date) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/; // Matches YYYY-MM-DD format
  if (!regex.test(date)) return false;

  const [year, month, day] = date.split("-").map(Number);
  const enteredDate = new Date(year, month - 1, day);
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Normalize to start of the day

  return (
    enteredDate.getFullYear() === year &&
    enteredDate.getMonth() === month - 1 &&
    enteredDate.getDate() === day &&
    enteredDate <= today // ❌ Reject future dates
  );
};


  /*********************************************************
   *  This function is use to validateFields.
   *********************************************************/

const validateFields = () => {
  const newErrors = {};

  // Validate first name
  if (!formData.first_name || !formData.first_name.trim()) {
    newErrors.first_name = "First name is required.";
  } else {
    // Check for numbers
    if (/\d/.test(formData.first_name)) {
      newErrors.first_name = "First name cannot contain numbers.";
    }
    // Check for special characters (except apostrophes and hyphens)
    if (/[^a-zA-Z\s'-]/.test(formData.first_name)) {
      newErrors.first_name = "First name can only contain alphabets, apostrophes, and hyphens.";
    }
    // Check minimum character limit (e.g., minimum 2 characters)
    if (formData.first_name.trim().length < 2) {
      newErrors.first_name = "First name must be at least 2 characters long.";
    }
    if (formData.first_name.trim().length > 30) {
      newErrors.first_name = "First name cannot exceed 30 characters.";
    }
  }

  // Validate last name
  if (!formData.last_name || !formData.last_name.trim()) {
    newErrors.last_name = "Last name is required.";
  }else {
    // Check for numbers
    if (/\d/.test(formData.last_name)) {
      newErrors.last_name = "Last name cannot contain numbers.";
    }
    // Check for special characters (except apostrophes and hyphens)
    if (/[^a-zA-Z\s'-]/.test(formData.last_name)) {
      newErrors.last_name = "Last name can only contain alphabets, apostrophes, and hyphens.";
    }
    // Check minimum character limit (e.g., minimum 2 characters)
    if (formData.last_name.trim().length < 2) {
      newErrors.last_name = "Last name must be at least 2 characters long.";
    }
    if (formData.last_name.trim().length > 20) {
      newErrors.last_name = "Last name cannot exceed 20 characters.";
    }
  }

  // Validate date of birth
  if (!formData.dob || !formData.dob.trim()) {
    newErrors.dob = "Date of birth is required.";
  }else if (!validateDate(formData.dob)) {
    newErrors.dob = "Invalid or future date is not allowed .";
  }

 

  // Validate gender
  if (!formData.gender || !formData.gender.trim()) {
    newErrors.gender = "Gender is required.";
  }

  // Validate nationality
  if (!formData.nationality || !formData.nationality.trim()) {
    newErrors.nationality = "Nationality is required.";
  }else {
    // Check for numbers
    if (/\d/.test(formData.nationality)) {
      newErrors.nationality = "Nationality cannot contain numbers.";
    }
  // Check for special characters (except apostrophes and hyphens)
  if (/[^a-zA-Z\s'-]/.test(formData.nationality)) {
    newErrors.nationality = "Nationality can only contain alphabets, apostrophes, and hyphens.";
  }
  // Check minimum character limit (e.g., minimum 2 characters)
  if (formData.nationality.trim().length < 1) {
    newErrors.nationality = "Nationality must be at least 2 characters long.";
  }
  if (formData.nationality.trim().length > 20) {
    newErrors.nationality = "Nationality cannot exceed 20 characters.";
  }}

  // Validate guardian email
  if (!formData.gaurdian_email || !formData.gaurdian_email.trim()) {
    newErrors.gaurdian_email = "Guardian email is required.";
  } else {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.gaurdian_email)) {
      newErrors.gaurdian_email = "Invalid email format.";
    }
  }

  // Validate guardian relationship
  // if (!formData.gaurdian_relationship || !formData.gaurdian_relationship.trim()) {
  //   newErrors.gaurdian_relationship = "Guardian relationship is required.";
  // }

  // Validate OTP
  if (otpSent && (!formData.otp || !formData.otp.trim())) {
    newErrors.otp = "OTP is required.";
  }

 

  return newErrors;
};

  /*********************************************************
   *  This function is use send Otp.
   *********************************************************/
  const sendOtpFunction = async () => {
    const newErrors = validateFields();
    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      return;
    }
    try {
      const rawBody = {
        gaurdian_email: formData.gaurdian_email,
        name: `${formData.first_name} ${formData.last_name}`
      };
      const response = await sendOtp(rawBody);
      if (response?.status === true) {
        // alert("OTP has been sent to your email.");
        notification.open({
          message: "OTP send.",
          description: `OTP has been sent to your email.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
        setOtpSent(true);
      } else {
        // alert("Failed to send OTP.");
        notification.open({
          message: "Opps!",
          description: `${"Failed to send OTP"}`,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      console.error("Failed to send OTP:", error);
    }
  }; //end

  /*********************************************************
   *  This function is use to verify Otp .
   *********************************************************/
  const verifyOtpFunction = async () => {
    const newErrors = validateFields();
    if (Object.keys(newErrors).length) {
      setErrors(newErrors);
      return;
    }
    try {
      const rawBody = { ...formData };
        // Convert date format from DD-MM-YYYY to YYYY-MM-DD
    // if (rawBody.dob) {
    //   const [day, month, year] = rawBody.dob.split("-");
    //   rawBody.dob = `${year}-${month}-${day}`;
    // }
      const response = await verifyOtp(rawBody);
      if (response?.status === true) {
        // console.log(response, "response");
        // Save applicationId to localStorage with Base64 encoding
        const encodedId = btoa(response.result._id);
        localStorage.setItem("applicationId", encodedId);

        // alert("OTP verified successfully.");
        notification.open({
          message: "OTP verified.",
          description: `OTP verified successfully.`,
          placement: "topRight",
          icon: <CheckCircleOutlined style={{ color: "green" }} />,
          duration: 2,
        });
        window.history.pushState({}, "", "/program-for-admission");
        window.dispatchEvent(new Event("popstate"));
      } else {
        // alert("Failed to verify OTP.");
        notification.open({
          message: "Opps!",
          description: response?.message,
          placement: "topRight",
          icon: <InfoCircleOutlined style={{ color: "red" }} />,
          duration: 2,
        });
      }
    } catch (error) {
      console.error("Failed to verify OTP:", error);
    }
  }; //end
  useEffect(() => {
    const fetchApplicationDetails = async () => {
      const encodedId = localStorage.getItem("applicationId");
      if (!encodedId) {
        // console.error("Encoded applicationId not found in localStorage");
        // navigate("/enter-student");
        return;
      }

      try {
        const decodedId = atob(encodedId);
        setApplicationId(decodedId);

        // Call API to fetch registration details
        const response = await registrationDetails({ id: decodedId });
        if (response?.status === true) {
          const prevData = response.result;
          // console.log(prevData, "prevdata in student");

          // Populate form fields with existing data or empty string if not available
          setFormData({
            first_name: prevData.first_name || "",
            last_name: prevData.last_name || "",
            dob: prevData.dob || "",
            gender: prevData.gender || "",
            nationality: prevData.nationality || "",
            // gaurdian_email: prevData.gaurdian_email || "",
            // gaurdian_relationship: prevData.gaurdian_relationship || "",
            otp: "", // OTP should remain empty initially
          });
        } else {
          // console.error("Failed to fetch registration details");
          // navigate("/enter-student");
        }
      } catch (error) {
        console.error("Error fetching registration details:", error);
        // navigate("/enter-student");
      }
    };

    fetchApplicationDetails();
  }, [navigate]);
 // Scroll to the top of the page when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <div className="inner_mainbanner">
        <Common_heading />
      </div>
      <div className="exam_gapping_div">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3">
              <Registration_side_bar />
            </div>
            <div className="col-md-9">
              <form>
                <div className="form_heading_container mb-3">
                  <h5 className="heading-text">
                    {/* Enter Student / Child Details */}
                    Basic Details
                  </h5>
                </div>
                <div>
                  <div className="row g-3">
                    <div className="col-md-4 regestration_form_input position-relative">
                      <input
                        type="text"
                        name="first_name"
                        className="form-control"
                        placeholder="Student’s First Name *"
                        onChange={handleInputChange}
                        value={formData.first_name}
                      />
                      {errors.first_name && (
                        <small className="text-danger">
                          {errors.first_name}
                        </small>
                      )}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="text"
                        name="last_name"
                        className="form-control"
                        placeholder="Student’s Last Name *"
                        onChange={handleInputChange}
                        value={formData.last_name}
                      />
                      {errors.last_name && (
                        <small className="text-danger">
                          {errors.last_name}
                        </small>
                      )}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <select
                        name="gender"
                        className="form-select"
                        onChange={handleInputChange}
                        value={formData.gender}
                      >
                        <option value="">Select Gender *</option>
                        <option>Male</option>
                        <option>Female</option>
                        <option>Prefer not to choose</option>
                      </select>
                      {errors.gender && (
                        <small className="text-danger">{errors.gender}</small>
                      )}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="text"
                        name="dob"
                        className="form-control"
                        onChange={handleInputChange}
                        value={formData.dob||""}
                        placeholder="Date of Birth (YYYY-MM-DD)"
                        
                      />
                      {errors.dob && (
                        <small className="text-danger">{errors.dob}</small>
                      )}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="text"
                        name="nationality"
                        className="form-control"
                        placeholder="Nationality *"
                        onChange={handleInputChange}
                        value={formData.nationality}
                      />
                      {errors.nationality && (
                        <small className="text-danger">
                          {errors.nationality}
                        </small>
                      )}
                    </div>
                    <div className="col-md-4 regestration_form_input">
                      <input
                        type="email"
                        name="gaurdian_email"
                        className="form-control"
                        placeholder="Parent/Guardian Email *"
                        onChange={handleInputChange}
                        value={formData.gaurdian_email}
                      />
                      {errors.gaurdian_email && (
                        <small className="text-danger">
                          {errors.gaurdian_email}
                        </small>
                      )}
                    </div>
                    {/* <div className="col-md-4 regestration_form_input">
                      <input
                        type="text"
                        name="gaurdian_relationship"
                        className="form-control"
                        placeholder="Guardian relation with the student *"
                        onChange={handleInputChange}
                        value={formData.gaurdian_relationship}
                      />
                      {errors.gaurdian_relationship && (
                        <small className="text-danger">
                          {errors.gaurdian_relationship}
                        </small>
                      )}
                    </div> */}
                    {otpSent && (
                      <div className="col-md-4 regestration_form_input">
                        <input
                          type="number"
                          name="otp"
                          className="form-control"
                          placeholder="OTP *"
                          onChange={handleInputChange}
                          value={formData.otp}
                        />
                        {errors.otp && (
                          <small className="text-danger">{errors.otp}</small>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form_subbmit_btn">
                  {!otpSent && (
                    <button type="button" onClick={sendOtpFunction}>
                      Send OTP
                    </button>
                  )}
                  {otpSent && (
                    // <Link to="/foreign-nationals-Only">
                      <button type="button" onClick={verifyOtpFunction}>
                        Proceed to Continue
                      </button>
                    // </Link>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Enterstudent;
