import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../Header/header";
import Footer from "../footer/footer";
import { cmsFAQ } from "../../controllers/commonController/cmsController";

function Faq() {
  const [openIndex, setOpenIndex] = useState(null);
  const [FAQ, setFAQ] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  /*********************************************************
   *  This function is use to fetch cms faq list
   *********************************************************/
  const cmsFAQlist = async () => {
    setFAQ([]);
    setIsLoading(true);
    try {
      const option = {
        type: "",
        condition: {
          status: "A",
        },
      };
      const listData = await cmsFAQ(option);
      if (listData?.status === true) {
        // console.log(listData?.result, "FAQ");
        setFAQ(listData?.result);
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      setIsLoading(false);
    }
  }; //end

  useEffect(() => {
    cmsFAQlist();
  }, []);
    // Scroll to the top of the page when the component mounts
useEffect(() => {
  window.scrollTo(0, 0);
}, []);


  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div>
      <Header />

      <div className="faq_background">
        <div className="container-fluid">
          <div className="text-center home_school_heading faq_main_div all_section_margin faq_main_mobile_div faq_main_file">
            <h2>
              <span className="highlight">FAQ’s</span>
            </h2>
            <p>
              Here are some frequently asked questions to help you understand
              our Early Years program better. <br />
              We aim to provide clarity and support as you navigate your child's
              early learning journey.
            </p>

            {/* Wrap FAQ items in a row and col-12 */}
            <div className="row">
              <div className="col-12 col-md-6 col-lg-12 faq_tabs">
                {FAQ && FAQ.length > 0 ? (
                  FAQ.map((faq, index) => (
                    <div className={`details`} key={faq._id}>
                      <div
                        className={`details__container ${
                          openIndex === index ? "open" : ""
                        }`}
                        onClick={() => toggleFAQ(index)}
                      >
                        <summary className="details__summary">
                          <h2 className="faq_details__title">{faq.title}</h2>
                        </summary>
                      </div>
                      <div
                        className={`details__desc ${
                          openIndex === index ? "open" : ""
                        }`}
                      >
                        <div className="details__desc-inner">
                          {faq.description}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No FAQs available.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Faq;
