import { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link, useLocation } from 'react-router-dom';

const Registration_side_bar = () => {
    const location = useLocation();
    const [activeKey, setActiveKey] = useState("0"); // Always set to "0" to keep the accordion open

    // Map routes to specific accordion keys (you may not need this if you want the accordion to always be open)
    useEffect(() => {
        if (
            location.pathname.startsWith('/enter-student') ||
            location.pathname.startsWith('/foreign-nationals-Only') ||
            location.pathname.startsWith('/previously-attended-details')||
            location.pathname.startsWith('/home-details')||
            location.pathname.startsWith('/other-details')||
            location.pathname.startsWith('/details-of-siblings')||
            location.pathname.startsWith('/details-of-father')||
            location.pathname.startsWith('/details-of-mother')||
            // location.pathname.startsWith('/emergency-contacts')||
            location.pathname.startsWith('/program-for-admission')||
            location.pathname.startsWith('/parent-questionnaires')
        ) {
            setActiveKey("0");
        }
    }, [location.pathname]);

    return (
        <>
            <Accordion
                activeKey={activeKey} // Keeps the accordion open by default
                className='accordian_body_main_div register_accordian_main_body'
            >
                <Accordion.Item eventKey="0" className='side_bar_item'>
                    <Accordion.Header className='registration_button'>
                        <span>Online Registration</span>
                    </Accordion.Header>
                    <Accordion.Body>
                        <ul className='services_names'>
                            <li className={`side_bar_item_fix register_side_bar ${location.pathname === '/enter-student' ? 'active' : ''}`}>
                                <div>
                                    <Link to="/enter-student">
                                        Basic Details
                                    </Link>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix register_side_bar ${location.pathname === '/program-for-admission' ? 'active' : ''}`}>
                                <div>
                                    <Link to="/program-for-admission">
                                        Apply For Admission
                                    </Link>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix register_side_bar ${location.pathname === '/foreign-nationals-Only' ? 'active' : ''}`}>
                                <div>
                                    <Link to="/foreign-nationals-Only">
                                       Foreign Nationals Only
                                    </Link>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix register_side_bar ${location.pathname === '/home-details' ? 'active' : ''}`}>
                                <div>
                                    <Link to="/home-details">
                                        Home Details
                                    </Link>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix register_side_bar ${location.pathname === '/details-of-father' ? 'active' : ''}`}>
                                <div>
                                    <Link to="/details-of-father">
                                        Details of Father/Guardian
                                    </Link>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix register_side_bar ${location.pathname === '/details-of-mother' ? 'active' : ''}`}>
                                <div>
                                    <Link to="/details-of-mother">
                                        Details of Mother/Guardian
                                    </Link>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix register_side_bar ${location.pathname === '/details-of-siblings' ? 'active' : ''}`}>
                                <div>
                                    <Link to="/details-of-siblings">
                                        Details of Siblings
                                    </Link>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix register_side_bar ${location.pathname === '/previously-attended-details' ? 'active' : ''}`}>
                                <div>
                                    <Link to="/previously-attended-details">
                                        Previously Attended Details
                                    </Link>
                                </div>
                            </li>
                        
                            <li className={`side_bar_item_fix register_side_bar ${location.pathname === '/parent-questionnaires' ? 'active' : ''}`}>
                                <div>
                                    <Link to="/parent-questionnaires">
                                        Parent Questionnaires
                                    </Link>
                                </div>
                            </li>
                            <li className={`side_bar_item_fix register_side_bar ${location.pathname === '/other-details' ? 'active' : ''}`}>
                                <div>
                                    <Link to="/other-details">
                                        Other Details
                                    </Link>
                                </div>
                            </li>
                      
                            
                            {/* <li className={`side_bar_item_fix ${location.pathname === '/emergency-contacts' ? 'active' : ''}`}>
                                <div>
                                    <Link to="/emergency-contacts">
                                        Emergency Contacts
                                    </Link>
                                </div>
                            </li> */}
                            
                       
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
};

export default Registration_side_bar;
