import React, { useEffect, useState } from 'react';
import Header from '../Header/header';
import Footer from '../footer/footer';
import "./founder.css";
import youtube from "../images/youtube.png";
import founder_logo from '../images/home_founder_logo.png';
import Reflections_slider from './reflections_slider';
import founder_middle_img from "../images/founder_middle_img.png"
import Founder_top_slider from './founder_top_slider';
import { founderDetails } from '../../controllers/commonController/cmsController';
import { useLocation } from 'react-router-dom';

function Founder() {

    const location = useLocation();
    const data = location.state;

    const [isVideoPlaying, setIsVideoPlaying] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [founderData, setFounderData] = useState({});

    const cmsfounderData = async () => {
        setFounderData({});
        setIsLoading(true);
        try {
            const listData = await founderDetails();
            if (listData?.status === true) {
                setFounderData(listData?.result);
                // console.log(listData?.result, "founderdata");
            }
        } catch (error) {
            console.log("Failed to fetch founder data:", error);
        } finally {
            setIsLoading(false);
        }
    };
    const getSanitizedVideoLink = (link) => {
        if (!link) return "";

        // Add protocol if missing
        if (!link.startsWith("http://") && !link.startsWith("https://")) {
            link = `https://${link}`;
        }

        try {
            const url = new URL(link);

            // Replace "localhost" with "www.youtube.com" if necessary
            if (url.hostname === "localhost") {
                url.hostname = "www.youtube.com";
            }

            return url.toString();
        } catch (error) {
            console.error("Invalid video link:", error);
            return "";
        }
    };


    useEffect(() => {
        cmsfounderData();
    }, []);
    // Scroll to the top of the page when the component mounts
    useEffect(() => {
        if (data === 'founder') {
            window.scrollTo(0, document.documentElement.scrollHeight / 2.3);
        } else {
            window.scrollTo(0, 0);
        }
    }, [data]);
    console.log(data,"data")
    // Helper to get sanitized video link
    // const getSanitizedVideoLink = (link) => {
    //     if (!link) return "";
    //     const videoBaseUrl = new URL(link);
    //     videoBaseUrl.searchParams.set('autoplay', '1');
    //     videoBaseUrl.searchParams.set('mute', '1');
    //     return videoBaseUrl.toString();
    // };

    return (
        <div>
            <Header />
            <section className='founder_section'>
                <div className='container-fluid'>
                    <div className='content-container'>
                        <div className='row'>
                            <div className='sec_adjust founder_section_adjust'>
                                <div className='col-12 col-md-7 col-lg-7'>
                                    <div className='text-start early_year_heading_Section meet_the_founder_mobile'>
                                        <h2>
                                            <span>{founderData?.founderData?.[0]?.banner_title?.split(' ')[0] || 'Meet The'}</span> {founderData?.founderData?.[0]?.banner_title?.split(' ').slice(1).join(' ') || 'Founder'}
                                        </h2>
                                        <p>
                                            {founderData?.founderData?.[0]?.content || "Valli Subbiah, the founder of KC High, has over 30 years of dedicated experience in the field of education..."}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-12 col-md-5 col-lg-5'>
                                    <Founder_top_slider founderImage={founderData?.founderData?.[0]?.image}></Founder_top_slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="policies_gapping_div">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center calendar_school_text">
                                <h2>Founder’s <span> Vision</span></h2>
                                <p className='founder_paragraph'>
                                    {founderData?.founderData?.[0]?.vision_content || "KC High’s growth reflects a commitment to providing socially relevant education..."}
                                </p>
                            </div>
                        </div>
                        <div className="founder_middle_img">
                            {isVideoPlaying && (
                                <div className="video-container">
                                    <iframe
                                        src={getSanitizedVideoLink(founderData?.founderData?.[0]?.video_link)}
                                        title="Founder Video"
                                        className="video-iframe"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="founder_education">
                <div className="container-fluid all_fluid_main_class">
                    <div className="row align-items-center home_founder_message_row">
                        <div className="col-md-12 d-flex align-items-center home_founder_sub_image refelection_founder">
                            <div className="founder-image">
                                <img src={founder_logo} alt="Founder Icon" />
                            </div>
                            <div className="founder-text ms-3 text-start founder_message_all_text">
                                <h2 className="text-white">Founder's Message</h2>
                                <p className="text-white founder_mobile_padding">
                                    {founderData?.founderData?.[0]?.founder_message || "It would be nice to have a custom-built education for each child..."}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='founder_reflection_gapping_section'>
                <Reflections_slider founderJourneydata={founderData?.founderJourneydata}></Reflections_slider>
            </div>
            <Footer />
        </div>
    );
}

export default Founder;
