import { API_BASE_URL } from "../../config/constants";
import { getRequest, postRequest } from "../API";

/*********************************************************
* Function Name : List
* Description   : Get content list
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const generalData = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/generalData/list`,
            postData: options
        }
        const res = await getRequest(params);
        // console.log( "generalDatadfsdf: ", res);
        
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end
/*********************************************************
* Function Name : policy
* Description   : Get policy
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const policy = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}policy/list`,
            postData: options
        }
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end 

/*********************************************************
* Function Name : List
* Description   : Get audioAlbums 
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const audioAlbums = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/albums`,
            postData: options
        }
        const res = await getRequest(params);
        // console.log( "generalDatadfsdf: ", res);
        
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : List
* Description   : Get articles 
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const articles = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/articles`,
            postData: options
        }
        const res = await postRequest(params);
        // console.log( "generalDatadfsdf: ", res);
        
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result , count: res?.data?.response?.count};
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : List
* Description   : Get articlesTag 
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const articlesTag = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/articles/tags`,
            postData: options
        }
        const res = await getRequest(params);
        // console.log( "generalDatadfsdf: ", res);
        
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result , count: res?.data?.response?.count};
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end

/*********************************************************
* Function Name : articlesDetail
* Description   : Get articlesDetail 
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const articlesDetail = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/articles/detail`,
            postData: options
        }
        const res = await postRequest(params);
        // console.log( "generalDatadfsdf: ", res);
        
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}//end


/*********************************************************
* Function Name : List
* Description   : Get content list
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const list = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/cmscontent/list`,
            postData: options
        }
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}
/*********************************************************
* Function Name : List
* Description   : Get content list
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const albumAudio = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/albums`,
            postData: options
        }
        const res = await getRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}


/*********************************************************
* Function Name : List
* Description   : Get content list
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const bannerHome = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/homeBanner/list`,
            postData: options
        }
        const res = await getRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}


/*********************************************************
* Function Name : List
* Description   : Get content list
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const admissionList = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/admissionProcess/list`,
            postData: options
        }
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}

/*********************************************************
* Function Name : List
* Description   : Get content list
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const cmsFAQ = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/faqs`,
            postData: options
        }
        const res = await postRequest(params);
        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}
/*********************************************************
* Function Name : List
* Description   : Get content list
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const partnerList = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/partners`,
            postData: options
        }
        const res = await getRequest(params);

        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}

/*********************************************************
* Function Name : List
* Description   : Get campuses list
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const campuses = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/campuses`,
            postData: options
        }
        const res = await getRequest(params);

        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}

/*********************************************************
* Function Name : List
* Description   : Get campuses list
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const founderDetails = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/kcfounder/list`,
            postData: options
        }
        const res = await getRequest(params);
        // console.log(res, "founder data in controller");
        

        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}

/*********************************************************
* Function Name : List
* Description   : Get gallery
* By            : Siddharth Singh
* Date          : 30-November-2024 
*********************************************************/
export const galleryList = async (options) => {
    try {
        const params = {
            url: `${API_BASE_URL}cms/gallery`,
            postData: options
        }
        const res = await getRequest(params);
        // console.log(res, "founder data in controller");
        

        if (res.status === true || res.status === 200) {
            return { status: true, result: res?.data?.response?.result };
        } else {
            return { status: false, message: res?.response?.data?.statusMessage }
        }
    } catch (error) {
        console.log("Error", error);
        return { status: false, message: "Under Maintenance, Please try after some time." }
    }
}