import React from 'react'
import career_banner_img from "../../images/career_option.png"

function Career_option_banner() {
    return (
        <>

            <section className=''>
                <div className='container-fluid'>
                    <div className='content-container'>
                        <div className='row'>
                            <div className='career_banner_adjust'>
                                <div className='col-12 col-md-12'>
                                    <img src={process.env.PUBLIC_URL+'/images/Careers.JPG'} alt="Admissions" className="icon-image" />
                                </div>
                                <div className='career_option_top_text'>
                                    <div className='text-start  carreer_text_top_banner'>
                                        <h2 >
                                            Join Our  <span > Team</span>
                                        </h2>
                                        <h4>Build a  <span>meaningful career</span> </h4>
                                        <p>
                                            At KC High, we believe in creating a collaborative, innovative, and supportive workplace where everyone has the opportunity to grow and thrive. We are always looking for talented and motivated individuals to join us in our mission to make a difference.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="policies_gapping_div">
                <div className="container-fluid">
                    <div className="row">
                        <div className=" col-md-12 ">
                            <div className="text-center calendar_school_text">
                                <h2>
                                    Open <span> Positions</span>
                                </h2>
                                <p className='founder_paragraph'>
                                    Discover a career path that aligns with your passion and goals. When you join us, you're not just starting a job; you're becoming part of a team that values creativity, diversity, and a commitment to excellence.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Career_option_banner