import React, { useState, useRef, useEffect } from 'react';
import music3 from "../music/school_bell.mp3"
import music2 from '../music/school_bell.mp3'
// import kc_album from "../images/the_kc_album.png"
import kc_album from "../images/kc_album.png"
import { FaRandom, FaStepBackward, FaStepForward, FaPlay, FaPause, FaVolumeUp, FaMusic } from 'react-icons/fa';
import { LuRepeat } from "react-icons/lu";
import backword_icon from '../images/music_grey.svg'
import fowardOne_icon from "../images/music1_icon.svg"
import 'react-range-slider-input/dist/style.css';
import { albumAudio } from "../../controllers/commonController/cmsController"
import { FRONT_ASSETS_URL } from '../../config/constants';

function Kc_album_section() {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [volume, setVolume] = useState(1);
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [musicTracks, setmusicTracks] = useState([]);
    const [IsLoading, setIsLoading] = useState(false);
    /*********************************************************
      *  This function is use to fetch cms content list
      *********************************************************/
    /*********************************************************
    *  This function is used to fetch CMS content list
    *********************************************************/
    const cmsAudio = async () => {
        setmusicTracks([]);
        setIsLoading(true);
        try {
            const listData = await albumAudio();
            if (listData?.status === true) {
                // console.log(listData.result,"listm data");

                const formattedTracks = listData?.result?.map(item => ({
                    title: item.name,
                    src: `${FRONT_ASSETS_URL}${item.audio}`
                }));
                // console.log(formattedTracks, "format audio");

                setmusicTracks(formattedTracks);
            }
        } catch (error) {
            console.log("Failed to fetch banner list:", error);
        } finally {
            setIsLoading(false);
        }
    }; //end

    useEffect(() => {
        cmsAudio();
    }, []);
    // List of music tracks
    // const musicTracks = [
    //     { title: "The KC Anthem", src: music3 },
    //     { title: "Song 2", src: music2 },
    // ];

    const togglePlayPause = () => {
        setIsPlaying(!isPlaying);
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
    };

    const handleVolumeChange = (e) => {
        const newVolume = e.target.value;
        setVolume(newVolume);
        audioRef.current.volume = newVolume;
    };

    const handleBackward = () => {
        setCurrentTrackIndex((prevIndex) => (prevIndex - 1 + musicTracks.length) % musicTracks.length);
    };

    const handleForward = () => {
        setCurrentTrackIndex((prevIndex) => (prevIndex + 1) % musicTracks.length);
    };

    const handleTrackChange = (index) => {
        setCurrentTrackIndex(index);
        setIsPlaying(false);
        audioRef.current.pause();
    };

    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobileView(window.innerWidth <= 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);




    const [value, setValue] = useState(0);

    // const handleSliderChange = (event) => {
    //     const newValue = event.target.value;
    //     setValue(newValue);
    //     document.documentElement.style.setProperty(
    //         "--slider-fill-percentage",
    //         `${(newValue / 50) * 100}%`
    //     );
    // };
    const handleSliderChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);
        // Convert the slider value (0-50) to a volume value (0-1)
        const newVolume = newValue / 50;
        audioRef.current.volume = newVolume;

        // Update CSS variable for background gradient (optional)
        document.documentElement.style.setProperty(
            "--slider-fill-percentage",
            `${(newValue / 50) * 100}%`
        );
    };
    // Set initial CSS variable on component mount
    useEffect(() => {
        document.documentElement.style.setProperty(
            "--slider-fill-percentage",
            `${(value / 50) * 100}%`
        );
    }, []);


    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    // Toggle dropdown visibility
    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };
    return (
        <div>
            {/* //// The Kc Album section */}
            <div className="container-fluid position-relative p-0">
                <img
                    src={kc_album}
                    alt="Full Width"
                    className="img-fluid w-100 kc_album_img"
                />
                <div className="position-absolute  translate-middle text-center text-white kc_album_section all_fluid_main_class">
                    <h1>The KC Album</h1>
                    <div className="container-fluid p-3 kc_music_group">
                        {/* Music Player */}
                        <audio ref={audioRef} src={musicTracks?.[currentTrackIndex]?.src} preload="auto" />

                        <div className="row align-items-center kc_music_group_div">

                            {isMobileView ? (
                                <>
                                    <div className="col-12 d-flex justify-content-between align-items-center flex-row">
                                        <div className="d-flex justify-content-start align-items-center kc_music_svg_section">
                                            <div className='music_icon_data'>
                                                <FaRandom className="kc_svg" />
                                                {/* <backword_icon className="kc_svg me-3" onClick={handleBackward} /> */}
                                                <img
                                                    src={fowardOne_icon}
                                                    alt="Campus Background"
                                                    className="campus-image"
                                                    onClick={handleBackward}
                                                />
                                                {isPlaying ? (
                                                    <FaPause className="text-white " onClick={togglePlayPause} />
                                                ) : (
                                                    <FaPlay className="text-white " onClick={togglePlayPause} />
                                                )}
                                                {/* <FaStepForward className="kc_svg me-3" onClick={handleForward} /> */}
                                                <img
                                                    src={backword_icon}
                                                    alt="Campus Background"
                                                    className="campus-image"
                                                    onClick={handleForward}
                                                />
                                                <LuRepeat className="kc_svg" />
                                            </div>
                                        </div>

                                        {/* Volume control (in the same row for mobile) */}
                                        <div className="col-2 d-flex justify-content-end align-items-center kc_music_svg_input">
                                            <FaVolumeUp className="text-white me-2" />
                                            <div className="range">
                                                <input
                                                    type="range"
                                                    min="0"
                                                    max="50"
                                                    value={value}
                                                    onChange={handleSliderChange}
                                                    className="range-input"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Song title with dropdown */}
                                    <div className="col-12 mt-3 d-md-none"> {/* This will only show on mobile view */}
                                        <div className="input-group kc_music_input">
                                            <button
                                                className="btn bg-transparent border-0 text-white d-flex align-items-center" data-bs-toggle="dropdown" aria-expanded="false"
                                                onClick={toggleDropdown}
                                            >  <div className='fa_music'>
                                                    <FaMusic />
                                                </div>
                                                <span>{musicTracks[currentTrackIndex]?.title}</span>
                                                <span className="ms-auto dropdown-toggle"></span>
                                            </button>
                                            {/* <button className="btn dropdown-toggle bg-transparent border-0 text-white" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                            </button> */}
                                            <ul className="dropdown-menu dropdown_main_section">
                                                {musicTracks.map((track, index) => (
                                                    <li className="music_drop_down" key={index} onClick={() => handleTrackChange(index)}>
                                                        <a className="dropdown-item music" href="#!">{track.title}</a>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    {/* Desktop View */}
                                    <div className="col-2 d-flex justify-content-start align-items-center kc_music_svg_section">
                                        <div className='music_icon_data'>
                                            <FaRandom className="kc_svg" />
                                            {/* <backword_icon className="kc_svg me-3" onClick={handleBackward} /> */}
                                            <img
                                                src={fowardOne_icon}
                                                alt="Campus Background"
                                                className="campus-image"
                                                onClick={handleBackward}
                                            />
                                            {isPlaying ? (
                                                <FaPause className="text-white " onClick={togglePlayPause} />
                                            ) : (
                                                <FaPlay className="text-white " onClick={togglePlayPause} />
                                            )}
                                            {/* <FaStepForward className="kc_svg me-3" onClick={handleForward} /> */}
                                            <img
                                                src={backword_icon}
                                                alt="Campus Background"
                                                className="campus-image"
                                                onClick={handleForward}
                                            />
                                            <LuRepeat className="kc_svg" />
                                        </div>
                                    </div>

                                    <div className="col-8 d-flex justify-content-center align-items-center">
                                        <div className="kc_music_input position-relative">
                                            <button
                                                className="btn bg-transparent border-0 text-white d-flex align-items-center"
                                                onClick={toggleDropdown}
                                            >  <div className='fa_music'>
                                                    <FaMusic />
                                                </div>
                                                <span>{musicTracks?.[currentTrackIndex]?.title}</span>
                                                <span className="ms-auto dropdown-toggle"></span>
                                            </button>
                                            {isDropdownOpen && (
                                                <ul className="dropdown-menu dropdown_main_section show">
                                                    {musicTracks.map((track, index) => (
                                                        <li
                                                            className="music_drop_down"
                                                            key={index}
                                                            onClick={() => {
                                                                handleTrackChange(index);
                                                                setIsDropdownOpen(false); // Close dropdown after selection
                                                            }}
                                                        >
                                                            <a className="dropdown-item music" href="#!">
                                                                {track.title}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-2 d-flex justify-content-end align-items-center kc_music_svg_input">
                                        <FaVolumeUp className="text-white me-2" />
                                        <div className="range">
                                            <input
                                                type="range"
                                                min="0"
                                                max="50"
                                                value={value}
                                                onChange={handleSliderChange}
                                                className="range-input"
                                            />
                                        </div>
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
// import { albumAudio } from '../../controllers/commonController/cmsController';

export default Kc_album_section;
