import React, { useEffect, useState } from 'react'
import Header from '../Header/header';
import Footer from '../footer/footer';
import "./contact-us.css";
import contact_us_banner from "../images/contact-us-banner.png"
import OurCampus from './our_campus';
import { useSelector } from 'react-redux';
import { contactUs } from "../../controllers/submissionsController/submissionsController"
import { notification } from "antd";
import { CheckCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";

function ContactUS() {
    const [inputValue, setInputValue] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phone: "",
        reason: "",
        query: "",
    });
    const [errors, setErrors] = useState({
        name: "",
        email: "",
        phone: "",
        reason: "",
        query: ""
    });

    const { generalDatas } = useSelector((state) => state.footerReducer);
    const generalData = generalDatas?.[0] || {};
console.log(generalDatas,"gen")
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
        validateField(name, value);
    };
    const validateField = (name, value) => {
        let error = "";

        switch (name) {
            case "name":
                error = value.trim() === "" ? "Name is required." : "";
                break;
            case "email":
                const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                error = value.trim() === "" ? "Email is required." :
                    !emailRegex.test(value) ? "Please enter a valid email address." : "";
                break;
                case "phone":
                    const phoneRegex = /^\d{10}$/;
                    // Check if phone number is provided and not empty
                    error = value.trim() === "" ? "Phone number is required." :
                            !phoneRegex.test(value) ? "Phone number must be exactly 10 digits." :
                            /^0/.test(value) ? "Phone number cannot start with zero." : ""; 
                    break;
            case "reason":
                error = value.trim() === "" ? "Reason is required." : "";
                break;
            case "query":
                error = value.trim() === "" ? "Query is required." : "";
                break;
            default:
                break;
        }

        setErrors((prev) => ({
            ...prev,
            [name]: error
        }));
    };
    const handleCheckboxChange = (e) => {
        setAcceptTerms(e.target.checked);
        if (e.target.checked) {
            setErrors((prev) => ({ ...prev, acceptTerms: "" }));
        } else {
            setErrors((prev) => ({ ...prev, acceptTerms: "You must accept the terms to submit the form." }));
        }
    };
    const validateForm = () => {
        const newErrors = {
            name: formData.name.trim() === "" ? "Name is required." : "",
            email: formData.email.trim() === "" ? "Email is required." :
                !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(formData.email) ? "Please enter a valid email address." : "",
            phone: formData.phone.trim() === "" ? "Phone number is required." :
                !/^\d{10}$/.test(formData.phone) ? "Phone number must be exactly 10 digits." : "",
            reason: formData.reason.trim() === "" ? "Reason is required." : "",
            query: formData.query.trim() === "" ? "Query is required." : "",
            acceptTerms: acceptTerms ? "" : "You must accept the terms to submit the form."

        };

        setErrors(newErrors);

        return Object.values(newErrors).every((error) => error === "");
    };
    const handleSubmit = async () => {
        if (!validateForm()) {
            // notification.open({
            //     message: "Oops!",
            //     description: "Please correct the errors before submitting.",
            //     placement: "topRight",
            //     icon: <InfoCircleOutlined style={{ color: "red" }} />,
            //     duration: 2,
            // });
            return;
        }

        try {
            const response = await contactUs(formData);
            if (response.status) {

                // alert("Query submitted successfully.");
                notification.open({
                    message: "Submitted.",
                    description: "Query submitted successfully.",
                    placement: "topRight",
                    icon: <CheckCircleOutlined style={{ color: "green" }} />,
                    duration: 2,
                });
                setFormData({
                    name: "",
                    email: "",
                    phone: "",
                    reason: "",
                    query: "",
                });
                setAcceptTerms(false)
                setErrors({
                    name: "",
                    email: "",
                    phone: "",
                    reason: "",
                    query: ""
                });

            } else {
                // alert(response.message || "Something went wrong. Please try again.");
                notification.open({
                    message: "Oops!",
                    description: response.message || "Something went wrong. Please try again.",
                    placement: "topRight",
                    icon: <InfoCircleOutlined style={{ color: "red" }} />,
                    duration: 2,
                });
            }
        } catch (error) {
            // console.error("Error submitting query:", error);
            // alert("An error occurred. Please try again later.");
            notification.open({
                message: "Oops!",
                description: "An error occurred. Please try again later.",
                placement: "topRight",
                icon: <InfoCircleOutlined style={{ color: "red" }} />,
                duration: 2,
            });

        }
    };
      // Scroll to the top of the page when the component mounts
useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  const [map, setMap] = useState(null);
  const [activeTab, setActiveTab] = useState("OP Campus");

  const handleActiveTabChange = (tab) => {
    setActiveTab(tab);
  };
const RRMap="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.2305658352498!2d80.23714437412112!3d13.02098421379559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267a4497dcfc5%3A0x2f7fd19c44d0e57c!2sRanjith%20Rd%2C%20Kotturpuram%2C%20Chennai%2C%20Tamil%20Nadu!5e0!3m2!1sen!2sin!4v1738577268321!5m2!1sen!2sin"
    return (
        <>
            <Header></Header>
            <div>
                <div className="contact_us_top_section ">
                    <div className="container-fluid text-center   ">
                        <div className='contact_us'>
                            <div className="contact-us-section">
                                <img src={contact_us_banner} alt="Contact Us" className="contact-us-bg" />
                                <div className="contact-us-content">
                                    <div className='contact_us_hear'>
                                        <div className="contact_us_left text-start">
                                            <h2 >Contact <span>Us</span> </h2>
                                            <h4>We'd Love To <span>Hear From You!</span></h4>
                                        </div>
                                        <div className="contact_us_right">
                                            <p>
                                                Whether you have questions, feedback, or <br></br> need assistance, here's how you can reach us.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='contact_us_form_section'>
                            <div className="container-fluid ">
                                <form >
                                    <div className="row g-3">
                                        <div className='contact_us_enquiry_form'>
                                            <h3>Enquiry Form</h3>
                                        </div>
                                        <div className="col-md-4 regestration_form_input position-relative">
                                            <input
                                                type="text"
                                                name="name"
                                                className="form-control"
                                                placeholder="Enter Full Name *"
                                                value={formData.name}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            {errors.name && <p className="error-text">{errors.name}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="number"
                                                name="phone"
                                                className="form-control"
                                                placeholder="Enter Mobile Number"
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            {errors.phone && <p className="error-text">{errors.phone}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <input
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Enter Email Address *"
                                                value={formData.email}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            {errors.email && <p className="error-text">{errors.email}</p>}
                                        </div>
                                        <div className="col-md-4 regestration_form_input">
                                            <select
                                                name="reason"
                                                className="form-select"
                                                // placeholder="select"
                                                value={formData.reason}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="">Select Reason *</option>
                                                <option value="General Enquiry">General Enquiry</option>
                                                <option value="Admissions">Admissions</option>
                                                <option value="Exam Services">Exam Services</option>
                                                <option value="Careers">Careers</option>
                                                <option value="Media and Press">Media and Press</option>
                                                <option value="Vendor">Vendor</option>
                                            </select>
                                            {errors.reason && <p className="error-text">{errors.reason}</p>}
                                        </div>
                                        <div className="col-md-8 regestration_form_input">
                                            <input
                                                type="text"
                                                name="query"
                                                className="form-control"
                                                placeholder="Query *"
                                                value={formData.query}
                                                onChange={handleInputChange}
                                                required
                                            />
                                            {errors.query && <p className="error-text">{errors.query}</p>}
                                        </div>
                                        <div className='enquiry_submit'>
                                            <div className=' contact_us_query_text'>
                                                <div className='kc_application'>
                                                    <div className='contact_us_checkbox_mobile'>
                                                        <label className="form_checkbo me-2">
                                                            <input
                                                                type="checkbox"
                                                                name="acceptTerms"
                                                                id='kcApplication'
                                                                value="accepted"
                                                                checked={acceptTerms}
                                                                onChange={handleCheckboxChange}
                                                            />
                                                        </label>
                                                        <label htmlFor="kcApplication"><p>By submitting this form, I confirm that all information provided is accurate and complete.</p></label>
                                                    </div>
                                                    {errors.acceptTerms && <span className="error-text ">{errors.acceptTerms}</span>}
                                                </div>
                                            </div>


                                            <div className='form_subbmit_btn query_btn'>
                                                <button type='button' onClick={handleSubmit}>Submit Query</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <OurCampus  toggleMap={setMap} onTabChange={handleActiveTabChange} ></OurCampus>
                <div className='contact_us_map_section'>
                    <div className="container-fluid ">
                        <div className="row our_campus_row_section">
                            {/* Left card (larger) */}
                            <div className="col-md-12 our_campus_map_section">
                                <div>
                                    <iframe
                                        src={activeTab==='OP Campus'?RRMap:generalData?.generalData_location}
                                        width="100%"
                                        height="300"
                                        style={{ border: 0 }}
                                        allowFullScreen=""
                                        loading="lazy"
                                    ></iframe>
                                </div>
                            </div>
                        </div>
                    </div >
                </div>
            </div>
            <Footer></Footer>
        </>

    )
}

export default ContactUS;
