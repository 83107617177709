import axios from "axios";
import { API_KEY } from "../config/constants";

/*****************************************************
 * This function is use to generate GET Request
 *****************************************************/
export const getRequest = async (options)=>{
    try {
        const { url } = options;
        const token = sessionStorage.getItem('TOKEN');
        const headers = { authToken : token, key : API_KEY };
        return await axios.get(url, { headers });
        // const data = response.data.response.result;
        // return data;
    } catch (error) {
        return false;
    }
} //End of the function



/*****************************************************
 * This function is use to generate POST Request
 *****************************************************/
export const postRequest = async (options, cancelToken={})=>{
    try{
        const { url, postData ={} } = options;
        const token = sessionStorage.getItem('TOKEN');
        const headers = { authToken : token, key : API_KEY };
        return await axios.post(url, postData, { headers }, { cancelToken: cancelToken.token });
    } catch(error){
        if(error?.response.status === 500 && error?.response.data.response.error === "Invalid or Expired Token"){
            sessionStorage.removeItem('ADMIN-INFO');
            sessionStorage.removeItem('TOKEN');
        }
        return error;
    }
} //End of the function

export const fetchIpAddress = async () => {
    try {
        if(sessionStorage.getItem('IP_ADDRESS')){
            return sessionStorage.getItem('IP_ADDRESS');
        } else {
            const result = await axios.get('https://api.ipify.org?format=json');
            // console.log('result',result);
            if(result?.data){
                // console.log('data1',result.data.ip)
                sessionStorage.setItem('IP_ADDRESS',result?.data.ip);
                return result?.data.ip;
            } else{
                return ":1";
            }
        }
    } catch (error) {
        return ":1";
    }
}
