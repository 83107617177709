import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./home.css";
import { articles } from "../../controllers/commonController/cmsController";
import { FRONT_ASSETS_URL } from "../../config/constants";
import moment from "moment";
import event_pic2 from "../images/event2.png";
import './home.css';
import { CgSpaceBetween } from 'react-icons/cg';
import { Link } from "react-router-dom";

// Replicate Data Function
const replicateData = (data) => {
  return data.length === 1 ? Array(5).fill(data[0]) : data;
};

const EventSlider = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [cmsArticleList, setcmsArticleList] = useState([]);

  const handleAfterChange = (index) => {
    setActiveIndex(index);
  };

  /*********************************************************
   *  This function is use to fetch cms content list
   *********************************************************/
  const cmsArticles = async () => {
    setcmsArticleList([]);
    // setIsLoading(true);
    try {
      const options = {
        type: "",
        condition: {
          status: "A",
        },
        select: {},
        sort: { _id: -1 },
        populate: {
          key: "",
          select: " ",
        },
        skip: 0,
        limit: 10,
      };
      const listData = await articles(options);
      if (listData?.status === true) {
        setcmsArticleList(listData?.result);
      }
    } catch (error) {
      console.log("failed to fetch banner list:", error);
    } finally {
      //   setIsLoading(false);
    }
  }; //end

  useEffect(() => {
    cmsArticles();
  }, []);

  // Replicate data if necessary
  const eventsToDisplay = replicateData(cmsArticleList);

  var settings = {
    dots: true,
    infinite: true,
    afterChange: handleAfterChange,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    cssEase: "linear",
    centerMode: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container-fluid event_slider_main_section">
      <div className="all_fluid_main_class event_slide">
        <div className="row event_slide">
          {/* Left Side Content */}
          <div className="col-md-4 p-4 news-events-section text-start event_slider_mobile_view">
            <h2 className="text-start mb-4 accreditation-title event_slider_news_events">
              News <span>&</span> Events
            </h2>
            <h5 className="news-subtitle mb-3 event_news_subtitle">
              What’s happening at <span>KC</span>{" "}
            </h5>
            <div className="home_white_line mb-3"></div>

            <p className="home_event_news_content mb-4">
              {/* It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. */}
              Stay updated with the latest news, achievements, and upcoming events from our vibrant community.
            </p>
            <div className="event_slider_left_btn">
              <Link to={`/event`}>
                <button type="button">View All Events</button>
              </Link>
              <Link to={`/news`}>
                <button type="button " className="event_view_news">View All News</button>
              </Link>
            </div>
          </div>

          {/* Right Side Slider */}
          <div className="col-md-8 event_slider_for_row">
            <Slider {...settings}>
              {eventsToDisplay.map((item, index) => (
                <Link to={`/${item.slug}`} key={item._id}>
                  <div className="slide-item">
                    <div
                      className={`home_event_card event_card ${activeIndex === index ? "home_event_card_active" : ""
                        }`}
                    >
                      <div className="home_event_custom_card card text-center border-0 event_slider_for_height">
                        <div className="home_card_img_container">
                          <img
                            src={`${FRONT_ASSETS_URL}${item.image}`}
                            className="img-fluid card-img event_slider_img_right"
                            alt="Event Title"
                          />
                        </div>
                        <div className="home_event_card_body text-start event_slider_contnet event_slider_limit">
                          <h4 className="event-label">
                            {item.type} <span>/ By {item.author}</span>
                          </h4>
                          <h5 className="card-title text-start">{item.title}</h5>
                          <p className="text-start">{item.content}</p>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-start event-date home_event_slider_bottom">
                          {moment(item.updatedAt).format("MMM DD, YYYY")}
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventSlider;
